// ==========
// import
// ==========
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { type Theme, type Palette } from "@mui/material/styles";
import { ELangs } from "../../../enums/ELangs";
import reference from "../../../reference.json";
import { useLocation } from "react-router-dom";

// ==========
// types
// ==========
type TTitle = string | (string & keyof typeof reference.greetings);
type TComponentProps = {
  title: TTitle;
  lang: ELangs;
  theme: Theme;
  isTablet: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  isFullscreenMode: boolean;
  isReady?: boolean;
};

// ==========
// name of the "/"
// ==========
const ROOT_URL = reference.utils.rootUrl.pageName;

// ==========
// regex
// ==========
const regex = /[^/_]+/i;

// ==========
// constants
// ==========
const _colorForHeader = (colors: Palette) =>
  colors.mode === "light" ? colors.primary.main : colors.primary.light;

const _colorForSubHeader = (
  colors: Palette,
  isFullscreenMode: boolean = false
) =>
  !isFullscreenMode
    ? colors.mode === "light"
      ? colors.primary.dark
      : colors.text.secondary
    : colors.mode === "light"
    ? colors.primary.main
    : colors.primary.light;

// ==========
// main
// ==========
export function RHeader({
  title,
  lang,
  theme,
  isMobile,
  isTablet,
  isDesktop,
  isFullscreenMode,
  isReady,
}: TComponentProps) {
  // --------
  // local titles
  // --------
  const [mainTitle, subTitle] = _localTitles(title, lang);

  // -------
  // URL params
  // -------
  const location = useLocation();

  // -------
  //
  // -------
  const regexMatch = location.pathname.match(regex) as Array<
    keyof typeof reference.indicatorHeaders.startPage.indicators
  > | null;

  // -------
  // local Types
  // -------
  type TRootIndex = keyof typeof reference.indicatorHeaders.startPage.root;
  type TCategoriesIndex = keyof typeof reference.directory.blocks;

  const sectionObj =
    regexMatch !== null
      ? reference.indicatorHeaders.startPage.indicators[regexMatch[0]]
      : reference.indicatorHeaders.startPage.root[ROOT_URL as TRootIndex];

  let section = "";
  let block = "";

  try {
    section = sectionObj.name[lang as ELangs];
    block =
      reference.directory.blocks[sectionObj.block as TCategoriesIndex].name[
        lang as ELangs
      ];
  } catch (err) {
    console.info("--------------------------");
    console.info(
      "%cRHeader::regexMatch[0]: ",
      "color: tomato",
      regexMatch !== null ? regexMatch[0] : "null"
    );
    console.info("%cRHeader::section: ", "color: tomato", section);
    console.info("%cRHeader::sectionObj: ", "color: tomato", sectionObj);
    console.info("%cRHeader::block: ", "color: tomato", block);

    throw new Error();
  }

  // -----------
  // rendering
  // -----------
  return (
    <Stack sx={_stackStyles(isTablet)} className="RHeader_Stack">
      {/* <TestForm /> */}
      {!isFullscreenMode && isReady && (
        <Stack
          className="RHeader.H2.Stack"
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            variant="h2"
            sx={_mainTitleStyle(theme.palette, isTablet)}
          >
            {mainTitle}
          </Typography>
          {(isDesktop || isTablet) && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className="RHeader_Breadcrumbs"
              sx={_breadCrumbsStyle(theme.palette, isTablet)}
            >
              <Typography>{block.toUpperCase()}</Typography>
              <Typography>{section.toUpperCase()}</Typography>
            </Breadcrumbs>
          )}
        </Stack>
      )}
      {isReady && (
        <Typography
          variant="h5"
          sx={_subTitleStyle(theme.palette, isTablet, isFullscreenMode)}
        >
          {subTitle}
        </Typography>
      )}
      {isMobile && (
        <Breadcrumbs
          aria-label="breadcrumb"
          className="RHeader_Breadcrumbs"
          sx={_breadCrumbsStyle(theme.palette, isTablet, isMobile)}
        >
          <Typography>{block.toUpperCase()}</Typography>
          <Typography>{section.toUpperCase()}</Typography>
        </Breadcrumbs>
      )}
    </Stack>
  );
}

// ----------
// _localTitles
// ----------
function _localTitles(title: TTitle, lang: ELangs) {
  let what = {} as Record<string, any>;
  if (title in reference.targets) {
    what = reference.targets[title as keyof typeof reference.targets];
  } else if (title in reference.greetings) {
    what = reference.greetings[title as keyof typeof reference.greetings];
  } else {
    throw new Error(
      `RHeader::_localTitles: "title" ${title} is not in "reference.targets" or "reference.greetings".`
    );
  }
  const mainTitle = ("shortName" as keyof typeof what)
    ? what.shortName[lang]
    : "Error";
  const subTitle = ("name" as keyof typeof what) ? what.name[lang] : "Error";

  return [mainTitle, subTitle];
} //<<

// ==========
// styles
// ==========
function _stackStyles(isTablet: boolean) {
  // ---
  // CSSObject
  // ---
  return {
    gap: "0.3rem",
    position: "relative",
    paddingBottom: isTablet ? "0" : "1em",
  };
} //<<

// ----------
// _mainTitleStyle
// ----------
function _mainTitleStyle(colors: Palette, isTablet: boolean) {
  return {
    color: _colorForHeader(colors),
    fontWeight: "bold",
    fontSize: isTablet ? "2em" : "2rem",
  };
}

// ----------
// _subTitleStyle
// ----------
function _subTitleStyle(
  colors: Palette,
  isTablet: boolean,
  isFullscreenMode: boolean = false
) {
  return {
    marginLeft: "0.3rem",
    color: _colorForSubHeader(colors, isFullscreenMode),
    // fontSize: isTablet ? "1em" : "16px",
    fontSize: !isFullscreenMode
      ? isTablet
        ? "1em"
        : "1rem"
      : isTablet
      ? "2em"
      : "2rem",

    paddingBottom: !isFullscreenMode ? 0 : "0.5em",
  };
}

// ----------
// _breadCrumbsStyle
// ----------
function _breadCrumbsStyle(
  colors: Palette,
  isTablet: boolean,
  isMobile?: boolean
) {
  return {
    marginLeft: "0.3em",
    display: "flex",
    alignItems: isTablet ? "flex-end" : "center",
    margin: isMobile ? "0.4em 0 0.4em 0.3em" : "unset",
    marginRight: "0.4em",
    fontStyle: "italic",
    color: colors.mode === "light" ? colors.primary.main : colors.primary.dark,
    "& .MuiTypography-root": {
      fontSize: isTablet ? "0.7em" : "0.8em",
      fontWeight: "bold",
    },
    "& .MuiBreadcrumbs-separator": {
      fontSize: isTablet ? "0.6em" : "0.8em",
    },
  };
}
