// ============
// import
// ============
import { type TEStatus } from "../../enums/EStatus";

// ============
// types
// ============
export type TMinState<T = any> = {
  ids: Array<string>;
  entities: Record<string, T>;
  status?: TEStatus;
  indicatorID: string;
};

export type TRootState = Record<string, TMinState | undefined>;

type TSlice = {
  name: string;
  initialState: TMinState;
  reducers?: (self: TSlice) => {
    [x: string]: (
      state: TRootState,
      dispatch: Function,
      select: Function
    ) => (action: any) => void;
  };
  extraReducers?: (self: TSlice) => {
    [x: string]: (
      state: TRootState,
      dispatch: Function,
      select: Function
    ) => (action: any) => void;
  };
};

type TEStateNames = {
  _enums: Record<string, string>;
  get: (name: string) => string | never;
  set: (name: string) => void;
};

// ============
// pseudo ENUM
// ============
export const noReduxEStateNames: TEStateNames = {
  _enums: {},
  get(name) {
    if (this._enums[name]) {
      return this._enums[name];
    } else {
      throw new Error(
        `context/noredux/mainStore::noReduxEStateNames:\nThere is no name "${name}" in store!`
      );
    }
  },
  set(name) {
    this._enums[name] = name;
  },
};

// ============
// main
// ============
export const store = {
  _state: {} as TRootState,

  getState() {
    return this._state;
  },

  select(name: string) {
    return this._state[name];
  },

  dispatch(name: string, payload: TMinState) {
    const oldState = this._state[name];
    this._state[name] =
      payload === undefined ? undefined : Object.assign({}, oldState, payload);
    this._toUpdate();
  },

  // eslint-disable-next-line
  _updateFn(fn: ({}) => void) {
    store._toUpdate = () =>
      window.setTimeout(() => {
        fn({});
      }, 0);
  },

  _toUpdate: () => {},

  _allActions: {},

  _allExtraActions: {},

  createSlice(objToSlice: TSlice) {
    let actions;
    let extraActions;
    if (objToSlice.reducers) {
      actions = Object.keys(objToSlice.reducers(objToSlice)).reduce(
        (result, fn) => {
          const elem = {
            // @ts-ignore 'objToSlice.reducers !== undefined'
            [fn]: objToSlice
              .reducers(objToSlice)
              [fn](
                this._state,
                this.dispatch.bind(this),
                this.select.bind(this)
              ),
          };
          return Object.assign({}, result, elem);
        },
        {} as Record<string, Function>
      );
    } else {
      actions = null;
    }

    if (objToSlice.extraReducers) {
      const extraReducersObj = objToSlice.extraReducers(objToSlice);

      extraActions = Object.keys(extraReducersObj).reduce((result, fn) => {
        const elem = {
          // @ts-ignore 'objToSlice.reducers !== undefined'
          [fn]: extraReducersObj[fn](this._state, this.dispatch.bind(this)),
        };
        return Object.assign({}, result, elem);
      }, {} as Record<string, Function>);
    } else {
      extraActions = null;
    }

    // -------
    // prepare ENUM
    // -------
    noReduxEStateNames.set(objToSlice.name);

    // -------
    // prepare return
    // -------
    this._allActions = Object.assign({}, this._allActions, actions);
    this._allExtraActions = Object.assign(
      {},
      this._allExtraActions,
      extraActions
    );

    // -------
    // return
    // -------
    return {
      actions: this._allActions,
      extraActions: this._allExtraActions,
    };
  },
};

// ========
// common selectors
// ========
export const noReduxSelectors = {
  selectAll(target: string) {
    const stateCopy = Object.assign({}, store._state[target]);
    return stateCopy.ids.length > 0
      ? (Object.values(stateCopy.entities) as Array<typeof stateCopy.entities>)
      : undefined;
  },
  selectOneById(target: string, id: string) {
    const stateCopy = Object.assign({}, store._state[target]);
    try {
      return stateCopy.entities[id];
    } catch (error) {
      return undefined;
    }
  },
  selectEntities(target: string) {
    const stateCopy = Object.assign({}, store._state[target]);
    return stateCopy.entities;
  },
  selectIndicatorID(target: string) {
    return store._state[target]?.indicatorID;
  },
};

// ========
// updater
// ========
export const { _updateFn: noReduxUpdater } = store;
