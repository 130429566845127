// =============
// import
// =============
import reference from "../../reference.json";
import type { TECountriesVal } from "../../enums/ECountries";

// =============
// types
// =============
type TDataColor = {
  id: TECountriesVal;
  data: Array<{ x: string; y: number }>;
};
type TMode = "light" | "dark";

// =============
// main
// =============
export function pickCountryColor(data: Array<TDataColor>, mode: TMode) {
  return data.map((obj) => {
    const cnt = obj.id;
    const colorCountry = reference.countries[cnt].colors[mode];
    return colorCountry;
  });
}
