// ===========
// import
// ===========
import { RDescription } from "../description/RDescription";
import { RSource } from "../source/RSource";
import { RStyledHorizontalTabsWrap as StyledTabsWrap } from "../../../../styled/RStyledHorizontalTabsWrap";
import { RStyledHorizontalTabs as StyledTabs } from "../../../../styled/RStyledHorizontalTabs";
import { type SxProps /*type Theme*/ } from "@mui/material";
import * as React from "react";
import reference from "../../../../reference.json";
import { ELangs } from "../../../../enums/ELangs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { RLine } from "../line/RLine";
import { RMainResponsiveBar } from "../bar/RMainResponsiveBar";
import { RBaseTable } from "../tables/RBaseTable";
import { type TThemeSettings } from "../../../../theming/themeSettings";
import { type Theme } from "@mui/material/styles";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";

// ===========
// types
// ===========
type TComponentProps = {
  data: any;
  axisTitles: any;
  lang: ELangs;
  minMaxY: any;
  xArray: any;
  xRange: any;
  target: any;
  handleChangedXRange: (range: [number, number]) => void;
  rangeTupple: [] | [number, number];
  dataID: string;
  fnSetCurrentTabName: (
    isShowLegend: boolean,
    currentTabNumber: number
  ) => void;
  theme: TThemeSettings & Theme;
  targetFloatPrecision: number;
  isCompressedLines: boolean;
  toggleIsCompressedLines: () => void;
  isTablet: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  toShowLegend: boolean;
  area: Array<number | null>;
  fnOpenInFullscreen(): void;
  isFullscreenMode: boolean;
};
export enum ETabsNames {
  CHART = "charts",
  BAR = "bars",
  TABLE = "table",
  DESCRIPTION = "description",
  SOURCE = "source",
}
type TTabsMemoComponentProps = {
  mode: string;
  value: number;
  mainColor: string;
  lightColor: string;
  lang: ELangs;
  handleChange: (_event: React.SyntheticEvent, newValue: number) => void;
};

// =========
// subcomponents
// =========
const TabsMemoComponent = React.memo(function TabsMemoComponent(
  props: TTabsMemoComponentProps
) {
  const { mode, value, mainColor, lightColor, lang, handleChange } = props;

  const isLight = mode === "light" ? true : false;

  // -------
  // rendering
  // -------
  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs"
      textColor="inherit"
      className="StyledTabs"
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: isLight ? mainColor : lightColor,
          opacity: 1,
        },
      }}
    >
      <Tab label={reference.ui.chart.name[lang]} {...allyProps(0)} />
      <Tab label={reference.ui.bars.name[lang]} {...allyProps(1)} />
      <Tab label={reference.ui.table.name[lang]} {...allyProps(2)} />
      <Tab label={reference.ui.description.name[lang]} {...allyProps(3)} />
      <Tab label={reference.ui.source.name[lang]} {...allyProps(4)} />
    </StyledTabs>
  );
});

// ===========
// main
// ===========
export function RBasicTabs(props: TComponentProps) {
  const {
    data,
    axisTitles,
    lang,
    minMaxY,
    xArray,
    xRange,
    target,
    handleChangedXRange,
    rangeTupple,
    dataID,
    theme,
    targetFloatPrecision,
    isCompressedLines,
    toggleIsCompressedLines,
    isFullscreenMode = false,
  } = props;

  // ---------
  // constants
  // ---------
  const BASE_HEIGHT = "50vh";
  const MIN_HEIGHT = props.isTablet ? "220px" : "417px";
  const MAX_HEIGHT = "unset";

  // -----------
  // local state
  // -----------
  const [value, setValue] = React.useState<number>(
    busContext.getDataFromBusOne(busNames.tabNumber)
  );

  // -----------
  // local store
  // -----------
  const refDomStyledTabsWrap = React.useRef<HTMLDivElement>(null);

  // -----------
  // handlers
  // -----------
  const handleChange = React.useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      // const isShowLegend = props.isDesktop ? true : false;

      switch (newValue) {
        case 0: // Line
          // props.fnSetCurrentTabName(isShowLegend, 0);
          props.fnSetCurrentTabName(true, 0);
          break;

        case 1: // Bar
          // props.fnSetCurrentTabName(isShowLegend, 1);
          props.fnSetCurrentTabName(true, 1);
          break;

        case 2: // Table
          // props.fnSetCurrentTabName(isShowLegend, 2);
          props.fnSetCurrentTabName(true, 2);
          break;

        case 3: // Description
          props.fnSetCurrentTabName(false, 3);
          break;

        case 4: // Source
          // props.fnSetCurrentTabName(isShowLegend, 4);
          props.fnSetCurrentTabName(true, 4);
          break;

        default:
          throw new Error(
            "RBasicTabs::handleChange::switch::default: wrong result 'newValue'"
          );
      }
    },
    // eslint-disable-next-line
    []
  );

  // -----------
  // pre-rendering
  // -----------
  const stylerBT = React.useMemo(
    () =>
      _styleRBasicTabs(
        props.isDesktop,
        props.isTablet,
        props.toShowLegend,
        props.isFullscreenMode
      ),
    [
      props.isDesktop,
      props.isTablet,
      props.toShowLegend,
      props.isFullscreenMode,
    ]
  );
  // -----------
  // rendering
  // -----------
  return (
    <StyledTabsWrap
      className="RBasicTabs"
      sx={stylerBT}
      isStart={false}
      ref={refDomStyledTabsWrap}
      isMobile={props.isMobile}
      isTablet={props.isTablet}
    >
      {/* {tabsMemo} */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.text.disabled,
          // marginRight: "32px",
          visibility: !isFullscreenMode ? "visible" : "collapse",
        }}
        className="RBasicTabs_Box"
      >
        <TabsMemoComponent
          value={value}
          lang={lang}
          handleChange={handleChange}
          mode={theme.palette.mode}
          mainColor={theme.palette.primary.main}
          lightColor={theme.palette.primary.light}
        />
      </Box>
      {/* Line */}
      <TabPanel
        value={value}
        index={0}
        sx={{
          minHeight: MIN_HEIGHT,
          maxHeight: MAX_HEIGHT,
          flex: "1",
        }}
      >
        <RLine
          data={data}
          axisTitles={axisTitles}
          lang={lang}
          minMaxY={minMaxY}
          target={target}
          xArray={xArray}
          xRange={xRange}
          rangeTupple={rangeTupple}
          baseHeight={BASE_HEIGHT}
          isCompressedLines={isCompressedLines}
          toggleIsCompressedLines={toggleIsCompressedLines}
          fnOpenInFullscreen={props.fnOpenInFullscreen}
        />
      </TabPanel>
      {/* Bar */}
      <TabPanel
        value={value}
        index={1}
        sx={{
          minHeight: MIN_HEIGHT,
          maxHeight: MAX_HEIGHT,
          flex: "1",
        }}
      >
        <RMainResponsiveBar
          xRange={xRange}
          xArray={xArray}
          data={data}
          target={target}
          lang={lang}
          handleChangedXRange={handleChangedXRange}
          rangeTupple={rangeTupple}
          dataID={dataID}
          baseHeight={BASE_HEIGHT}
          targetFloatPrecision={targetFloatPrecision}
          area={props.area}
          fnOpenInFullscreen={props.fnOpenInFullscreen}
        />
      </TabPanel>
      {/* Table */}
      <TabPanel
        value={value}
        index={2}
        sx={{
          minHeight: MIN_HEIGHT,
          maxHeight: MAX_HEIGHT,
          flex: "1",
        }}
      >
        <RBaseTable
          data={data}
          axisTitles={axisTitles}
          lang={lang}
          xArray={xArray}
          target={target}
          xRange={xRange}
          handleChangedXRange={handleChangedXRange}
          rangeTupple={rangeTupple}
          baseHeight={BASE_HEIGHT}
        />
      </TabPanel>
      {/* Description */}
      <TabPanel
        value={value}
        index={3}
        sx={{
          minHeight: MIN_HEIGHT,
          maxHeight: MAX_HEIGHT,
          flex: "1",
        }}
      >
        <RDescription
          lang={lang}
          target={target}
          baseHeight={BASE_HEIGHT}
          isMobile={props.isMobile}
        />
      </TabPanel>
      {/* Source */}
      <TabPanel
        value={value}
        index={4}
        sx={{
          minHeight: MIN_HEIGHT,
          maxHeight: MAX_HEIGHT,
          flex: "1",
        }}
      >
        <RSource
          lang={lang}
          target={target}
          baseHeight={BASE_HEIGHT}
          isMobile={props.isMobile}
        />
      </TabPanel>
    </StyledTabsWrap>
  );
}

// ===========
// subcomponents
// ===========
type TTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  // sx?: SxProps<Theme>;
  sx?: SxProps<TThemeSettings>;
};

function TabPanel(props: TTabPanelProps) {
  const { children, value, index } = props;
  const style = { ...props.sx };

  // -----------
  // rendering
  // -----------
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="TabPanel"
      style={style}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 0,
            paddingLeft: 0,
            height: "100%",
            overflow: "hidden",
          }}
          className="TabPanel_Box"
        >
          {children}
        </Box>
      )}
    </div>
  );
}

// ===========
// allies
// ===========
function allyProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ===========
// styles
// ===========
function _styleRBasicTabs(
  isDesktop: boolean,
  isTablet: boolean = false,
  toShowLegend: boolean,
  isFullscreenMode: boolean = false
) {
  return {
    width: "100%",
    height: isDesktop || isTablet ? "auto" : toShowLegend ? "60vh" : "100%",
    marginBottom: isTablet ? "0" : !isFullscreenMode ? "32px" : "0",
    paddingBottom: "0px",

    "& .TabPanel > .MuiBox-root": {
      height: "100%",
      paddingBottom: 0,
      paddingLeft: "0px",
      paddingRight: 0,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };
}
