// ============
// import
// ============
import React from "react";
import { useMediaQuery, type Theme, type SxProps } from "@mui/material";
import { type Breakpoint } from "@mui/material/styles";
import reference from "../../../../../reference.json";
import { toggleCountryActiveNRedux } from "../../../../../context/noredux/countries/countriesSlice";
import {
  noReduxSelectors,
  noReduxEStateNames,
} from "../../../../../context/noredux/mainStore";
import { RAllCntrsLegendV } from "./RAllCntrsLegendV";
import { type TPrepareCountry } from "../../../../../func/prepare/prepareCountries";
import Stack from "@mui/material/Stack";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material";
import { ELangs } from "../../../../../enums/ELangs";
import { TECountriesVal } from "../../../../../enums/ECountries";
import { prepareCountries } from "../../../../../func/prepare/prepareCountries";
import { busContext } from "../../../../..";
import { busNames } from "../../../../../bus_context/busContextStore";
import { iconsStore } from "../../../../../hooks_g/useIcons";

// ============
// types
// ============
type TComponentProps = {
  lang: ELangs;
  colors: any;
  isDark: boolean;
  marginRight: number;
  toShowBlock?: boolean;
};
type TCountry = {
  countryId: TECountriesVal;
  name: string;
  isActive: boolean;
  color: string;
};
type TStyledStack = {
  children: React.ReactNode | Array<React.ReactNode>;
  sx?: SxProps<Theme>;
  className?: string;
} & Record<string, any>;

// ============
// constants
// ============
const SVG_WIDTH_HEIGHT = "0.6em";

// ============
// styled
// ============
const StyledStack = styled((props: TStyledStack) => (
  <Stack {...props}>{props.children}</Stack>
))((allProps) =>
  Object.assign(
    {},
    {
      color: "inherit",
      "& .IIIg-noactive": {
        opacity: 0.6,
      },
    },
    {
      ...allProps.sx,
    }
  )
);

// ============
// main
// ============
export function RLineLegendV({ lang }: TComponentProps) {
  const theme = busContext.getDataFromBusOne(busNames.theme);
  const cntrs = noReduxSelectors.selectAll(
    noReduxEStateNames.get("countries")
  ) as Array<any>;

  const countries = prepareCountries(lang, cntrs, theme.palette.mode);

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm" as Breakpoint));

  const { CircleBound, CircleFill } = iconsStore;

  // ------------
  // handlers
  // ------------
  const onActiveCountry =
    (country: TCountry) => (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();

      window.setTimeout(() => {
        toggleCountryActiveNRedux({
          id: country.countryId,
          changes: {
            id: country.countryId,
            active: !country.isActive,
          },
        });
      }, 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <StyledStack
      className="RLineLegendV"
      direction="row"
      sx={_rlineLegendStyle()}
    >
      <RAllCntrsLegendV
        countries={countries}
        lang={lang}
        isMobile={isMobile}
        theme={theme}
      />
      {countries.map((country) => (
        <ButtonBase
          key={country.countryId}
          className="RLineLegendV_ButtonBase"
          onClick={onActiveCountry(country)}
          sx={_buttonBaseStyle(isMobile, theme, country)}
        >
          <span style={_buttonIconStyle()}>
            {country.isActive ? (
              <CircleFill
                sx={{
                  color: country.color,
                  width: SVG_WIDTH_HEIGHT,
                  height: SVG_WIDTH_HEIGHT,
                }}
              />
            ) : (
              <CircleBound
                sx={{ width: SVG_WIDTH_HEIGHT, height: SVG_WIDTH_HEIGHT }}
              />
            )}
          </span>

          <span
            style={_countryStyle()}
            className={country.isActive ? "" : "IIIg-NoActive"}
          >
            {reference.countries[country.countryId].shortName[lang]}
          </span>
        </ButtonBase>
      ))}
    </StyledStack>
  );
}

// ============
// styles
// ============
function _rlineLegendStyle() {
  return {
    minWidth: "60px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    height: "100%",
    marginLeft: "-1em",
    columnGap: "0.5em",
  };
}

function _buttonBaseStyle(
  isMobile: boolean,
  theme: Theme,
  country: TPrepareCountry
) {
  return {
    color: "inherit",
    justifyContent: "flex-start",
    // fontSize: isMobile ? "16px" : "11px",
    fontSize: isMobile ? "16px" : "0.75em",
    border: isMobile ? `1px solid ${theme.palette.primary.light}` : "none",
    padding: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: country.isActive
      ? reference.countries[country.countryId].colors["main"]
      : "inherit",
    opacity: country.isActive ? 1 : 0.6,
    alignItems: "center",

    "&:hover": {
      color: reference.countries[country.countryId].colors["main"],
      opacity: country.isActive ? 0.6 : 1,
    },
  };
}

function _countryStyle() {
  return {
    color: "inherit",
    paddingBottom: "0.1em",
  };
}

function _buttonIconStyle() {
  return {
    paddingLeft: "0.01rem",
    paddingRight: "0.01rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginRight: "0.2em",
    // marginRight: "0.5em",
  };
}
