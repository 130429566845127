// ==========
// types
// ==========
type TBusProps = {
  val: any;
  fn: Function;
  triggers: {
    maps: Map<string, Function>;
  };
  lastFileId: string;
};

type TBusContextIn = {
  [name: symbol]: TBusProps;
};

// export type TBusContext = typeof BusContext;

// ==========
// BusContext
// ==========
export class BusContext {
  _context: TBusContextIn;

  constructor() {
    this._context = {};
  }

  _triggerIt(name: symbol) {
    this._context[name].triggers.maps.forEach((fn) => {
      fn({});
    });
  }

  _triggerItSome(name: symbol, arrFileId: Array<string>) {
    const newArr = [] as Array<Function>;
    for (const [fileID, fn] of this._context[name].triggers.maps.entries()) {
      if (arrFileId.includes(fileID)) {
        newArr.push(fn);
      }
    }
    newArr.forEach((fn) => {
      fn({});
    });
  }

  setBusContext<T, K>(name: symbol, val: T, setFn: (data: K) => K) {
    if (!this._context[name]) {
      this._context[name] = {} as TBusProps;
    }

    this._context[name].val = val;
    this._context[name].fn = setFn;
    this._context[name].triggers = {
      maps: new Map(),
    };
  }

  getDataFromBusOne(name: symbol) {
    return this._context[name].val;
  }

  getDataFromBus(names: Array<symbol>, fileID?: string, trigg?: Function) {
    if (fileID && trigg) {
      names.forEach((name) => {
        this._context[name].triggers.maps.set(fileID, trigg);
      });
    }

    const vals = names.reduce((accum, name: symbol) => {
      if (name.description) {
        accum[name.description] = this._context[name].val;
      } else {
        throw new Error(
          `busContext: "description" of "symbol name" is "undefined"`
        );
      }
      return accum;
    }, {} as Record<string, any>);

    return vals;
  }

  placeDataToBus<T>(name: symbol, data: T, lastFileId: string) {
    this._context[name].val = this._context[name].fn(data);
    this._context[name].lastFileId = lastFileId;
    this._triggerIt(name);
  }

  placeDataToBusReplace<T>(name: symbol, newData: T, lastFileId: string): void {
    if (!Boolean(this._context[name].lastFileId)) {
      this._context[name].lastFileId = lastFileId;
      return;
    }

    this._context[name].val = this._context[name].fn(newData);
    const newArr: Array<string> = [];

    newArr.push(this._context[name].lastFileId);
    newArr.push(lastFileId);

    this._context[name].lastFileId = lastFileId;
    this._triggerItSome(name, newArr);
  }
}
