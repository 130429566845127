// ============
// import
// ============
import { type TMinState } from "../mainStore";
// import { EStatus, type TEStatus } from "../../../enums/EStatus";

// ============
// main
// ============
export const upsertOne = <
  U extends string,
  T extends { id: string; active: U }
>(
  state: TMinState,
  action: T
): TMinState => {
  try {
    const stateCopy = Object.assign({}, state);
    // console.log(
    //   "%cnoredux/handlers/upsertOne::stateCopy_before: ",
    //   "color: orange",
    //   stateCopy
    // );
    // stateCopy[entities.action.id] = action.active;
    if (!stateCopy.entities[action.id]) {
      stateCopy.ids.push(action.id);
    }
    stateCopy.entities[action.id] = action.active;
    // console.log(
    //   "%cnoredux/handlers/updateOne::stateCopy_after: ",
    //   "color: coral",
    //   stateCopy
    // );
    // return { ids: state.ids, entities: stateCopy };
    return stateCopy;
  } catch (error) {
    console.info("%cnoredux/handlers/upsertOne: ", "color: red", error);
    throw new Error();
  }
};
