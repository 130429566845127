// ===========
// import
// ===========
import * as bm from "../barrelModule";
import * as React from "react";
import { RStyledSource } from "../../../../styled/RStyledSource";
import Box from "@mui/material/Box";
import { type Theme, type Breakpoint } from "@mui/material/styles";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { type TThemeSettings } from "../../../../theming/themeSettings";

// ===========
// types
// ===========
type TComponentProps = {
  lang: bm.ELangs;
  target: bm.TEWhatVal;
  baseHeight: string;
  isMobile: boolean;
};
type TResult = {
  baseHeight: string;
  lastupdated: string;
  ourlastupdated: string;
  target: string;
  targetHTTP: string;
};

// ===========
// constants
// ===========
const URL_TEMPLATE = "/data/meta/:target/metadata.json";

// ===========
// regex
// ===========
const regex = /([a-z]+)_([a-z_0-9]+)/;

// ===========
// main
// ===========
export function RSource(props: TComponentProps) {
  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RSource";
  const { isAnimation } = busContext.getDataFromBus(
    [busNames.isAnimation, busNames.colorMode],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);

  // --------
  // local state
  // --------
  const [resultData, setResultData] = bm.useState<TResult>({} as TResult);
  const [isReady, setIsReady] = bm.useState(false);

  const regResult = props.target.match(regex);

  let targetAddress = "";

  if (regResult === null) {
    throw new Error("RSource: 'regResult === null.");
  } else {
    targetAddress = regResult[2];
  }

  // --------
  // http address of a target
  // --------
  const httpAddress = URL_TEMPLATE.replace(":target", targetAddress);

  // --------
  // Effects
  // --------
  bm.useEffect(() => {
    (async () => {
      const result = await _fetchToServer(httpAddress);
      setResultData(
        Object.assign({}, result, { baseHeight: props.baseHeight })
      );
      setIsReady(true);
    })();
  }, [httpAddress]);

  /** */
  React.useEffect(() => {
    busContext.placeDataToBus(busNames.targetInContext, props.target, fileID);
  }, [props.target]);

  /** */
  const data = Object.assign({}, resultData, { target: props.target });

  // --------
  // rendering
  // --------
  if (isAnimation) {
    return (
      <Box
        className="RStyledSourceWrapper"
        sx={_stylerRStyledSourceWrapper(props.isMobile)}
      >
        {!props.isMobile && (
          <Box
            className="RStyledSourceWrapper_MockBack"
            sx={_stylerRStyledSourceWrapper_MockBack(theme)}
          ></Box>
        )}
        <Box />
      </Box>
    );
  } else {
    return (
      <Box
        className="RStyledSourceWrapper"
        sx={_stylerRStyledSourceWrapper(props.isMobile)}
      >
        {!props.isMobile && (
          <Box
            className="RStyledSourceWrapper_MockBack"
            sx={_stylerRStyledSourceWrapper_MockBack(theme)}
          ></Box>
        )}
        <Box
          className="RStyledSourceWrapper_Data"
          sx={_stylerRStyledSourceWrapper_Data(theme)}
        >
          <Box
            className="RStyledSourceWrapper_Data_Container"
            sx={_stylerRStyledSourceWrapper_Data_Container(props.isMobile)}
          >
            {isReady && <RStyledSource {...data} lang={props.lang} />}
          </Box>
        </Box>
      </Box>
    );
  }
}

// ===========
// utils
// ===========
async function _fetchToServer(httpAddress: string) {
  return await fetch(httpAddress).then((response) => response.json());
}

// ===========
// stylers
// ===========
function _stylerRStyledSourceWrapper(isMobile: boolean) {
  return {
    display: "grid",
    gridTemplateColumns: !isMobile ? "38.2% auto" : "auto",
    height: "100%",
    width: "100%",
  };
}

function _stylerRStyledSourceWrapper_MockBack(theme: TThemeSettings) {
  const OVERLAY_OPACITY = 16;
  const { color, kefs } = theme.overlay;
  const overlayColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${
    kefs[`k${OVERLAY_OPACITY}`]
  } )`;

  return {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(${overlayColor}, ${overlayColor})`,
  };
}

function _stylerRStyledSourceWrapper_Data(theme: Theme) {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    overflowY: "hidden",
    padding: "1em 0",
    [theme.breakpoints.up("xl" as Breakpoint)]: {
      padding: 0,
    },
  };
}

function _stylerRStyledSourceWrapper_Data_Container(isMobile: boolean) {
  return {
    overflowY: !isMobile ? "auto" : "hidden",
  };
}
