// =============
// import
// =============
import { fetchData } from "../../../func/fetch/fetchData";
import { type TECountriesVal } from "../../../enums/ECountries";

// =============
// main
// =============
export async function genericFetchData(state: any, target: any) {
  const serverTarget = target.slice(target.indexOf("_") + 1);
  let data;
  const cntrs = state["countries"].ids as Array<TECountriesVal>;

  try {
    data = await Promise.all(
      cntrs.map((country) => fetchData(serverTarget, country))
    );
  } catch (error) {
    console.log("%cnoredux/handlers/generiFetchData: ", "color: red", error);
  }

  return {
    countries: cntrs,
    data,
  };
}
