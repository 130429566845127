// ========
// import
// ========
import { type CountryType } from "../components/other_countries/ROtherCountries";
import { ELangs } from "../enums/ELangs";

// ========
// main
// ========
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  { id: "AD", label: { [ELangs.EN]: "Andorra", [ELangs.RU]: "Андорра" } },
  {
    id: "AE",
    label: {
      [ELangs.EN]: "United Arab Emirates",
      [ELangs.RU]: "Арабские Эмираты",
    },
  },
  {
    id: "AF",
    label: { [ELangs.EN]: "Afghanistan", [ELangs.RU]: "Афганистан" },
  },
  {
    id: "AG",
    label: {
      [ELangs.EN]: "Antigua and Barbuda",
      [ELangs.RU]: "Антигуа и Барбуда",
    },
  },
  { id: "AI", label: { [ELangs.EN]: "Anguilla", [ELangs.RU]: "Ангилья" } },
  { id: "AL", label: { [ELangs.EN]: "Albania", [ELangs.RU]: "Албания" } },
  { id: "AO", label: { [ELangs.EN]: "Angola", [ELangs.RU]: "Ангола" } },
  { id: "AQ", label: { [ELangs.EN]: "Antarctica", [ELangs.RU]: "Антарктика" } },
  { id: "AR", label: { [ELangs.EN]: "Argentina", [ELangs.RU]: "Аргентина" } },
  {
    id: "AS",
    label: { [ELangs.EN]: "American Samoa", [ELangs.RU]: "Восточное Самоа" },
  },
  { id: "AT", label: { [ELangs.EN]: "Austria", [ELangs.RU]: "Австрия" } },
  {
    id: "AU",
    label: { [ELangs.EN]: "Australia", [ELangs.RU]: "Австралия" },
  },
  { id: "AW", label: { [ELangs.EN]: "Aruba", [ELangs.RU]: "Аруба" } },
  {
    id: "BA",
    label: {
      [ELangs.EN]: "Bosnia and Herzegovina",
      [ELangs.RU]: "Босния и Герцеговина",
    },
  },
  { id: "BB", label: { [ELangs.EN]: "Barbados", [ELangs.RU]: "Барбадос" } },
  { id: "BD", label: { [ELangs.EN]: "Bangladesh", [ELangs.RU]: "Бангладеш" } },
  { id: "BE", label: { [ELangs.EN]: "Belgium", [ELangs.RU]: "Бельгия" } },
  {
    id: "BF",
    label: { [ELangs.EN]: "Burkina Faso", [ELangs.RU]: "Буркина-Фасо" },
  },
  { id: "BG", label: { [ELangs.EN]: "Bulgaria", [ELangs.RU]: "Болгария" } },
  { id: "BH", label: { [ELangs.EN]: "Bahrain", [ELangs.RU]: "Бахрейн" } },
  { id: "BI", label: { [ELangs.EN]: "Burundi", [ELangs.RU]: "Бурунди" } },
  { id: "BJ", label: { [ELangs.EN]: "Benin", [ELangs.RU]: "Бенин" } },
  { id: "BM", label: { [ELangs.EN]: "Bermuda", [ELangs.RU]: "Бермуды" } },
  {
    id: "BN",
    label: {
      [ELangs.EN]: "Brunei Darussalam",
      [ELangs.RU]: "Бруней-Даруссалам",
    },
  },
  { id: "BO", label: { [ELangs.EN]: "Bolivia", [ELangs.RU]: "Боливия" } },
  { id: "BR", label: { [ELangs.EN]: "Brazil", [ELangs.RU]: "Бразилия" } },
  { id: "BS", label: { [ELangs.EN]: "Bahamas", [ELangs.RU]: "Багамы" } },
  { id: "BT", label: { [ELangs.EN]: "Bhutan", [ELangs.RU]: "Бутан" } },
  {
    id: "BV",
    label: { [ELangs.EN]: "Bouvet Island", [ELangs.RU]: "Буве, остров" },
  },
  { id: "BW", label: { [ELangs.EN]: "Botswana", [ELangs.RU]: "Ботсвана" } },
  { id: "BZ", label: { [ELangs.EN]: "Belize", [ELangs.RU]: "Белиз" } },
  {
    id: "CA",
    label: { [ELangs.EN]: "Canada", [ELangs.RU]: "Канада" },
  },
  {
    id: "CC",
    label: {
      [ELangs.EN]: "Cocos (Keeling) Islands",
      [ELangs.RU]: "Кокосовые острова",
    },
  },
  {
    id: "CD",
    label: { [ELangs.EN]: "Congo, Democratic Republic", [ELangs.RU]: "Конго" },
  },
  {
    id: "CF",
    label: {
      [ELangs.EN]: "Central African Republic",
      [ELangs.RU]: "Центрально-Африканская Республика",
    },
  },
  {
    id: "CG",
    label: {
      [ELangs.EN]: "Congo, Republic of the",
      [ELangs.RU]: "Конго (Браззавиль)",
    },
  },
  { id: "CH", label: { [ELangs.EN]: "Switzerland", [ELangs.RU]: "Швейцария" } },
  {
    id: "CI",
    label: { [ELangs.EN]: "Cote d'Ivoire", [ELangs.RU]: "Кот-д’Ивуар" },
  },
  {
    id: "CK",
    label: { [ELangs.EN]: "Cook Islands", [ELangs.RU]: "Кука, острова" },
  },
  { id: "CL", label: { [ELangs.EN]: "Chile", [ELangs.RU]: "Чили" } },
  { id: "CM", label: { [ELangs.EN]: "Cameroon", [ELangs.RU]: "Камерун" } },
  { id: "CN", label: { [ELangs.EN]: "China", [ELangs.RU]: "Китай" } },
  { id: "CO", label: { [ELangs.EN]: "Colombia", [ELangs.RU]: "Колумбия" } },
  { id: "CR", label: { [ELangs.EN]: "Costa Rica", [ELangs.RU]: "Коста-Рика" } },
  { id: "CU", label: { [ELangs.EN]: "Cuba", [ELangs.RU]: "Куба" } },
  { id: "CV", label: { [ELangs.EN]: "Cape Verde", [ELangs.RU]: "Кабо-Верде" } },
  {
    id: "CX",
    label: {
      [ELangs.EN]: "Christmas Island",
      [ELangs.RU]: "Рождества (Кристмас), остров",
    },
  },
  { id: "CY", label: { [ELangs.EN]: "Cyprus", [ELangs.RU]: "Кипр" } },
  { id: "CZ", label: { [ELangs.EN]: "Czech Republic", [ELangs.RU]: "Чехия" } },
  {
    id: "DE",
    label: { [ELangs.EN]: "Germany", [ELangs.RU]: "Германия" },
  },
  { id: "DJ", label: { [ELangs.EN]: "Djibouti", [ELangs.RU]: "Джибути" } },
  { id: "DK", label: { [ELangs.EN]: "Denmark", [ELangs.RU]: "Дания" } },
  { id: "DM", label: { [ELangs.EN]: "Dominica", [ELangs.RU]: "Доминика" } },
  {
    id: "DO",
    label: {
      [ELangs.EN]: "Dominican Republic",
      [ELangs.RU]: "Доминиканская Республика",
    },
  },
  { id: "DZ", label: { [ELangs.EN]: "Algeria", [ELangs.RU]: "Алжир" } },
  { id: "EC", label: { [ELangs.EN]: "Ecuador", [ELangs.RU]: "Эквадор" } },
  { id: "EE", label: { [ELangs.EN]: "Estonia", [ELangs.RU]: "Эстония" } },
  { id: "EG", label: { [ELangs.EN]: "Egypt", [ELangs.RU]: "Египет" } },
  {
    id: "EH",
    label: { [ELangs.EN]: "Western Sahara", [ELangs.RU]: "Западная Сахара" },
  },
  { id: "ER", label: { [ELangs.EN]: "Eritrea", [ELangs.RU]: "Эритрея" } },
  { id: "ES", label: { [ELangs.EN]: "Spain", [ELangs.RU]: "Испания" } },
  { id: "ET", label: { [ELangs.EN]: "Ethiopia", [ELangs.RU]: "Эфиопия" } },
  { id: "FI", label: { [ELangs.EN]: "Finland", [ELangs.RU]: "Финляндия" } },
  { id: "FJ", label: { [ELangs.EN]: "Fiji", [ELangs.RU]: "Фиджи" } },
  {
    id: "FK",
    label: {
      [ELangs.EN]: "Falkland Islands (Malvinas)",
      [ELangs.RU]: "Фолклендские (Мальвинские) острова",
    },
  },
  {
    id: "FM",
    label: {
      [ELangs.EN]: "Micronesia, Federated States of",
      [ELangs.RU]: "Микронезия (Федеративные Штаты Микронезии)",
    },
  },
  {
    id: "FO",
    label: { [ELangs.EN]: "Faroe Islands", [ELangs.RU]: "Фарерские острова" },
  },
  {
    id: "FR",
    label: { [ELangs.EN]: "France", [ELangs.RU]: "Франция" },
  },
  { id: "GA", label: { [ELangs.EN]: "Gabon", [ELangs.RU]: "Габон" } },
  {
    id: "GB",
    label: { [ELangs.EN]: "United Kingdom", [ELangs.RU]: "Великобритания" },
  },
  { id: "GD", label: { [ELangs.EN]: "Grenada", [ELangs.RU]: "Гренада" } },
  {
    id: "GF",
    label: { [ELangs.EN]: "French Guiana", [ELangs.RU]: "Гвиана Французская" },
  },
  { id: "GH", label: { [ELangs.EN]: "Ghana", [ELangs.RU]: "Гана" } },
  { id: "GI", label: { [ELangs.EN]: "Gibraltar", [ELangs.RU]: "Гибралтар" } },
  { id: "GL", label: { [ELangs.EN]: "Greenland", [ELangs.RU]: "Гренландия" } },
  { id: "GM", label: { [ELangs.EN]: "Gambia", [ELangs.RU]: "Гамбия" } },
  { id: "GN", label: { [ELangs.EN]: "Guinea", [ELangs.RU]: "Гвинея" } },
  { id: "GP", label: { [ELangs.EN]: "Guadeloupe", [ELangs.RU]: "Гваделупа" } },
  {
    id: "GQ",
    label: {
      [ELangs.EN]: "Equatorial Guinea",
      [ELangs.RU]: "Экваториальная Гвинея",
    },
  },
  { id: "GR", label: { [ELangs.EN]: "Greece", [ELangs.RU]: "Греция" } },
  {
    id: "GS",
    label: {
      [ELangs.EN]: "South Georgia and the South Sandwich Islands",
      [ELangs.RU]: "Южная Георгия и Южные Сандвичевы острова",
    },
  },
  { id: "GT", label: { [ELangs.EN]: "Guatemala", [ELangs.RU]: "Гватемала" } },
  { id: "GU", label: { [ELangs.EN]: "Guam", [ELangs.RU]: "Гуам" } },
  {
    id: "GW",
    label: { [ELangs.EN]: "Guinea-Bissau", [ELangs.RU]: "Гвинея-Бисау" },
  },
  { id: "GY", label: { [ELangs.EN]: "Guyana", [ELangs.RU]: "Гайана" } },
  { id: "HK", label: { [ELangs.EN]: "Hong Kong", [ELangs.RU]: "Гонконг" } },
  {
    id: "HM",
    label: {
      [ELangs.EN]: "Heard Island and McDonald Islands",
      [ELangs.RU]: "Херд и Макдональд, острова",
    },
  },
  { id: "HN", label: { [ELangs.EN]: "Honduras", [ELangs.RU]: "Гондурас" } },
  { id: "HR", label: { [ELangs.EN]: "Croatia", [ELangs.RU]: "Хорватия" } },
  { id: "HT", label: { [ELangs.EN]: "Haiti", [ELangs.RU]: "Гаити" } },
  { id: "HU", label: { [ELangs.EN]: "Hungary", [ELangs.RU]: "Венгрия" } },
  { id: "ID", label: { [ELangs.EN]: "Indonesia", [ELangs.RU]: "Индонезия" } },
  { id: "IE", label: { [ELangs.EN]: "Ireland", [ELangs.RU]: "Ирландия" } },
  { id: "IL", label: { [ELangs.EN]: "Israel", [ELangs.RU]: "Израиль" } },
  { id: "IN", label: { [ELangs.EN]: "India", [ELangs.RU]: "Индия" } },
  {
    id: "IO",
    label: {
      [ELangs.EN]: "British Indian Ocean Territory",
      [ELangs.RU]: "Британская территория в Индийском океане",
    },
  },
  { id: "IQ", label: { [ELangs.EN]: "Iraq", [ELangs.RU]: "Ирак" } },
  {
    id: "IR",
    label: { [ELangs.EN]: "Iran, Islamic Republic of", [ELangs.RU]: "Иран" },
  },
  { id: "IS", label: { [ELangs.EN]: "Iceland", [ELangs.RU]: "Исландия" } },
  { id: "IT", label: { [ELangs.EN]: "Italy", [ELangs.RU]: "Италия" } },
  { id: "JM", label: { [ELangs.EN]: "Jamaica", [ELangs.RU]: "Ямайка" } },
  { id: "JO", label: { [ELangs.EN]: "Jordan", [ELangs.RU]: "Иордания" } },
  {
    id: "JP",
    label: { [ELangs.EN]: "Japan", [ELangs.RU]: "Япония" },
  },
  { id: "KE", label: { [ELangs.EN]: "Kenya", [ELangs.RU]: "Кения" } },
  { id: "KH", label: { [ELangs.EN]: "Cambodia", [ELangs.RU]: "Камбоджа" } },
  { id: "KI", label: { [ELangs.EN]: "Kiribati", [ELangs.RU]: "Кирибати" } },
  { id: "KM", label: { [ELangs.EN]: "Comoros", [ELangs.RU]: "Коморы" } },
  {
    id: "KN",
    label: {
      [ELangs.EN]: "Saint Kitts and Nevis",
      [ELangs.RU]: "Сент-Китс и Невис",
    },
  },
  {
    id: "KP",
    label: {
      [ELangs.EN]: "Korea, Democratic People's Republic of",
      [ELangs.RU]: "Корейская Народно-Демократическая Республика",
    },
  },
  {
    id: "KR",
    label: {
      [ELangs.EN]: "Korea, Republic of",
      [ELangs.RU]: "Корея, Республика",
    },
  },
  { id: "KW", label: { [ELangs.EN]: "Kuwait", [ELangs.RU]: "Кувейт" } },
  {
    id: "KY",
    label: { [ELangs.EN]: "Cayman Islands", [ELangs.RU]: "Кайман, острова" },
  },
  {
    id: "LA",
    label: {
      [ELangs.EN]: "Lao People's Democratic Republic",
      [ELangs.RU]: "Лаос",
    },
  },
  { id: "LB", label: { [ELangs.EN]: "Lebanon", [ELangs.RU]: "Ливан" } },
  {
    id: "LC",
    label: { [ELangs.EN]: "Saint Lucia", [ELangs.RU]: "Сент-Люсия" },
  },
  {
    id: "LI",
    label: { [ELangs.EN]: "Liechtenstein", [ELangs.RU]: "Лихтенштейн" },
  },
  { id: "LK", label: { [ELangs.EN]: "Sri Lanka", [ELangs.RU]: "Шри-Ланка" } },
  { id: "LR", label: { [ELangs.EN]: "Liberia", [ELangs.RU]: "Либерия" } },
  { id: "LS", label: { [ELangs.EN]: "Lesotho", [ELangs.RU]: "Лесото" } },
  { id: "LU", label: { [ELangs.EN]: "Luxembourg", [ELangs.RU]: "Люксембург" } },
  {
    id: "LY",
    label: {
      [ELangs.EN]: "Libya",
      [ELangs.RU]: "Ливийская Арабская Джамахирия (Ливия)",
    },
  },
  { id: "MA", label: { [ELangs.EN]: "Morocco", [ELangs.RU]: "Марокко" } },
  { id: "MC", label: { [ELangs.EN]: "Monaco", [ELangs.RU]: "Монако" } },
  { id: "ME", label: { [ELangs.EN]: "Montenegro", [ELangs.RU]: "Черногрия" } },
  { id: "MG", label: { [ELangs.EN]: "Madagascar", [ELangs.RU]: "Мадагаскар" } },
  {
    id: "MH",
    label: {
      [ELangs.EN]: "Marshall Islands",
      [ELangs.RU]: "Маршалловы острова",
    },
  },
  {
    id: "MK",
    label: {
      [ELangs.EN]: "Macedonia, the Former Yugoslav Republic of",
      [ELangs.RU]: "Северная Македония",
    },
  },
  { id: "ML", label: { [ELangs.EN]: "Mali", [ELangs.RU]: "Мали" } },
  { id: "MM", label: { [ELangs.EN]: "Myanmar", [ELangs.RU]: "Мьянма" } },
  { id: "MN", label: { [ELangs.EN]: "Mongolia", [ELangs.RU]: "Монголия" } },
  { id: "MO", label: { [ELangs.EN]: "Macao", [ELangs.RU]: "Аомынь (Макао)" } },
  {
    id: "MP",
    label: {
      [ELangs.EN]: "Northern Mariana Islands",
      [ELangs.RU]: "Северные Марианские острова",
    },
  },
  { id: "MQ", label: { [ELangs.EN]: "Martinique", [ELangs.RU]: "Мартиника" } },
  { id: "MR", label: { [ELangs.EN]: "Mauritania", [ELangs.RU]: "Мавритания" } },
  { id: "MS", label: { [ELangs.EN]: "Montserrat", [ELangs.RU]: "Монтсеррат" } },
  { id: "MT", label: { [ELangs.EN]: "Malta", [ELangs.RU]: "Мальта" } },
  { id: "MU", label: { [ELangs.EN]: "Mauritius", [ELangs.RU]: "Маврикий" } },
  { id: "MV", label: { [ELangs.EN]: "Maldives", [ELangs.RU]: "Мальдивы" } },
  { id: "MW", label: { [ELangs.EN]: "Malawi", [ELangs.RU]: "Малави" } },
  { id: "MX", label: { [ELangs.EN]: "Mexico", [ELangs.RU]: "Мексика" } },
  { id: "MY", label: { [ELangs.EN]: "Malaysia", [ELangs.RU]: "Малайзия" } },
  { id: "MZ", label: { [ELangs.EN]: "Mozambique", [ELangs.RU]: "Мозамбик" } },
  { id: "NA", label: { [ELangs.EN]: "Namibia", [ELangs.RU]: "Намибия" } },
  {
    id: "NC",
    label: { [ELangs.EN]: "New Caledonia", [ELangs.RU]: "Новая Каледония" },
  },
  { id: "NE", label: { [ELangs.EN]: "Niger", [ELangs.RU]: "Нигер" } },
  {
    id: "NF",
    label: { [ELangs.EN]: "Norfolk Island", [ELangs.RU]: "Норфолк" },
  },
  { id: "NG", label: { [ELangs.EN]: "Nigeria", [ELangs.RU]: "Нигерия" } },
  { id: "NI", label: { [ELangs.EN]: "Nicaragua", [ELangs.RU]: "Никарагуа" } },
  {
    id: "NL",
    label: { [ELangs.EN]: "Netherlands", [ELangs.RU]: "Нидерланды" },
  },
  { id: "NO", label: { [ELangs.EN]: "Norway", [ELangs.RU]: "Норвегия" } },
  { id: "NP", label: { [ELangs.EN]: "Nepal", [ELangs.RU]: "Непал" } },
  { id: "NR", label: { [ELangs.EN]: "Nauru", [ELangs.RU]: "Науру" } },
  { id: "NU", label: { [ELangs.EN]: "Niue", [ELangs.RU]: "Ниуэ" } },
  {
    id: "NZ",
    label: { [ELangs.EN]: "New Zealand", [ELangs.RU]: "Новая Зеландия" },
  },
  { id: "OM", label: { [ELangs.EN]: "Oman", [ELangs.RU]: "Оман" } },
  { id: "PA", label: { [ELangs.EN]: "Panama", [ELangs.RU]: "Панама" } },
  { id: "PE", label: { [ELangs.EN]: "Peru", [ELangs.RU]: "Перу" } },
  {
    id: "PF",
    label: {
      [ELangs.EN]: "French Polynesia",
      [ELangs.RU]: "Французская Полинезия",
    },
  },
  {
    id: "PG",
    label: {
      [ELangs.EN]: "Papua New Guinea",
      [ELangs.RU]: "Папуа-Новая Гвинея",
    },
  },
  { id: "PH", label: { [ELangs.EN]: "Philippines", [ELangs.RU]: "Филиппины" } },
  { id: "PK", label: { [ELangs.EN]: "Pakistan", [ELangs.RU]: "Пакистан" } },
  { id: "PL", label: { [ELangs.EN]: "Poland", [ELangs.RU]: "Польша" } },
  {
    id: "PM",
    label: {
      [ELangs.EN]: "Saint Pierre and Miquelon",
      [ELangs.RU]: "Сен-Пьер и Микелон",
    },
  },
  { id: "PN", label: { [ELangs.EN]: "Pitcairn", [ELangs.RU]: "Питкэрн" } },
  {
    id: "PR",
    label: { [ELangs.EN]: "Puerto Rico", [ELangs.RU]: "Пуэрто-Рико" },
  },
  { id: "PT", label: { [ELangs.EN]: "Portugal", [ELangs.RU]: "Португалия" } },
  { id: "PW", label: { [ELangs.EN]: "Palau", [ELangs.RU]: "Палау" } },
  { id: "PY", label: { [ELangs.EN]: "Paraguay", [ELangs.RU]: "Парагвай" } },
  { id: "QA", label: { [ELangs.EN]: "Qatar", [ELangs.RU]: "Катар" } },
  { id: "RE", label: { [ELangs.EN]: "Reunion", [ELangs.RU]: "Реюньон" } },
  { id: "RO", label: { [ELangs.EN]: "Romania", [ELangs.RU]: "Румыния" } },
  { id: "RS", label: { [ELangs.EN]: "Serbia", [ELangs.RU]: "Сербия" } },
  { id: "RW", label: { [ELangs.EN]: "Rwanda", [ELangs.RU]: "Руанда" } },
  {
    id: "SA",
    label: { [ELangs.EN]: "Saudi Arabia", [ELangs.RU]: "Саудовская Аравия" },
  },
  {
    id: "SB",
    label: {
      [ELangs.EN]: "Solomon Islands",
      [ELangs.RU]: "Соломоновы Острова",
    },
  },
  { id: "SC", label: { [ELangs.EN]: "Seychelles", [ELangs.RU]: "Сейшелы" } },
  { id: "SD", label: { [ELangs.EN]: "Sudan", [ELangs.RU]: "Судан" } },
  { id: "SE", label: { [ELangs.EN]: "Sweden", [ELangs.RU]: "Швеция" } },
  { id: "SG", label: { [ELangs.EN]: "Singapore", [ELangs.RU]: "Сингапур" } },
  {
    id: "SH",
    label: { [ELangs.EN]: "Saint Helena", [ELangs.RU]: "Святая Елена" },
  },
  { id: "SI", label: { [ELangs.EN]: "Slovenia", [ELangs.RU]: "Словения" } },
  {
    id: "SJ",
    label: {
      [ELangs.EN]: "Svalbard and Jan Mayen",
      [ELangs.RU]: "Свальбард (Шпицберген) и Ян-Майен",
    },
  },
  { id: "SK", label: { [ELangs.EN]: "Slovakia", [ELangs.RU]: "Словакия" } },
  {
    id: "SL",
    label: { [ELangs.EN]: "Sierra Leone", [ELangs.RU]: "Сьерра-Леоне" },
  },
  { id: "SM", label: { [ELangs.EN]: "San Marino", [ELangs.RU]: "Сан-Марино" } },
  { id: "SN", label: { [ELangs.EN]: "Senegal", [ELangs.RU]: "Сенегал" } },
  { id: "SO", label: { [ELangs.EN]: "Somalia", [ELangs.RU]: "Сомали" } },
  { id: "SR", label: { [ELangs.EN]: "Suriname", [ELangs.RU]: "Суринам" } },
  {
    id: "ST",
    label: {
      [ELangs.EN]: "Sao Tome and Principe",
      [ELangs.RU]: "Сан-Томе и Принсипи",
    },
  },
  { id: "SV", label: { [ELangs.EN]: "El Salvador", [ELangs.RU]: "Сальвадор" } },
  {
    id: "SY",
    label: { [ELangs.EN]: "Syrian Arab Republic", [ELangs.RU]: "Сирия" },
  },
  { id: "SZ", label: { [ELangs.EN]: "Swaziland", [ELangs.RU]: "Свазиленд" } },
  {
    id: "TC",
    label: {
      [ELangs.EN]: "Turks and Caicos Islands",
      [ELangs.RU]: "Теркс и Кайкос",
    },
  },
  { id: "TD", label: { [ELangs.EN]: "Chad", [ELangs.RU]: "Чад" } },
  {
    id: "TF",
    label: {
      [ELangs.EN]: "French Southern Territories",
      [ELangs.RU]: "Французские Южные территории",
    },
  },
  { id: "TG", label: { [ELangs.EN]: "Togo", [ELangs.RU]: "Того" } },
  { id: "TH", label: { [ELangs.EN]: "Thailand", [ELangs.RU]: "Таиланд" } },
  { id: "TK", label: { [ELangs.EN]: "Tokelau", [ELangs.RU]: "Токелау" } },
  { id: "TN", label: { [ELangs.EN]: "Tunisia", [ELangs.RU]: "Тунис" } },
  { id: "TO", label: { [ELangs.EN]: "Tonga", [ELangs.RU]: "Тонга" } },
  { id: "TR", label: { [ELangs.EN]: "Turkey", [ELangs.RU]: "Турция" } },
  {
    id: "TT",
    label: {
      [ELangs.EN]: "Trinidad and Tobago",
      [ELangs.RU]: "Тринидад и Тобаго",
    },
  },
  { id: "TV", label: { [ELangs.EN]: "Tuvalu", [ELangs.RU]: "Тувалу" } },
  {
    id: "TW",
    label: { [ELangs.EN]: "Taiwan", [ELangs.RU]: "Тайвань" },
  },
  {
    id: "TZ",
    label: {
      [ELangs.EN]: "United Republic of Tanzania",
      [ELangs.RU]: "Танзания",
    },
  },
  { id: "UG", label: { [ELangs.EN]: "Uganda", [ELangs.RU]: "Уганда" } },
  {
    id: "US",
    label: { [ELangs.EN]: "United States", [ELangs.RU]: "США" },
  },
  { id: "UY", label: { [ELangs.EN]: "Uruguay", [ELangs.RU]: "Уругвай" } },
  {
    id: "VA",
    label: { [ELangs.EN]: "Vatican", [ELangs.RU]: "Ватикан" },
  },
  {
    id: "VC",
    label: {
      [ELangs.EN]: "Saint Vincent and the Grenadines",
      [ELangs.RU]: "Сент-Винсент и Гренадины",
    },
  },
  { id: "VE", label: { [ELangs.EN]: "Venezuela", [ELangs.RU]: "Венесуэла" } },
  {
    id: "VG",
    label: {
      [ELangs.EN]: "British Virgin Islands",
      [ELangs.RU]: "Виргинские острова (Британские)",
    },
  },
  {
    id: "VI",
    label: {
      [ELangs.EN]: "US Virgin Islands",
      [ELangs.RU]: "Виргинские острова (США)",
    },
  },
  { id: "VN", label: { [ELangs.EN]: "Vietnam", [ELangs.RU]: "Вьетнам" } },
  { id: "VU", label: { [ELangs.EN]: "Vanuatu", [ELangs.RU]: "Вануату" } },
  {
    id: "WF",
    label: { [ELangs.EN]: "Wallis and Futuna", [ELangs.RU]: "Уоллис и Футуна" },
  },
  { id: "WS", label: { [ELangs.EN]: "Samoa", [ELangs.RU]: "Самоа" } },
  { id: "YE", label: { [ELangs.EN]: "Yemen", [ELangs.RU]: "Йемен" } },
  {
    id: "YT",
    label: { [ELangs.EN]: "Mayotte", [ELangs.RU]: "Маоре (Майотта)" },
  },
  { id: "ZA", label: { [ELangs.EN]: "South Africa", [ELangs.RU]: "ЮАР" } },
  { id: "ZM", label: { [ELangs.EN]: "Zambia", [ELangs.RU]: "Замбия" } },
  { id: "ZW", label: { [ELangs.EN]: "Zimbabwe", [ELangs.RU]: "Зимбабве" } },
];
