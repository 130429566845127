// =============
// import
// =============
import * as bm from "./barrelModule";
import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { type Theme /*type Breakpoint*/ } from "@mui/material/styles";
import { iconsStore } from "../../../hooks_g/useIcons";
import reference from "../../../reference.json";
// import { useNoreduxUpdater } from "../../../context/CNoReduxContextProvider";
import { ELangs } from "../../../enums/ELangs";
import { busContext } from "../../..";
import { busNames } from "../../../bus_context/busContextStore";
import { RHomeTabs } from "./RHomeTabs";
import { noReduxUpdater } from "../../../context/noredux/mainStore";
import changeUrl from "../../../func/url/changeUrl";

// =============
// types
// =============
type TComponentProps = {
  lang: bm.ELangs;
  theme: Theme;
  isTablet: boolean;
  isMobile: boolean;
  isDesktop: boolean;
};

// =============
// constants
// =============
const INDEX_OF_REFERENCE_TAB = 1;

// =============
// subcompnent
// =============
function StartWrapper(props: {
  tabNumberH: number;
  isDesktop: boolean;
  lang: ELangs;
  theme: Theme;
  isTablet: boolean;
}) {
  const fileID = "RHomePageWrapper::StartWrapper";

  // ----------
  // prerequisite
  // ----------
  const { ArrowRight } = iconsStore;

  // ----------
  // handlers
  // ----------
  const handleOnButtonClick = React.useCallback(
    (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();
      busContext.placeDataToBus(
        busNames.tabNumberH,
        INDEX_OF_REFERENCE_TAB,
        fileID
      );
    },
    // eslint-disable-next-line
    []
  );

  // ----------
  // rendering
  // ----------
  return (
    <Box
      className="RHomePageWrapper_StartWrapper"
      sx={_startWrapperStyle(props.theme, props.tabNumberH)}
    >
      <Button
        variant="outlined"
        endIcon={<ArrowRight />}
        onClick={handleOnButtonClick}
        sx={{
          visibility:
            props.tabNumberH === INDEX_OF_REFERENCE_TAB ? "hidden" : "visible",
          padding: props.isTablet ? "2px 15px" : "5px 15px",
        }}
      >
        {reference.greetings.startPage.startButton.text[props.lang]}
      </Button>
    </Box>
  );
}

// =============
// main
// =============
export function RHomePageWrapper(props: TComponentProps) {
  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RHomePageWrapper";
  const { tabNumberH } = busContext.getDataFromBus(
    [busNames.tabNumberH],
    fileID,
    setTrigg
  );
  //<

  changeUrl({ clmode: busContext.getDataFromBusOne(busNames.colorMode) });

  // ---
  // Effects
  // ---
  React.useEffect(() => {
    // ----------
    // noredux updater
    // ----------
    noReduxUpdater(setTrigg);
  }, []);

  // -------------
  // rendering
  // -------------
  return (
    <bm.Box
      className="RHomePageWrapper"
      sx={_wrappserStyle(props.isDesktop, props.isTablet, tabNumberH)}
    >
      <RHomeTabs
        lang={props.lang}
        theme={props.theme}
        isMobile={props.isMobile}
        isTablet={props.isTablet}
        tabNumberH={tabNumberH}
      />
      {props.isDesktop && <div></div>}
      <StartWrapper
        tabNumberH={tabNumberH}
        isDesktop={props.isDesktop}
        lang={props.lang}
        theme={props.theme}
        isTablet={props.isTablet}
      />
    </bm.Box>
  );
}

// =============
// styles
// =============
function _wrappserStyle(
  isDesktop: boolean,
  isTabletMode: boolean,
  tabNumberH: number
) {
  const isIndicatorsTab = tabNumberH === 1;
  const DESKTOP_WIDTH = "85%";
  const GAP = isTabletMode ? "0.5em" : "1em";
  const DESKTOP_HEIGHT_ROW_1 = isDesktop
    ? !isIndicatorsTab
      ? "88% auto"
      : "100% 0%"
    : !isIndicatorsTab
    ? "88% auto"
    : "100% 0%";
  const MOBILE_HEIGHT_ROW = !(tabNumberH === 1) ? "88% 12%" : "100% 0";

  // --------
  // CSSObject
  // --------
  return {
    display: "grid",
    gridTemplateColumns: isTabletMode
      ? "1fr"
      : isDesktop
      ? `${DESKTOP_WIDTH} 1fr`
      : "auto",
    minWidth: 0,
    rowGap: GAP,
    gridTemplateRows: isTabletMode
      ? DESKTOP_HEIGHT_ROW_1
      : isDesktop
      ? DESKTOP_HEIGHT_ROW_1
      : MOBILE_HEIGHT_ROW,
    overflow: isDesktop ? "hidden" : "visible",
    transition: "grid-template-rows 500ms 500ms",
  };
}

function _startWrapperStyle(theme: Theme, tabNumberH: number) {
  const isDark = theme.palette.mode === "dark";
  const buttonColor = isDark
    ? theme.palette.primary.main
    : theme.palette.primary.dark;
  const isIndicators = tabNumberH === 1 ? true : false;

  return {
    paddingLeft: "4px",
    paddingTop: "1em",
    paddingBottom: "1em",
    "& .MuiButton-root": {
      color: buttonColor,
      borderColor: buttonColor,
    },
    height: !isIndicators ? "100%" : "0%",
    opacity: !isIndicators ? 1 : 0,
    visibility: !isIndicators ? "visible" : "hidden",
    transition: "height 500ms",
    display: !isIndicators ? "initial" : "none",
  };
}
