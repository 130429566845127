// ============
// import
// ============
import reference from "../reference.json";
import { CUtilIter } from "../classes/CUtilIter";

// ============
// types
// ============
type TEWhatId = keyof typeof reference.targets & string;
type TEWhats = { [x: string]: TEWhatId };
type TEWhatKeys = keyof typeof EWhat & string;
export type TEWhatVal = (typeof EWhat)[TEWhatKeys];

// ============
// main
// ============
export const EWhat: TEWhats = (function () {
  const targets = reference.targets;
  const iterTargets = new CUtilIter(targets);
  let whats = {} as TEWhats;

  for (const what of iterTargets) {
    if (what === undefined) {
      break;
    }
    whats[what[0].toUpperCase()] = what[0] as TEWhatId;
  }

  whats = { ...whats } as const;

  // console.log("%cEWhat::whats: ", "color: yellow", whats);

  return whats;
})();
