// ===========
// import
// ===========
import { styled } from "@mui/material/styles";

// ===========
// types
// ===========
type TComponentProps = {
  flexDirection?: "row" | "column";
  marginBottom?: string;
  rowGap?: string;
};

// ===========
// main
// ===========
export const RStyledFlexContainer = styled("div")<TComponentProps>(
  (allProps) => {
    return {
      display: "flex",
      flexDirection: allProps.flexDirection,
      marginBottom: allProps.marginBottom,
      rowGap: allProps.rowGap,
    };
  }
);
