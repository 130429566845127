// ==========
// import
// ==========
import { styled, SxProps, Theme } from "@mui/material/styles";

// ==========
// types
// ==========
type TTooltipComponent = {
  sx?: SxProps<Theme>;
  tooltipObj: Record<string, any>;
  cntrs?: Record<string, string>;
  className?: string;
  targetPrecision: number;
};

// ==========
// styled
// ==========
export const RStyledTooltipComponent = styled(
  (props: TTooltipComponent) => <TooltipComponent {...props} />,
  {
    name: "IIIgStyledTooltipComponent",
    slot: "Root",
  }
)((allProps) => {
  return Object.assign(
    {},
    {
      display: "flex",
      backgroundColor: allProps.theme.palette.grey["50"],
      padding: "8px",
      alignItems: "center",
      color: allProps.theme.palette.grey["800"],
      fontSize: "1.5em",
      "& .IIIgTooltipColor": {
        width: "12px",
        height: "12px",
        backgroundColor: allProps.tooltipObj.point.serieColor
          ? allProps.tooltipObj.point.serieColor
          : allProps.tooltipObj.point.color,
        marginLeft: "2px",
      },
      "& .IIIgTooltipCountry": {
        flexBasis: 2,
        marginLeft: "8px",
      },
      "& .IIIgTooltipValue": {
        flexBasis: 2,
        marginLeft: "4px",
      },
    },
    { ...allProps.sx }
  );
});

// ==========
// main component
// ==========
function TooltipComponent(props: {
  tooltipObj: Record<string, any>;
  cntrs?: Record<string, string>;
  className?: string;
  targetPrecision: number;
}) {
  const { tooltipObj, cntrs } = props;
  const country =
    cntrs === undefined
      ? tooltipObj.point.data.shortName
      : cntrs[tooltipObj.point.serieId];
  // const value = Math.round(tooltipObj.point.data.y);
  const value = tooltipObj.point.data.y.toFixed(props.targetPrecision);

  // --------
  // rendering
  // --------
  return (
    <div className={props.className}>
      <div className="IIIgTooltipColor"></div>
      <span className="IIIgTooltipCountry">{country}:</span>
      <span className="IIIgTooltipValue"> {value}</span>
    </div>
  );
}
