// import
// =============
import * as React from "react";
import Box from "@mui/material/Box";
/* app */
import { RTopbarSearch } from "./RTopbarSearch";
import { RTopbarIcons } from "./PTopbarIcons";
import { RTopbarSidebar } from "./RTopbarSidebar";
import { RTopbarTargetSidebar } from "./RTopbarTargetSidebar";
import { type Palette } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { iconsStore } from "../../hooks_g/useIcons";
import Modal from "@mui/material/Modal";
import { ELangs } from "../../enums/ELangs";
import reference from "../../reference.json";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";

// ===========
// types
// ===========
type TMobileSearchProps = {
  fnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
type TMobileSearchInputPanelProps = {
  open: boolean;
  fnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
type TRTopbarSearchWithRef = {
  fnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// =============
// constants
// =============
// const SECONDS_TO_EXPIRE = 10000; // 10s

// =============
// subcomponents
// =============
const MobileSearch = (props: TMobileSearchProps) => {
  // -----------
  // icons
  // -----------
  const SearchIcon = iconsStore.search;

  // -----------
  // handlers
  // -----------
  const handleOnClick = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    evnt.stopPropagation();
    props.fnModalOpen((old: boolean) => !old);
  };

  // -----------
  // rendering
  // -----------
  return (
    <IconButton
      type="button"
      sx={{
        p: 1,
        color: "inherit",
      }}
      onClick={handleOnClick}
    >
      <SearchIcon />
    </IconButton>
  );
};

const RTopbarSearchWithRef = React.forwardRef<
  HTMLDivElement,
  TRTopbarSearchWithRef
>(function RTopbarSearchWithRef(props: TRTopbarSearchWithRef, ref) {
  return (
    <RTopbarSearch
      isMobile={true}
      modalRef={ref}
      fnModalOpen={props.fnModalOpen}
    />
  );
});

const MobileSearchInputPanel = (props: TMobileSearchInputPanelProps) => {
  // -----------
  // bus
  // -----------
  // eslint-disable-next-line
  const [_trigg, setTrigg] = React.useState({});
  const fileID = "RTopbarMain::MobileSearchInputPanel";
  const { lang } = busContext.getDataFromBus([busNames.lang], fileID, setTrigg);
  const theme = busContext.getDataFromBusOne(busNames.theme);
  // **

  const colors = theme.palette;

  // -----------
  // handlers
  // -----------
  const handleOnClose = () => {
    props.fnModalOpen((old: boolean) => !old);
  };

  // -----------
  // rendering
  // -----------
  return (
    <Modal
      className="RTopbarMain_MobileSearchInputPanel_Modal"
      open={props.open}
      onClose={handleOnClose}
      sx={_stylerMobileSearchInputPanel_Modal(lang, colors)}
      disableEnforceFocus
    >
      <RTopbarSearchWithRef fnModalOpen={props.fnModalOpen} />
    </Modal>
  );
};

// =============
// main
// =============
export function RTopbarMain() {
  // --------
  // local state
  // --------
  const [isShowMobileSearchInput, setIsShowMobileSearchInput] =
    React.useState(false);

  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RTopbarMain";
  const { lang } = busContext.getDataFromBus([busNames.lang], fileID, setTrigg);
  const { isDesktop, isMobile } = busContext.getDataFromBus([
    busNames.isDesktop,
    busNames.isMobile,
  ]);
  const theme = busContext.getDataFromBusOne(busNames.theme);
  // **

  // --------
  // variables
  // --------
  const colors = theme.palette;

  // ---------
  // pre-rendering
  // ---------
  const RTopbarSidebarMemo = React.useMemo(
    () => !isDesktop && <RTopbarSidebar />,
    // eslint-disable-next-line
    [lang, isDesktop]
    // [lang, isMobile]
  );

  const MobileSearchMemo = React.useMemo(
    () =>
      isMobile ? (
        <MobileSearch fnModalOpen={setIsShowMobileSearchInput} />
      ) : (
        <RTopbarSearch />
      ),
    // eslint-disable-next-line
    [lang, isMobile]
  );
  const MobileSearchInputPanelMemo = React.useMemo(
    () => (
      <MobileSearchInputPanel
        open={isShowMobileSearchInput}
        fnModalOpen={setIsShowMobileSearchInput}
      />
    ),
    // eslint-disable-next-line
    [isShowMobileSearchInput, lang]
  );
  const RTopbarIconsMemo = React.useMemo(
    () => <RTopbarIcons colors={colors} lang={lang} />,
    [colors, lang]
  );
  const RTopbarTargetSidebarMemo = React.useMemo(
    () => {
      return <RTopbarTargetSidebar lang={lang} />;
    },
    // eslint-disable-next-line
    [isMobile, lang]
  );

  // ---------
  // rendering
  // ---------
  return (
    <Box
      className="RTopbarMain"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        color:
          theme.palette.mode === "light"
            ? colors.primary.main
            : colors.primary.light,
        transition: "color 2s",
      }}
    >
      {isMobile && RTopbarSidebarMemo}
      {/* {isTablet && RTopbarTargetSidebarMemo} */}
      {MobileSearchMemo}
      {MobileSearchInputPanelMemo}
      {RTopbarIconsMemo}
      {isMobile && RTopbarTargetSidebarMemo}
    </Box>
  );
}

// =============
// stylers
// =============
function _stylerMobileSearchInputPanel_Modal(lang: ELangs, colors: Palette) {
  return {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    p: 4,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.6)",

    "& .MuiBox-root": {
      width: "100%",
      position: "relative",
      marginTop: "5em",
    },
    "& .MuiBackdrop-root::before": {
      position: "absolute",
      content: `'${reference.ui.close.name[lang].toUpperCase()}'`,
      color: colors.grey[400],
      // color: colors.primary.light,
      bottom: "64px",
      // fontWeight: "bold",
    },
  };
}
