// =============
// import
// =============
import React from "react";
import { useRef } from "react";
import { ResponsiveLine } from "@nivo/line";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { type Breakpoint, type Palette } from "@mui/material/styles";
import { type TECountriesVal } from "../../../../enums/ECountries";
import { type TEWhatVal } from "../../../../enums/EWhat";
import config from "../../../../config.json";
import { ELangs } from "../../../../enums/ELangs";
import { pickCountryColor } from "../../../../func/prepare/pickCountryColor";
import reference from "../../../../reference.json";
import { RStyledTooltipComponent } from "../../../../styled/RStyledTooltipComponent";
import { prepareFormatY as formatY } from "../../../../func/prepare/prepareFormatY";
import { iconsStore } from "../../../../hooks_g/useIcons";
import IconButton from "@mui/material/IconButton";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { type TThemeSettings } from "../../../../theming/themeSettings";
import Typography from "@mui/material/Typography";

// =============
// types
// =============
type TComponentProps = {
  data: Array<TData>;
  axisTitles: { x: string; y: string };
  lang: ELangs;
  minMaxY: { minY: number | null; maxY: number | null };
  target: TEWhatVal;
  xArray: Array<string> | null;
  xRange: Array<string> | null;
  rangeTupple: [] | [number, number];
  baseHeight: string;
  isCompressedLines: boolean;
  toggleIsCompressedLines: () => void;
  fnOpenInFullscreen(): void;
};
type TData = {
  id: TECountriesVal;
  data: Array<{ x: string; y: number }>;
  minX: string;
  maxX: string;
  stepX: number;
  minY: number;
  maxY: number;
  stepY: number;
};

// =============
// constants
// =============
const LEFT_PADDING = "24px";

// =============
// subcomponent
// =============
function ZeroToggle({
  flag,
  isDesktop,
  theme,
  toggleIsCompressedLines,
}: {
  flag: boolean;
  isDesktop: boolean;
  theme: TThemeSettings;
  toggleIsCompressedLines: () => void;
}) {
  const { CompressIcon, ExpandIcon } = iconsStore;

  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_styleZeroToggle(theme, isDesktop, "zero")}
      className="RLine_ZeroToggle"
    >
      <IconButton
        aria-label="toggle to zero"
        sx={{ color: "inherit" }}
        onClick={toggleIsCompressedLines}
      >
        {flag ? <ExpandIcon /> : <CompressIcon />}
      </IconButton>
    </Box>
  );
} //<

/// ---
function FullscreenToggle({
  flag,
  isDesktop,
  theme,
  fnOpenInFullscreen,
}: {
  flag: boolean;
  isDesktop: boolean;
  theme: TThemeSettings;
  fnOpenInFullscreen(): void;
}) {
  const { FullscreenIcon, FullscreenExitIcon } = iconsStore;

  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_styleZeroToggle(theme, isDesktop, "fullscreen")}
      className="RLine_FullscreenToggle"
    >
      <IconButton
        aria-label="toggle to zero"
        sx={{ color: "inherit" }}
        onClick={fnOpenInFullscreen}
      >
        {flag ? <FullscreenIcon /> : <FullscreenExitIcon />}
      </IconButton>
    </Box>
  );
} //<

//>>
function PostUssr({
  theme,
  isFullscreenMode,
}: {
  theme: TThemeSettings;
  isFullscreenMode: boolean;
}) {
  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_stylePostUssr(theme, isFullscreenMode)}
      className="RLine_PostUssr"
    >
      <Typography>https://postussr.info</Typography>
    </Box>
  );
} //<<

// =============
// main
// =============
export function RLine({
  data,
  axisTitles,
  minMaxY,
  target,
  xRange,
  lang,
  isCompressedLines,
  toggleIsCompressedLines,
  fnOpenInFullscreen,
}: TComponentProps) {
  // ---------
  // bus
  // ---------
  const [, setTrigg] = React.useState({});
  const fileID = "RLine";
  const { isAnimation, colorMode, isMobile, isFullscreenMode } =
    busContext.getDataFromBus(
      [
        busNames.isAnimation,
        busNames.colorMode,
        busNames.isMobile,
        busNames.isFullscreenMode,
      ],
      fileID,
      setTrigg
    );
  const theme: TThemeSettings & { breakpoints: { [x: string]: any } } =
    busContext.getDataFromBusOne(busNames.theme);
  // **

  const dataId =
    target +
    data
      .map((obj) => obj.id)
      .sort()
      .join("");

  const cntrs = React.useMemo(
    () =>
      data.reduce((resultObj, elem) => {
        return Object.assign({}, resultObj, {
          [elem.id]: reference.countries[elem.id].shortName[lang],
        });
      }, {}),
    // eslint-disable-next-line
    [dataId]
  );

  const currentTarget = reference.targets[
    target
  ] as (typeof reference.targets)[typeof target] & { marker?: string };

  const targetPrecision = currentTarget.floatPrecision;
  const marker = currentTarget.marker ? currentTarget.marker : "0";

  // -------
  // refs
  // -------
  const oldDataId = useRef("");

  if (dataId !== oldDataId.current) {
    oldDataId.current = dataId;
  }

  const colors = React.useMemo(
    () => pickCountryColor(data, colorMode),
    // eslint-disable-next-line
    [dataId, theme.palette.mode]
  );

  // eslint-disable-next-line
  const themeColors = React.useMemo(() => theme, [colorMode]);
  // eslint-disable-next-line
  const themeAlias = React.useMemo(() => theme as { [x: string]: any }, []);

  const matches = useMediaQuery(() => themeAlias.breakpoints.up("md"));
  const marginRight = matches
    ? Number(config.layout.charts.line.margin.right.md)
    : Number(config.layout.charts.line.margin.right.xs);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm" as Breakpoint));

  const DIVIDER_DECKTOP = 2;
  const DIVIDER_MOBILE = 5;
  const X_NUM_FOR_CLOSE = 12;

  const resultXArray = React.useMemo(
    () =>
      xRange === null
        ? null
        : xRange.reduce((accum: Array<number>, year: string) => {
            const yearNumber = Number(year);
            if (matches && data.length > 0) {
              if (data[0].data.length <= X_NUM_FOR_CLOSE) {
                accum.push(yearNumber);
              } else if (yearNumber % DIVIDER_DECKTOP === 0) {
                accum.push(yearNumber);
              }
            } else {
              if (yearNumber % DIVIDER_MOBILE === 0) {
                accum.push(yearNumber);
              }
            }
            return accum;
          }, [] as Array<number>),
    // eslint-disable-next-line
    [xRange, dataId]
  );

  // -------------
  // Effects
  // -------------
  React.useEffect(() => {
    busContext.placeDataToBus(busNames.targetInContext, target, fileID);
  }, [target]);

  // -------------
  // pre-rendering
  // -------------
  const lineWrapperStyleMemo = React.useMemo(
    () => _rLineWrapperStyle(themeColors),
    [themeColors]
  );
  const lineStyleMemo = React.useMemo(
    () => _rlineStyles(isDesktop),
    [isDesktop]
  );
  const LocalLineMemo = React.useMemo(
    () => {
      return (
        <LocalLine
          data={data}
          colorsOfCountries={colors}
          themeColors={themeColors}
          axisTitles={{ x: "", y: axisTitles.y }}
          marginRight={marginRight}
          matches={matches}
          minY={minMaxY.minY}
          maxY={minMaxY.maxY}
          tickValues={
            resultXArray === null || data.length === 0 ? [] : resultXArray
          }
          cntrs={cntrs}
          target={target}
          isDesktop={isDesktop}
          targetPrecision={targetPrecision}
          colorMode={colorMode}
          marker={marker}
        />
      );
    },
    // eslint-disable-next-line
    [data, cntrs, target, isDesktop, colorMode]
  ); //<

  //>
  const ZeroToggleMemo = React.useMemo(
    () => (
      <ZeroToggle
        flag={isCompressedLines}
        isDesktop={isDesktop}
        theme={theme}
        toggleIsCompressedLines={toggleIsCompressedLines}
      />
    ),
    // eslint-disable-next-line
    [
      isCompressedLines,
      isDesktop,
      toggleIsCompressedLines,
      isFullscreenMode,
      theme.palette.mode,
    ]
  ); //<

  /// ---
  const FullscreenToggleMemo = React.useMemo(
    () => (
      <FullscreenToggle
        flag={!isFullscreenMode}
        isDesktop={isDesktop}
        theme={theme}
        fnOpenInFullscreen={fnOpenInFullscreen}
      />
    ),
    // eslint-disable-next-line
    [isDesktop, toggleIsCompressedLines, isFullscreenMode, theme.palette.mode]
  ); //<

  //>
  const lineTitleMemo = React.useMemo(
    () => _rLineTitle(themeColors),
    [themeColors]
  ); //<

  //>
  const lineEmptyMemo = React.useMemo(
    () => (
      <Box
        className="RLineWrapper"
        sx={lineWrapperStyleMemo}
        style={{ visibility: "hidden" }}
      >
        <Box sx={lineStyleMemo} className="RLine">
          <div></div>
        </Box>
        <Box sx={lineTitleMemo} className="RLine_title">
          <span className="RLine_title_span">{axisTitles.y}</span>
        </Box>
      </Box>
    ),
    // eslint-disable-next-line
    []
  ); //<

  //>>
  const PostUssrMemo = React.useMemo(
    () => <PostUssr theme={theme} isFullscreenMode={isFullscreenMode} />,
    [isFullscreenMode, theme]
  );

  // -------------
  // rendering
  // -------------
  if ((!isAnimation || isMobile) && data.length > 0) {
    return (
      <Box className="RLineWrapper" sx={lineWrapperStyleMemo}>
        <Box sx={lineStyleMemo} className="RLine">
          {LocalLineMemo}
          {ZeroToggleMemo}
          {!isMobile && FullscreenToggleMemo}
          {!isMobile && PostUssrMemo}
        </Box>
        <Box sx={lineTitleMemo} className="RLine_title">
          <span className="RLine_title_span">{axisTitles.y}</span>
        </Box>
      </Box>
    );
  } else {
    return lineEmptyMemo;
  }
}

// =============
// subComponents
// =============
const LocalLine = function LocalLine(props: any) {
  const {
    data,
    colorsOfCountries,
    themeColors,
    axisTitles,
    marginRight,
    matches,
    minY,
    maxY,
    tickValues,
    cntrs,
    target,
    isDesktop,
    targetPrecision,
    marker,
  } = props;

  const mode = themeColors.palette.mode;
  const colors = themeColors.palette;
  const isDark = mode === "dark" ? true : false;

  // ---------
  // pre-rendering
  // ---------
  const nivoThemeMemo = React.useMemo(
    () => _nivoTheme(colors, isDesktop, isDark),
    // eslint-disable-next-line
    [themeColors, isDesktop, props.colorMode]
  );

  const formatMemo = React.useCallback(
    (value: number) => formatY(target)(value),
    // eslint-disable-next-line
    []
  );

  // ---------
  // rendering
  // ---------
  return (
    <ResponsiveLine
      colors={colorsOfCountries}
      theme={nivoThemeMemo}
      data={data}
      margin={{ top: 50, right: marginRight, bottom: 60, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: minY === null ? "auto" : minY,
        max: maxY === null ? "auto" : maxY,
        // https://github.com/plouc/nivo/issues/557#issuecomment-493913356
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.1f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 15,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: tickValues,
        legend: axisTitles.x,
        legendOffset: 55,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -60,
        legendPosition: "middle",
        format: formatMemo,
      }}
      enablePoints={matches ? true : false}
      pointSize={10}
      pointColor={{ theme: "pointColor" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={(point) => (
        <RStyledTooltipComponent
          tooltipObj={point}
          cntrs={cntrs}
          targetPrecision={targetPrecision}
        />
      )}
      markers={[
        {
          axis: "y",
          value: Number(marker),
          // from here: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Fills_and_Strokes#fill_and_stroke_attributes
          lineStyle: {
            stroke:
              minY > Number(marker)
                ? "transparent"
                : isDark
                ? `${colors.secondary.main}`
                : `${colors.secondary.dark}`,
            strokeWidth: 1,
            strokeDasharray: "10",
          },
        },
      ]}
    />
  );
};

// =============
// style
// =============
function _rlineStyles(isDesktop: boolean) {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "98%",
    paddingLeft: isDesktop ? LEFT_PADDING : 0,
    height: "100%",
    justifyContent: "space-between",
    "& > div > div": {
      lineHeight: 0,
    },
  };
}

function _rLineWrapperStyle(themeColors?: TThemeSettings) {
  let overlayColor;
  if (themeColors) {
    const { r, g, b } = themeColors.overlay.color;
    const k = themeColors.overlay.kefs;
    overlayColor = `rgba(${r}, ${g}, ${b}, ${k?.k0})`;
  }
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    backgroundImage: themeColors
      ? `linear-gradient(${overlayColor}, ${overlayColor})`
      : "unset",
    position: "relative",
    paddingBottom: "32px",
  };
}

function _rLineTitle(themeColors: TThemeSettings) {
  return {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    color:
      themeColors !== undefined
        ? themeColors.palette.text.secondary
        : "transparent",
    display: "flex",
    alignItems: "center",
    "& .RLine_title_span": {
      whiteSpace: "nowrap",
      writingMode: "vertical-lr",
      transform: "rotate(180deg)",
    },
  };
}

//>
function _styleZeroToggle(
  theme: TThemeSettings,
  isDesktop: boolean = true,
  elem: "zero" | "fullscreen"
) {
  // ---
  // CSSObj
  // ---
  return {
    position: "absolute",
    left: isDesktop ? (elem === "zero" ? LEFT_PADDING : "unset") : 0,
    right: isDesktop ? (elem === "fullscreen" ? "-1.4em" : "unset") : 0,
    bottom: "-1em",
    color: theme !== undefined ? theme.palette.text.secondary : "transparent",
  };
} //<

//>>
function _stylePostUssr(theme: TThemeSettings, isFullscreenMode: boolean) {
  // ---
  // CSSObj
  // ---
  return {
    position: "absolute",
    right: 0,
    bottom: "-1em",
    color: theme !== undefined ? theme.palette.text.secondary : "transparent",
    visibility: !isFullscreenMode ? "hidden" : "visible",
  };
} //<

// -------------
// nivoTheme
// -------------
function _nivoTheme(colors: Palette, isDesktop: boolean, isDark: boolean) {
  const result = {
    background: "transparent",
    pointColor: colors.background.default,
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: isDark ? colors.text.secondary : colors.primary.main,
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: colors.text.primary,
        },
      },
      ticks: {
        line: {
          stroke: isDark ? colors.text.secondary : colors.primary.main,
          strokeWidth: 1,
        },
        text: {
          fontSize: isDesktop ? 12 : 9,
          fill: colors.text.secondary,
        },
      },
    },
    grid: {
      line: {
        stroke: isDark ? colors.grey[800] : colors.grey[300],
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "yellow",
        },
      },
      text: {
        fontSize: 12,
        fill: "yellow",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "yellow",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "yellow",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      link: {
        stroke: isDark ? colors.text.secondary : colors.primary.main,
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
    crosshair: {
      line: {
        stroke: isDark ? colors.text.secondary : colors.primary.main,
        strokeWidth: 1,
      },
    },
  };

  return result;
}
