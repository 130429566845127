export function rgb2rgba(rgb: string, opacity: number) {
  const rgbArray = rgb
    .slice(rgb.indexOf("(") + 1, rgb.indexOf(")"))
    .split(",")
    .map((elm) => elm.trim());

  rgbArray.push(String(opacity));

  return `rgba(${rgbArray.join(",")})`;
}
