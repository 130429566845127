/**
 * Утилитарный класс CUtilIter
 * Предоставляет интератор
 * по свойствам
 * */

// =============
// Types
// =============
type TObjForIterate = { [x: string]: any };

// =============
// Class
// =============
export class CUtilIter {
  private _keyValueArray: Array<[string, any]>;

  // -------------
  // constructor
  // -------------
  constructor(obj_for_iterate: TObjForIterate) {
    this._keyValueArray = Object.entries(obj_for_iterate);
  }

  // -------------
  // iterator
  // -------------
  [Symbol.iterator]() {
    let count = 0;
    const limit = this._keyValueArray.length;
    const self = this;

    /* return */
    return {
      next() {
        if (count < limit) {
          const nextElm = self._keyValueArray[count];
          count++;

          return { done: false, value: nextElm };
        } else {
          return { done: true, value: undefined };
        }
      },
    };
  }
}
