// =======
// import
// =======
import * as React from "react";
import {
  noReduxSelectors,
  noReduxEStateNames,
} from "../../context/noredux/mainStore";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { RWorldBankLogo } from "./RWorldBankLogo";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import reference from "../../reference.json";
import { ELangs } from "../../enums/ELangs";

// =======
// types
// =======
type TComponentProps = {
  templateHTTP: string;
  lang: ELangs;
};

// =======
// styled
// =======
const StyledRWorldBankLink = styled("div", {
  name: "RWorldBankLink",
  slot: "Root",
})((allProps) => {
  const isDark = allProps.theme.palette.mode === "dark" ? true : false;

  return {
    marginTop: "2em",
    "& > p": {
      marginBottom: "0.5em",
    },
    "& .MuiButtonBase-root": {
      width: "300px",
      height: "60px",
      backgroundColor: isDark
        ? allProps.theme.palette.secondary.main
        : allProps.theme.palette.secondary.light,
      overflow: "hidden",
      border: `1px solid ${
        isDark
          ? allProps.theme.palette.secondary.dark
          : allProps.theme.palette.secondary.main
      }`,
    },

    "& .MuiButtonBase-root:hover": {
      backgroundColor: isDark
        ? allProps.theme.palette.secondary.dark
        : allProps.theme.palette.secondary.main,
    },

    "& .RWorldBankLogo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },

    "& .RWorldBankLogo-img": {
      width: "200px",
      height: "200px",
    },
  };
});

// =======
// main
// =======
export default function RWorldBankLink(props: TComponentProps) {
  // -------
  // bus
  // -------
  const [, setUpdate] = React.useState({});
  const fileID = "RWorldBankLink";
  const { otherCountries, yearsRangeTupple } = busContext.getDataFromBus(
    [busNames.otherCountries, busNames.yearsRangeTupple],
    fileID,
    setUpdate
  );

  // --------
  // handlers
  // --------
  const handleOnBtnClick = () => {
    // ex-ussr countries
    const postUssrActveCountries = _getActiveCountries();
    const firstPartOfAddress = _makeStringFromCntrId(postUssrActveCountries);

    // other countries
    const secondPartOfAddress = _makeStringFromCntrId(otherCountries);
    const signBetween = firstPartOfAddress && secondPartOfAddress ? "-" : "";

    const strFromCountries = `${firstPartOfAddress}${signBetween}${secondPartOfAddress}`;

    const httpAddress = _constructHTTPAddress(
      props.templateHTTP,
      yearsRangeTupple,
      strFromCountries
    );

    if (httpAddress === "") {
      return;
    } else {
      window.open(httpAddress, "_blank");
    }
  };

  // --------
  // rendering
  // --------
  return (
    <StyledRWorldBankLink>
      <p>{reference.ui.other_countries.go_to_WBsite.name[props.lang]}</p>
      <Button onClick={handleOnBtnClick}>
        <RWorldBankLogo />
      </Button>
    </StyledRWorldBankLink>
  );
}

// =======
// utils
// =======
function _getActiveCountries(): Array<Record<string, any>> | undefined {
  const cntrs = noReduxSelectors.selectAll(noReduxEStateNames.get("countries"));

  if (cntrs) {
    return cntrs.filter((country) => country.active === true);
  } else {
    return undefined;
  }
}

/**---*/
function _makeStringFromCntrId(
  array: Array<Record<"id", string>> | undefined
): string {
  if (array) {
    return array.map((countryObj) => countryObj.id.toUpperCase()).join("-");
  } else {
    return "";
  }
}

/**---*/
function _constructHTTPAddress(
  template: string,
  yearsRangeTupple: [number, number],
  countries: string
): string {
  const needles = [":startYear", ":endYear", ":countries"];
  const replaceValues = [yearsRangeTupple[0], yearsRangeTupple[1], countries];

  if (_checkTemplate(template, needles) === false) {
    console.log(
      "%cRWorldBankLink::_constructHTTPAddress: 'HTTP-template is broken.'",
      "color: red"
    );
    return "";
  }

  return _replaceNeedles(template, needles, replaceValues);
}

/**---*/
function _checkTemplate(template: string, needles: Array<string>): boolean {
  let result = false;

  for (const needle of needles) {
    result = template.includes(needle);

    if (result === false) {
      console.log(
        "%cRWorldBankLink::_checkTemplate::broken_needle: ",
        "color: #f23200",
        needle
      );
      break;
    }
  }

  return result;
}

/**---*/
function _replaceNeedles(
  template: string,
  needles: Array<string>,
  replaceValues: Array<string | number>
): string {
  return needles.reduce((res, needle, idx) => {
    res = res.replace(needle, replaceValues[idx].toString());
    return res;
  }, template);
}
