import WorldBankLogo from "../../assets/world-bank-logo.svg";

export function RWorldBankLogo() {
  return (
    <div className="RWorldBankLogo">
      <img
        src={WorldBankLogo}
        alt="WorldBankLogo"
        className="RWorldBankLogo-img"
      />
    </div>
  );
}
