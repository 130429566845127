// ========
// import
// ========
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import config from "../../config.json";
import reference from "../../reference.json";
import { ELangs } from "../../enums/ELangs";

// ========
// types
// ========
type TComponentProps = {
  isTargetsNavOnScreen: boolean;
  lang: ELangs;
};

type TStyledAdvertising = {
  isTargetsNavOnScreen: boolean;
};

// ========
// constants
// ========
const DARK_BACKGROUND = "rgba(36, 39, 40, 1)";
const DARK_TEXT_ADVERTISING = "rgba(71, 77, 79)";
const LIGHT_BACKGROUND = "rgba(242, 243, 244, 1)";
const LIGHT_TEXT_ADVERTISING = "rgba(194, 210, 216)";
// const BOTTOM_MARGIN = "32px";
const BOTTOM_MARGIN = "0px";

// ========
// styled
// ========
const StyledAdvertising = styled(Box, {
  name: "RAdvertising",
  slot: "Root",
  shouldForwardProp: (prop) =>
    prop !== "isTargetsNavOnScreen" && prop !== "lang",
})<TStyledAdvertising>((allProps) => {
  const isDark = allProps.theme.palette.mode === "dark";
  const backgroundImageColor = isDark ? DARK_BACKGROUND : LIGHT_BACKGROUND;
  const targetsTabWidth = config.layout.grid.width.desktop;
  const PSEUDO_MARGIN_RIGHT = "16px";
  const width = allProps.isTargetsNavOnScreen
    ? `calc(${targetsTabWidth["2clmn"]} - ${PSEUDO_MARGIN_RIGHT})`
    : "100%";
  const bottom = allProps.isTargetsNavOnScreen ? BOTTOM_MARGIN : "0";

  // ------
  // CSSObject
  // ------
  return Object.assign(
    {},
    {
      position: "absolute" as const,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: width,
      // height: "20%",
      backgroundImage: `linear-gradient(${backgroundImageColor}, ${backgroundImageColor})`,
      bottom: bottom,
      right: 0,
      fontSize: "2em",
      color: isDark ? DARK_TEXT_ADVERTISING : LIGHT_TEXT_ADVERTISING,
      border: `1px solid ${
        isDark ? DARK_TEXT_ADVERTISING : LIGHT_TEXT_ADVERTISING
      }`,
      "@media (1080px < height <= 10000px)": {
        height: "20%",
      },

      "@media (980px < height <= 1080px)": {
        height: "24%",
      },

      "@media (860px < height <= 980px)": {
        height: "26%",
      },

      "@media (800px < height <= 860px)": {
        height: "28%",
      },
    },
    { ...allProps.sx }
  );
});

// ========
// main
// ========
export function RAdvertising(props: TComponentProps) {
  return (
    <StyledAdvertising
      className="RAdvertising"
      isTargetsNavOnScreen={props.isTargetsNavOnScreen}
    >
      {reference.ui.advertising.name[props.lang]}
    </StyledAdvertising>
  );
}
