// ==========
// import
// ==========
import { chartWrapperWorker } from "./chartWrapperWorker";
import { prepareAxisTitlesWorker } from "../func/prepare/prepareAxisTitles";
import { getActiveCountries } from "../func/prepare/getActiveCountries";
import { serializedDataIds } from "../func/prepare/serelizedDataIds";
import { getLineMinMaxWorker } from "../func/prepare/getLineMinMax";
import { prepareXArrayWorker } from "../func/prepare/prepareXArray";

// ==========
// main
// ==========
export function initWorker() {
  const code = chartWrapperWorker.toString();
  const blob = new Blob([
    `(${code})({
        prepareAxisTitles: ${prepareAxisTitlesWorker}, 
        getActiveCountries: ${getActiveCountries},
        serializedDataIds: ${serializedDataIds},
        getLineMinMax: ${getLineMinMaxWorker},
        prepareXArray: ${prepareXArrayWorker},
      })`,
  ]);
  return new Worker(URL.createObjectURL(blob));
}
