// ===========
// import
// ===========
import { styled } from "@mui/material/styles";

// ===========
// types
// ===========
type TComponentProps = {
  gridTemplateRows: string;
  padding: string;
  rowGap: string;
  color: string;
  isTablet: boolean;
};

// ===========
// main
// ===========
export const RStyledGridContainer = styled("div")<TComponentProps>(
  (allProps) => {
    return {
      display: "grid",
      gridTemplateRows: allProps.gridTemplateRows,
      padding: allProps.padding,
      rowGap: !allProps.isTablet ? allProps.rowGap : "unset",
      color:
        allProps.theme.palette.mode === "light"
          ? allProps.theme.palette.text.primary
          : allProps.theme.palette.text.secondary,
      height: "100%",
      // --transition_test--
      // transition: "all 4s",
    };
  }
);
