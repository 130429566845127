// =========
// import
// =========
import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { type TEWhatVal } from "../../../../enums/EWhat";
import { animataion_blink } from "../../../../func/styled/animation_blink";
import { ELangs } from "../../../../enums/ELangs";
import reference from "../../../../reference.json";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";

// =========
// types
// =========
type TComponentProps = {
  xArray: Array<string> | null;
  xRange: Array<string> | null;
  handleChangedXRange: (range: [number, number]) => void;
  rangeTupple: [] | [number, number];
  className?: string;
  sx?: Record<any, any>;
  target: TEWhatVal;
  numCntrs: number;
  orientation?: "vertical" | "horizontal";
  fnSetIsFixedXRangeMin: () => void;
  fnSetIsFixedXRangeMax: () => void;
  setFixedTupple: (tupple: [number, number]) => void;
  area: Array<number | null>;
  lang: ELangs;
  toShowBlock?: boolean;
};
type TSliderProps = {
  value: [number, number];
  onChange: (
    event: Event,
    newValue: number | Array<number>,
    activeThumb: number
  ) => void;
  valueLabelDisplay: "auto" | "on" | "off" | undefined;
  step: number | null;
  min: number;
  max: number;
  marks: Array<{ value: number; label: string }> | undefined;
  startFixedPoint: number | undefined;
  endFixedPoint: number | undefined;
  orientation: "vertical" | "horizontal";
  isAnimationBlink: boolean;
  lang: ELangs;
  toShowBlock: boolean;
  // ref: React.MutableRefObject<HTMLSpanElement | null>;
};

// =========
// styled
// =========
let fixFlag = false;
const DELAY = 2000;

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) =>
    prop !== "startFixedPoint" &&
    prop !== "endFixedPoint" &&
    prop !== "isAnimationBlink" &&
    prop !== "lang" &&
    prop !== "toShowBlock",
})<TSliderProps>((allProps) => {
  let animation;
  const isDarkMode = allProps.theme.palette.mode === "dark" ? true : false;

  if (allProps.isAnimationBlink) {
    fixFlag = true;
    window.setTimeout(() => (fixFlag = false), DELAY);
  }

  if (fixFlag) {
    animation = {
      ...animataion_blink(
        1000,
        500,
        8,
        "transparent",
        `${
          isDarkMode
            ? `${allProps.theme.palette.secondary.main}`
            : allProps.theme.palette.secondary.dark
        }`
      ),
    };
  } else {
    animation = {};
  }

  // -------
  // return
  // -------
  return {
    color:
      allProps.theme.palette.mode === "dark"
        ? allProps.theme.palette.primary.dark
        : allProps.theme.palette.primary.light,
    "& .MuiSlider-valueLabel": {
      backgroundColor:
        allProps.theme.palette.mode === "dark"
          ? allProps.theme.palette.primary.dark
          : allProps.theme.palette.primary.light,
      color:
        allProps.theme.palette.mode === "dark"
          ? allProps.theme.palette.text.primary
          : allProps.theme.palette.primary.contrastText,
      visibility: allProps.toShowBlock ? "visible" : "collapse",

      ...animation,
      // **
    },
    "& .MuiSlider-valueLabel::after": {
      content: `"${reference.ui.fixing_year.name[allProps.lang as ELangs]}"`,
      position: "absolute",
      top: "0px",
      fontSize: "1em",
      backgroundColor: allProps.theme.palette.primary.dark,
      color: allProps.theme.palette.common.white,
      padding: "0.3em 2em",
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.5s",
      cursor: "none",
    },
    "& span:nth-last-of-type(1) .MuiSlider-valueLabel:after": {
      right: allProps.orientation === "vertical" ? "110%" : "110%",
      borderRight: `4px solid ${allProps.theme.palette.secondary.main}`,
    },
    "& span:nth-last-of-type(2) .MuiSlider-valueLabel:after": {
      right: allProps.orientation === "vertical" ? "110%" : "none",
      left: allProps.orientation === "vertical" ? "none" : "110%",
      borderRight:
        allProps.orientation === "vertical"
          ? `4px solid ${allProps.theme.palette.secondary.main}`
          : "none",
      borderLeft:
        allProps.orientation === "vertical"
          ? "none"
          : `4px solid ${allProps.theme.palette.secondary.main}`,
    },
    "& .MuiSlider-valueLabel:hover::after": {
      opacity: 1,
      visibility: "visible",
    },
    [`& .MuiSlider-thumb input[value='${allProps.startFixedPoint}'] + span`]: {
      backgroundColor: allProps.theme.palette.secondary.main,
      color: allProps.theme.palette.secondary.contrastText,
    },
    [`& .MuiSlider-thumb input[value='${allProps.endFixedPoint}'] + span`]: {
      backgroundColor: allProps.theme.palette.secondary.main,
      color: allProps.theme.palette.secondary.contrastText,
    },
    "& .MuiSlider-mark": {
      width: allProps.orientation === "horizontal" ? "3px" : "2px",
      height: allProps.orientation === "horizontal" ? "8px" : "4px",
      backgroundColor:
        allProps.theme.palette.mode === "dark"
          ? allProps.theme.palette.background.default
          : allProps.theme.palette.background.default,
    },
  };
});

// =========
// main
// =========
export function RRangeSlider(props: TComponentProps) {
  const {
    xArray,
    handleChangedXRange,
    rangeTupple,
    className,
    sx: outerSX,
    target,
    xRange,
    numCntrs,
    orientation = "horizontal",
    lang,
    toShowBlock = true,
  } = props;

  const NAME = "RRangeSlider";
  const fileID = "RRangeSlider";

  // ---------
  // useState
  // ---------
  const [rangeValues, setRangeValues] = React.useState<[number, number]>(
    rangeTupple as [number, number]
  );
  const [startFixedPoint, setStartFixedPoint] = React.useState<number>();
  const [endFixedPoint, setEndFixedPoint] = React.useState<number>();

  // ---------
  // useRef
  // ---------
  const localStore = React.useRef({
    range: rangeValues as [number, number],
    isTransferDataBlocked: false as boolean,
    greenRangeValues: [0, 0],
    isDataChangingStoped: true,
    oldTarget: "" as TEWhatVal,
    oldXArrayLength: 0,
    oldRangeValues: [0, 0],
    currentValue: [0, 0],
    isDidFirstChangedSlider: false,
    isAnimationBlink: false,
    sliderWidth: 0,
    sliderLeft: 0,
    sliderMin: xArray ? xArray[0] : 0,
    sliderMax: xArray ? xArray[xArray?.length - 1] : 0,
    isInit: true,
  });

  const sliderRef = React.useRef<HTMLSpanElement>(null);

  // ---------
  // utils
  // ---------
  const transferRangeData = (newValue: [number, number]) => {
    if (
      localStore.current.greenRangeValues[0] !== newValue[0] ||
      localStore.current.greenRangeValues[1] !== newValue[1]
    ) {
      handleChangedXRange(newValue as [number, number]);
      localStore.current.greenRangeValues = newValue;
    }
  };

  // ---------
  // handlers
  // ---------
  const handleChange = (
    // @ts-ignore
    event: Event | null,
    newValue: number | Array<number>
    // activeThumb: number
  ): void => {
    let delay;

    if (numCntrs < 3) {
      delay = 700;
    } else if (numCntrs < 12) {
      delay = 1000;
    } else {
      delay = 1500;
    }

    if (!Array.isArray(newValue)) {
      return;
    }

    const xStart = Number(newValue[0]);
    const xEnd = Number(newValue[1]);

    const correctValue = [xStart, xEnd];

    setRangeValues(correctValue as [number, number]);
    localStore.current.currentValue = correctValue;
    if (!localStore.current.isDidFirstChangedSlider) {
      localStore.current.isAnimationBlink = true;
      localStore.current.isDidFirstChangedSlider = true;
    } else {
      localStore.current.isAnimationBlink = false;
    }

    if (!localStore.current.isTransferDataBlocked) {
      localStore.current.isTransferDataBlocked = true;
      window.setTimeout(() => {
        transferRangeData(localStore.current.currentValue as [number, number]);
        localStore.current.isTransferDataBlocked = false;
      }, delay);
    }
  };

  const handleOnClickXThumb = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    //@ts-ignore
    const [startPoint, endPoint] = rangeValues;

    // @ts-ignore: prop 'tagName' is valid
    const { tagName, classList, innerHTML } = event.target;

    const isToFix =
      tagName === "SPAN" &&
      (classList.contains("MuiSlider-valueLabelOpen") ||
        classList.contains("MuiSlider-valueLabelLabel"));

    if (isToFix) {
      if (innerHTML === startPoint || innerHTML.includes(startPoint)) {
        if (
          startFixedPoint === innerHTML ||
          innerHTML.includes(startFixedPoint)
        ) {
          setStartFixedPoint(undefined);
          props.setFixedTupple([0, endFixedPoint ? endFixedPoint : 0]);
        } else if (innerHTML.includes(startPoint)) {
          setStartFixedPoint(Number(startPoint));
          props.setFixedTupple([
            Number(startPoint),
            endFixedPoint ? endFixedPoint : 0,
          ]);
        } else {
          setStartFixedPoint(innerHTML);
        }
        props.fnSetIsFixedXRangeMin();
      } else {
        if (endFixedPoint === innerHTML || innerHTML.includes(endFixedPoint)) {
          setEndFixedPoint(undefined);
          props.setFixedTupple([startFixedPoint ? startFixedPoint : 0, 0]);
        } else if (innerHTML.includes(endPoint)) {
          setEndFixedPoint(Number(endPoint));
          props.setFixedTupple([
            startFixedPoint ? startFixedPoint : 0,
            Number(endPoint),
          ]);
        } else {
          setEndFixedPoint(innerHTML);
        }
        props.fnSetIsFixedXRangeMax();
      }
    }
  };

  // ---------
  // useEffect
  // ---------
  React.useEffect(() => {
    if (localStore.current.isInit) {
      busContext.placeDataToBus(busNames.yearsRangeTupple, rangeValues, fileID);
      localStore.current.isInit = false;
    } else if (
      (xRange !== null &&
        xArray !== null &&
        xArray.length !== localStore.current.oldXArrayLength) ||
      (xRange !== null &&
        xArray !== null &&
        target !== localStore.current.oldTarget)
    ) {
      localStore.current.range = [
        Number(xArray[0]),
        Number(xArray[xArray.length - 1]),
      ];

      // const minChangedXRange = Math.max(
      //   localStore.current.range[0],
      //   startFixedPoint ? startFixedPoint : localStore.current.range[0]
      // );
      const minChangedXRange = Math.max(
        localStore.current.range[0],
        startFixedPoint ? startFixedPoint : Number(xRange[0])
      );

      // const maxChangedXRange = Math.min(
      //   localStore.current.range[1],
      //   endFixedPoint ? endFixedPoint : localStore.current.range[1]
      // );
      const maxChangedXRange = Math.min(
        localStore.current.range[1],
        endFixedPoint ? endFixedPoint : Number(xRange[xRange.length - 1])
      );

      setRangeValues([minChangedXRange, maxChangedXRange]);
      busContext.placeDataToBus(
        busNames.yearsRangeTupple,
        [minChangedXRange, maxChangedXRange],
        fileID
      );

      localStore.current.oldTarget = target;
      localStore.current.oldXArrayLength = xArray.length;
    }
    // eslint-disable-next-line
  }, [xArray, rangeTupple, target]);

  // Slider dimensions
  React.useEffect(() => {
    if (sliderRef.current) {
      const dimObject = sliderRef.current?.getBoundingClientRect();
      if (dimObject) {
        localStore.current.sliderWidth = dimObject.width;
        localStore.current.sliderLeft = dimObject.left;
      }
    }
  });

  // ---------
  // rendering
  // ---------
  const marks = xArray?.map((elem) => ({ value: Number(elem), label: "" }));

  // ---
  // JSX
  // ---
  return (
    <Box
      sx={Object.assign({}, { width: "100%" }, outerSX)}
      className={className + "_" + NAME}
      onClick={(event) => {
        handleOnClickXThumb(event);
      }}
    >
      <StyledSlider
        value={rangeValues}
        onChange={handleChange}
        valueLabelDisplay="on"
        step={null}
        marks={marks}
        min={localStore.current.range[0]}
        max={localStore.current.range[1]}
        startFixedPoint={startFixedPoint}
        endFixedPoint={endFixedPoint}
        orientation={orientation}
        isAnimationBlink={localStore.current.isAnimationBlink}
        lang={lang}
        toShowBlock={toShowBlock}
        ref={sliderRef}
      />
    </Box>
  );
}
