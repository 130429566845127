// ============
// import
// ============
import * as bm from "../barrelModule";

// ============
// types
// ============
export type TPrepareCountry = {
  countryId: bm.TECountriesVal;
  name: string;
  isActive: boolean;
  color: string;
  region: string;
};
type TCountryForPrepare = {
  id: bm.TECountriesVal;
  active?: boolean;
};

// ============
// main
// ============
export function prepareCountries<T extends TCountryForPrepare>(
  lang: bm.ELangs,
  cntrs: Array<T>,
  mode: "light" | "dark" = "dark"
): TPrepareCountry[] {
  const result = cntrs.map((cntr) => {
    const countryId = cntr.id;
    const name = _translateCountries(countryId, lang);
    const isActive = cntr.active ? cntr.active : false;
    const color =
      cntr.active && cntr.active === true
        ? bm.reference.countries[cntr.id].colors[mode]
        : "inherit";
    const region = bm.reference.countries[cntr.id].region;

    return { countryId, name, isActive, color, region } as TPrepareCountry;
  });

  return result;
}

// ------------
// _translateCountries
// ------------
function _translateCountries(country: bm.TECountriesVal, lang: bm.ELangs) {
  return bm.reference.countries[country].name[lang];
}
