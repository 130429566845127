// =============
// import
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { SvgIconTypeMap } from "@mui/material";
import config from "../../../config.json";
import reference from "../../../reference.json";
import { iconsStore } from "../../../hooks_g/useIcons";
import { ELangs } from "../../../enums/ELangs";

// =============
// types
// =============
type TRoutesKeys = keyof typeof config.layout.sidebar.routes;
type TRoutes = {
  id: string;
  name: string;
  to: string;
  icon: string;
  block: string;
};
type TRawRoutes = {
  [K in TRoutesKeys]: TRoutes;
};
type TSortedRoutes = TRoutes[];
export type TSidebarRoute = {
  id: string;
  title: string;
  to: string;
  icon: TIcon | undefined;
  block: string;
  name: string | null;
};
export type TIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};
type TCatStore = { cat: string };

// =============
// global vars
// =============
const categoryTempStore: TCatStore = { cat: "" };

// =============
// main
// =============
export function routesStack(lang: ELangs): TSidebarRoute[] {
  const rawRoutes = config.layout.sidebar.routes;
  const sortedRoutes = _sortRoutes(rawRoutes, categoryTempStore);

  const [packagedRoutes, err] = _packageRoutes(sortedRoutes, lang) as [
    Array<TSidebarRoute>,
    string | null
  ];

  if (err !== null) {
    throw new Error(`routesStack::[ERROR]: ${err}`);
  }

  return packagedRoutes;
}

// -------------
// _sortRoutes
// -------------
function _sortRoutes(rawRoutes: TRawRoutes, catStore: TCatStore) {
  const resultRoutesArray: TSortedRoutes = [];
  const sortTarget = config.layout.sidebar.sort as Array<TRoutesKeys>;

  sortTarget.forEach((id) => {
    if (catStore.cat !== rawRoutes[id].block && id !== "00") {
      resultRoutesArray.push({
        id: "",
        name: "",
        to: "",
        icon: "",
        block: rawRoutes[id].block,
      });
      catStore.cat = rawRoutes[id].block;
    }
    resultRoutesArray.push(rawRoutes[id]);
  });

  return resultRoutesArray;
}

// -------------
// _packageRoutes
// -------------
function _packageRoutes(sortedRoutes: TSortedRoutes, lang: ELangs) {
  type TTargetKeys =
    keyof typeof reference.indicatorHeaders.startPage.indicators;
  type TCategoriesKeys = keyof typeof reference.directory.blocks;

  let err = null;

  const targets = Object.assign(
    {},
    reference.indicatorHeaders.startPage.root,
    reference.indicatorHeaders.startPage.indicators
  );

  const categories = reference.directory.blocks;

  const packagedRoutesArray = [] as Array<TSidebarRoute>;

  for (const routeObj of sortedRoutes) {
    const packagedRoute = {
      id: "",
      title: "",
      to: "",
      icon: {} as TIcon | undefined,
      block: "",
      name: "" as string | null,
    };

    const categoryName = routeObj.block as TCategoriesKeys;
    if (!categories[categoryName]) {
      err = `_packageRoutes: 'categories[${categoryName}]}' is not found`;

      return [packagedRoutesArray, err];
    }

    if (routeObj.name !== "") {
      const routeName = routeObj.name as TTargetKeys;

      if (!targets[routeName]) {
        err = `_packageRoutes: 'targets[${routeName}]' is not found`;

        return [packagedRoutesArray, err];
      }

      packagedRoute.id = routeObj.id;
      packagedRoute.title = targets[routeName].name[lang as ELangs];
      packagedRoute.to = routeObj.to;
      [packagedRoute.icon, err] = _insertIcon(routeObj.icon) as [
        TIcon,
        string | null
      ];
      packagedRoute.name = routeObj.name;
    } else {
      packagedRoute.id = categoryName;
      packagedRoute.title = categories[categoryName].name[lang as ELangs];
      packagedRoute.icon = undefined;
      packagedRoute.name = null;
    }

    packagedRoute.block = routeObj.block;

    if (err !== null) {
      return [packagedRoutesArray, err];
    }

    packagedRoutesArray.push(packagedRoute);
  }

  return [packagedRoutesArray, err];
}

// -------------
// _insertIcon
// -------------
function _insertIcon(iconName: string) {
  let err = null;
  let iconResult = undefined;

  if (iconName === "") {
    iconResult = undefined;
  } else if (!iconsStore[iconName]) {
    err = `_insertIcon: 'iconsStore[iconName] = ${iconName}' is not found`;

    return [{}, err];
  }

  iconResult = iconsStore[iconName];

  // return [iconsStore[iconName], err];
  return [iconResult, err];
}
