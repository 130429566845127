// =============
// types
// =============
export type TXArrayProps = {
  minX?: string | number | null;
  maxX?: string | number | null;
  stepX?: number | null;
};

export type TXArrayPropsWorker = {
  min?: string | number | null;
  max?: string | number | null;
  // step?: number | null;
  originArray: Array<string> | null | undefined;
};

// =============
// main
// =============
export function prepareXArray({
  minX: min,
  maxX: max,
  stepX: step,
}: TXArrayProps): null | Array<string> {
  if (min === null || max === null || step === null) {
    return null;
  }

  min = typeof min === "string" ? parseInt(min) : min;
  max = typeof max === "string" ? parseInt(max) : max;

  if (min !== undefined && max !== undefined && (isNaN(min) || isNaN(max))) {
    return null;
  }

  const resultArray = [] as Array<string>;
  return _packageToStrArray(min, max, step, resultArray);
}

// -------------
// _packageToStrArray
// -------------
function _packageToStrArray(
  min: number | undefined,
  max: number | undefined,
  step: number | undefined,
  resultArray: Array<string>
) {
  if (min !== undefined && max !== undefined && step !== undefined) {
    for (let i = min; i <= max; i += step) {
      resultArray.push(String(i));
    }
  }

  return resultArray;
}

// =============
// for worker
// =============
export function prepareXArrayWorker() {
  // -------------
  // _packageToStrArray
  // -------------
  const _packageToStrArray = (
    min: number | undefined,
    max: number | undefined,
    originArray: Array<string>
  ) => {
    if (min !== undefined && max !== undefined && originArray !== undefined) {
      return originArray.filter((x) => Number(x) >= min && Number(x) <= max);
    } else {
      return null;
    }
  };

  return ({
    min,
    max,
    originArray,
  }: TXArrayPropsWorker): null | Array<string> => {
    if (
      min === null ||
      max === null ||
      originArray === null ||
      originArray === undefined
    ) {
      console.info(
        "%cprepareXArray::prepareXArrayWorker:\nmin === null || max === null || originArray === null || originArray === undefined",
        "color: red"
      );
      return null;
    }

    min = typeof min === "string" ? parseInt(min) : min;
    max = typeof max === "string" ? parseInt(max) : max;

    if (min !== undefined && max !== undefined && (isNaN(min) || isNaN(max))) {
      return null;
    }

    // const resultArray = [] as Array<string>;
    // return _packageToStrArray(min, max, originArray, resultArray);
    return _packageToStrArray(min, max, originArray);
  };
}

// export function prepareXArrayWorker() {
//   // -------------
//   // _packageToStrArray
//   // -------------
//   const _packageToStrArray = (
//     min: number | undefined,
//     max: number | undefined,
//     step: number | undefined,
//     resultArray: Array<string>
//   ) => {
//     if (min !== undefined && max !== undefined && step !== undefined) {
//       for (let i = min; i <= max; i += step) {
//         resultArray.push(String(i));
//       }
//     }

//     return resultArray;
//   };

//   return ({ min, max, step }: TXArrayPropsWorker): null | Array<string> => {
//     if (min === null || max === null || step === null) {
//       return null;
//     }

//     min = typeof min === "string" ? parseInt(min) : min;
//     max = typeof max === "string" ? parseInt(max) : max;

//     if (min !== undefined && max !== undefined && (isNaN(min) || isNaN(max))) {
//       return null;
//     }

//     const resultArray = [] as Array<string>;
//     return _packageToStrArray(min, max, step, resultArray);
//   };
// }
