// ========
// import
// ========
// import * as React from "react";
import Box from "@mui/material/Box";
import { TEWhatVal } from "../../../../enums/EWhat";
import { ELangs } from "../../../../enums/ELangs";
import { RRangeSlider } from "../sliders/RRangeSlider";
import { RLineLegendV } from "../line/legend/RLineLegendV";
import { type TPalette } from "../../../../theming/themeSettings";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import * as React from "react";

// ========
// types
// ========
type ComponentProps = {
  xArray: Array<string> | null;
  axisTitles: { x: string; y: string } | null;
  toShowBlock: boolean;
  themeColors: TPalette;
  target: TEWhatVal;
  xRange: string[] | null;
  handleChangedXRange: (rangeX: [number, number]) => void;
  rangeTupple: [number, number];
  isDesktop: boolean;
  isTablet: boolean;
  numCntrs: number;
  lang: ELangs;
  isDark: boolean;
  marginRight: number;
  fnSetIsFixedXRangeMin: () => void;
  fnSetIsFixedXRangeMax: () => void;
  setFixedTupple: (tupple: [number, number]) => void;
  area: Array<number | null>;
};

// ========
// main
// ========
export function RLegendV(props: ComponentProps) {
  // -----
  // bus
  // -----
  const [, setBusUpdate] = React.useState({});
  const fileID = "RLegendV";
  const { /*kefHeight*/ kefWidth } = busContext.getDataFromBus(
    [/*busNames.kefHeight,*/ busNames.kefWidth, busNames.isTablet],
    fileID,
    setBusUpdate
  );
  //<

  const aT = props.axisTitles === null ? { x: "", y: "" } : props.axisTitles;

  // ----------
  // rendering
  // ----------
  return (
    <Box sx={_rLegendV(props, /*kefHeight*/ kefWidth)} className="RLegendV">
      {/* Axis Title (x) */}
      <span style={_axisTitleStyle(props)}>{aT.x.toLocaleLowerCase()}</span>

      {/* == Slider block == */}
      <Box sx={_rLegendVSliderStyle()} className="RLegendV_Slider">
        {/* Slider */}
        <RRangeSlider
          target={props.target}
          xArray={props.xArray}
          xRange={props.xRange}
          handleChangedXRange={props.handleChangedXRange}
          rangeTupple={props.rangeTupple}
          className="RLegendV_RRangeSlider"
          sx={_rRangeSlider(props.isTablet)}
          numCntrs={props.numCntrs}
          orientation="vertical"
          fnSetIsFixedXRangeMin={props.fnSetIsFixedXRangeMin}
          fnSetIsFixedXRangeMax={props.fnSetIsFixedXRangeMax}
          setFixedTupple={props.setFixedTupple}
          area={props.area}
          lang={props.lang}
          toShowBlock={props.toShowBlock}
        />
      </Box>

      {/* == Legend block == */}
      <RLineLegendV
        lang={props.lang}
        colors={props.themeColors}
        isDark={props.isDark}
        marginRight={props.marginRight}
        toShowBlock={props.toShowBlock}
      />
    </Box>
  );
}

// ============
// styles
// ============
function _rLegendVSliderStyle() {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "4em",
    width: "100%",
    flexDirection: "row-reverse",
    marginTop: "1em",
    height: "calc(100% - 3em)",
    // webkit
    '& input[type="range"]': {
      appearance: "slider-vertical",
    },
  };
}

function _rRangeSlider(isTabletMode: boolean) {
  return {
    width: "auto",
    marginTop: isTabletMode ? "0" : "1em",
    height: "100%",
  };
}

function _rLegendV(
  props: ComponentProps,
  /*kefHeight: number,*/ kefWidth: number
) {
  console.log(
    "%cRLegendV::_rLegendV::toShowBlock:",
    "color: gold",
    props.toShowBlock
  );

  // ---
  // CSSObject
  // ---
  return {
    display: "flex",
    alignItems: "flex-start",
    width: props.toShowBlock ? "100%" : "0px",
    overflowX: "visible",
    overflowY: "clip",
    position: "relative",
    transition: "width 1s",
    color: props.themeColors.text.secondary,
    fontSize: `${1 / (kefWidth * 0.85)}em`,
  };
}

function _axisTitleStyle(props: ComponentProps) {
  return {
    position: "absolute" as const,
    // left: "30%",
    left: "20%",
    top: "50%",
    transform: "translateY(-50%) rotate(180deg)",
    color: props.themeColors.text.secondary,
    writingMode: "vertical-rl" as const,
  };
}
