import Box from "@mui/material/Box";
import { RHeader } from "./page_header/RHeader";
import { ELangs } from "../../enums/ELangs";
import type { TColors } from "../../types/main-types";
import reference from "../../reference.json";
import { RHomePageWrapper } from "./home_page/RHomePageWrapper";
import * as React from "react";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";

// =============
// types
// =============
export type TPageUtils = {
  colors: TColors;
  lang: ELangs;
};
type TComponentProps = {
  what: string & keyof typeof reference.greetings;
};

// =============
// main
// =============
export function RStartPage({ what }: TComponentProps) {
  // -----------
  // bus
  // -----------
  const [, setTrigg] = React.useState({});
  const fileID = "RHomeTabs";
  const { lang, isMobile, isTablet, isDesktop } = busContext.getDataFromBus(
    [
      busNames.lang,
      busNames.isMobile,
      busNames.isTablet,
      busNames.isDesktop,
      busNames.colorMode,
    ],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  // **

  // -------------
  // rendering
  // -------------
  return (
    <Box sx={_boxStyles()} className="RStartPage">
      <RHeader
        title={what}
        lang={lang}
        theme={theme}
        isTablet={isTablet}
        isMobile={isMobile}
        isDesktop={isDesktop}
        isFullscreenMode={false}
      />
      <RHomePageWrapper
        lang={lang}
        theme={theme}
        isTablet={isTablet}
        isMobile={isMobile}
        isDesktop={isDesktop}
      />
    </Box>
  );
}

// =============
// styles
// =============
function _boxStyles() {
  return {
    display: "grid",
    // gridTemplateRows: "min-content 1fr",
    gridTemplateRows: "min-content minmax(80%, 1fr)",
    // overflowY: "auto",
    overflow: "hidden",
    fontSize: "1em",
  };
}
