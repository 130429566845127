// ==========
// import
// ==========
import { ELangs } from "../../enums/ELangs";

// ==========
// types
// ==========
type TSearchObject = {
  titles: Array<{ title: string }>;
  urls: { [name: string]: string };
};

// ==========
// main
// ==========
export function makeSearchList(
  config: Record<string, any>,
  reference: Record<string, any>
) {
  const targets = config.layout.targetsbar.targets;
  const listOfTargets = Object.values<Record<any, any>>(targets).reduce(
    (result: Array<Array<string>>, target: Record<any, any>) => {
      const line = Object.values<{ name: string; to: string }>(
        target.routes
      ).map((elem: { name: string; to: string }) => [elem.name, elem.to]);

      result = [...result, ...line];
      return result;
    },
    [] as Array<Array<string>>
  );

  const referenceTargets = reference.targets as {
    [target: string]: {
      shortName: { [ELangs.EN]: string; [ELangs.RU]: string };
    };
  };

  const resultList = listOfTargets.reduce(
    (result, touple: Array<string>) => {
      try {
        const target = referenceTargets[touple[0]];
        const targetEN = target.shortName[ELangs.EN];
        const targetRU = target.shortName[ELangs.RU];

        result[ELangs.EN].titles = [
          ...result[ELangs.EN].titles,
          { title: targetEN },
        ];
        result[ELangs.EN].urls = {
          ...result[ELangs.EN].urls,
          ...{ [targetEN.toLowerCase()]: touple[1] },
        };

        result[ELangs.RU].titles = [
          ...result[ELangs.RU].titles,
          { title: targetRU },
        ];
        result[ELangs.RU].urls = {
          ...result[ELangs.RU].urls,
          ...{ [targetRU.toLowerCase()]: touple[1] },
        };
        return result;
      } catch (error) {
        console.info("----------");
        console.info(
          "%cfunc/prepare/makeSearchList::resultList:\nERROR: ",
          "color: tomato",
          error
        );
        console.info(
          "%cfunc/prepare/makeSearchList::resultList::touple: ",
          "color: tomato",
          touple
        );
        console.info("----------");
        throw new Error();
      }
    },
    {
      [ELangs.EN]: { titles: [], urls: {} } as TSearchObject,
      [ELangs.RU]: { titles: [], urls: {} } as TSearchObject,
    }
  );

  resultList[ELangs.EN].titles.sort((a, b) => a.title.localeCompare(b.title));
  resultList[ELangs.RU].titles.sort((a, b) => a.title.localeCompare(b.title));

  return resultList;
}
