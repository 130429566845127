// ========
// import
// ========
// import * as React from "react";
import Box from "@mui/material/Box";
import { RLineLegend } from "../../../../components/content/charts/line/legend/RLineLegend";
import { TEWhatVal } from "../../../../enums/EWhat";
import { ELangs } from "../../../../enums/ELangs";
import { RRangeSlider } from "../sliders/RRangeSlider";
import { type TPalette } from "../../../../theming/themeSettings";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import * as React from "react";

// ========
// types
// ========
type TComponentProps = {
  xArray: Array<string> | null;
  axisTitles: { x: string; y: string } | null;
  toShowBlock: boolean;
  themeColors: TPalette;
  target: TEWhatVal;
  xRange: string[] | null;
  handleChangedXRange: (rangeX: [number, number]) => void;
  rangeTupple: [number, number];
  isDesktop: boolean;
  numCntrs: number;
  lang: ELangs;
  isDark: boolean;
  marginRight: number;
  isMobile: boolean;
  fnSetIsFixedXRangeMin: () => void;
  fnSetIsFixedXRangeMax: () => void;
  setFixedTupple: (tupple: [number, number]) => void;
  area: Array<number | null>;
  isReady: boolean;
  isFullscreenMode: boolean;
};

// ========
// main
// ========
export function RLegendH(props: TComponentProps) {
  // -----
  // bus
  // -----
  const [, setBusUpdate] = React.useState({});
  const fileID = "RLegendH";
  const { /*kefHeight,*/ kefWidth, isMiniDrawerOpen } =
    busContext.getDataFromBus(
      [
        /*busNames.kefHeight,*/ busNames.kefWidth,
        /*busNames.isTablet*/ busNames.isMiniDrawerOpen,
      ],
      fileID,
      setBusUpdate
    );

  const aT = props.axisTitles === null ? { x: "", y: "" } : props.axisTitles;
  const computedFontSize = `calc(1rem / ${
    kefWidth === 1 ? 1 : Math.min(kefWidth * 0.9, 1.2)
  })`;

  // ----------
  // rendering
  // ----------
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: props.toShowBlock ? "100%" : "0px",
        overflow: props.isMobile ? "visible" : "hidden",
        fontSize:
          kefWidth < 1.33
            ? computedFontSize
            : !isMiniDrawerOpen
            ? computedFontSize
            : "13px",
        visibility: props.isReady ? "visible" : "hidden",
        paddingTop: !props.isFullscreenMode ? "0" : "1em",
      }}
      className="LegendMemoComponentWrapper"
    >
      {/* == Slider block == */}
      <Box
        sx={{
          width: "100%",
          display: !props.isFullscreenMode
            ? props.toShowBlock
              ? "flex"
              : "none"
            : "none",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="RLegendH_Slider"
      >
        {/* Axis Title (x) */}
        <span
          style={{
            marginTop: "1em",
            color: props.themeColors.text.secondary,
          }}
        >
          {aT.x.toLocaleLowerCase() || String.fromCharCode(160)}
        </span>

        {/* Slider */}
        <RRangeSlider
          target={props.target}
          xArray={props.xArray}
          xRange={props.xRange}
          handleChangedXRange={props.handleChangedXRange}
          rangeTupple={props.rangeTupple}
          className="RLegendH_RRangeSlider"
          sx={{
            width: props.isDesktop ? "92%" : "82%",
            marginTop: "1em",
          }}
          numCntrs={props.numCntrs}
          fnSetIsFixedXRangeMin={props.fnSetIsFixedXRangeMin}
          fnSetIsFixedXRangeMax={props.fnSetIsFixedXRangeMax}
          setFixedTupple={props.setFixedTupple}
          area={props.area}
          lang={props.lang}
        />
      </Box>

      {/* == Legend block == */}
      <RLineLegend
        lang={props.lang}
        colors={props.themeColors}
        isDark={props.isDark}
        marginRight={props.marginRight}
        toShowBlock={props.toShowBlock}
        isMobile={props.isMobile}
        isFullscreenMode={props.isFullscreenMode}
      />
    </Box>
  );
}
