// ============
// import
// ============
import { type TMinState } from "../mainStore";
import { EStatus, type TEStatus } from "../../../enums/EStatus";

// ============
// main
// ============
export const updateAll = (
  state: TMinState,
  action: Array<{ id: string }>,
  status?: TEStatus
  // sortingAlgo?: (a: string, b: string) => number
): TMinState => {
  const entitiesCopy = Object.assign({}, state.entities);
  action.forEach((elem) => {
    entitiesCopy[elem.id] = elem;
  });

  status = status ? status : EStatus.IDLE;

  return { ids: state.ids, entities: entitiesCopy, indicatorID: "", status };
};
