import * as React from "react";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
/* app */
import { ELangs } from "../../enums/ELangs";
import reference from "../../reference.json";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";

// =============
// main
// =============
export function RTopbarSwitchLang() {
  // -------
  // bus
  // -------
  // eslint-disable-next-line
  const [_trigg, setTrigg] = React.useState({});
  const fileID = "RTopbarSwitchLang";
  const { lang } = busContext.getDataFromBus([busNames.lang], fileID, setTrigg);
  // **

  // ---------
  // handlers
  // ---------
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const val = event.target.checked;
    const lang = _boolToLang(val);
    busContext.placeDataToBus(busNames.lang, lang, fileID);
  };

  // ---------
  // rendering
  // ---------
  return (
    <Stack className="RTopbarSwitchLang" direction="row" alignItems="center">
      <Typography>{reference.ui.lang.name.RU}</Typography>
      <Switch checked={_langToBool(lang)} onChange={handleChange} />
      <Typography>{reference.ui.lang.name.EN}</Typography>
    </Stack>
  );
}

// ========
// utils
// ========
function _langToBool(lang: ELangs) {
  if (lang === ELangs.EN) {
    return true;
  }

  return false;
}

function _boolToLang(b: boolean) {
  if (b === true) {
    return ELangs.EN;
  }

  return ELangs.RU;
}
