// =====
// import
// =====
import { type TThemeSettings } from "../../theming/themeSettings";

// =====
// constants
// =====
const OVERLAY_OPACITY_DARK = 5;
const OVERLAY_OPACITY_LIGHT = 7;

// =====
// main
// =====
export default function prepareBGImageColors(theme: TThemeSettings): string {
  const isDark = theme.palette.mode === "dark";
  const backgroundOverlayOpacity =
    theme.overlay.kefs[
      `k${isDark ? OVERLAY_OPACITY_DARK : OVERLAY_OPACITY_LIGHT}`
    ];
  return `rgba(${theme.overlay.color.r}, ${theme.overlay.color.g}, ${theme.overlay.color.b}, ${backgroundOverlayOpacity})`;
} //<<
