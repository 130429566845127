// ============
// import
// ============
import { type TRootState } from "../mainStore";
import { EStatus } from "../../../enums/EStatus";

// ============
// main
// ============
export const deleteOne = <T extends TRootState, K extends keyof T>(
  state: T,
  name: K
): boolean => {
  try {
    let stateCopy = Object.assign({}, state[name]);
    if (stateCopy !== undefined && stateCopy.status === EStatus.SUCCEEDED) {
      delete state[name];
      return true;
    }
    return false;
  } catch (error) {
    console.info("%cnoredux/handlers/upsertOne: ", "color: red", error);
    throw new Error();
  }
};
