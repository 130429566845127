// ============
// import
// ============
import { type OverridableComponent } from "@mui/material/OverridableComponent";
import { type SvgIconTypeMap } from "@mui/material";
import reference from "../../../../reference.json";
import config from "../../../../config.json";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { ELangs } from "../../charts/barrelModule";

// ============
// types
// ============
type TRoutesKeys = keyof typeof reference.targets;
export type TTargetsBarRoute = {
  id: string;
  title: string;
  to: string;
  icon?: TIcon;
  name: string;
};
type TIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};
type TPackageRoutes2 = {
  sort: Array<string>;
  routes: Record<string, { id: string; name: string; to: string }>;
};

// ============
// main
// ============
export function getListItemsStack(
  target: TRoutesKeys
): Array<TTargetsBarRoute> {
  const targetsDirectory = config.layout.targetsbar.targets;
  const inxOf_ = target.indexOf("_");
  const majorTarget =
    inxOf_ === -1
      ? (target as keyof typeof targetsDirectory)
      : (target.substring(0, inxOf_) as keyof typeof targetsDirectory);

  const rawRoutes = config.layout.targetsbar.targets[majorTarget];

  const [packageRoutes, err] = _packageRoutes_2(rawRoutes);

  if (err !== null) {
    throw new Error(`useListItemsStack::[ERROR]: ${err}`);
  }

  return packageRoutes;
}

// ------------
// _packageRoutes_2
// ------------
function _packageRoutes_2(
  rawRoutes: TPackageRoutes2
): [Array<TTargetsBarRoute>, string | null] {
  let err = null;
  const lang: ELangs = busContext.getDataFromBusOne(busNames.lang);
  const targets = reference.targets;
  let packageRoutesArray = [] as Array<TTargetsBarRoute>;
  const routesArray = Object.values(rawRoutes.routes);

  const isError = routesArray.find(
    (obj) => targets[obj.name as keyof typeof targets] === undefined
  );

  if (isError !== undefined) {
    err = `_packageRoutes: targets[${isError.name}] is not found`;
    return [packageRoutesArray, err];
  }

  packageRoutesArray = routesArray.map((obj) => {
    return {
      ...obj,
      title: targets[obj.name as keyof typeof targets].shortName[lang],
      icon: undefined,
    };
  });

  const newSortedPackageRoutesArray = packageRoutesArray
    .slice()
    .sort((obj1, obj2) => obj1.title.localeCompare(obj2.title));

  return [newSortedPackageRoutesArray, err];
}
