// ===========
// import
// ===========
import * as React from "react";
import { styled, type Theme, type CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { iconsStore } from "../../hooks_g/useIcons";
import { RSidebarMuiItem } from "./RSidebarMuiItem";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import {
  noReduxSelectors,
  noReduxEStateNames as ETargetNames,
} from "../../context/noredux/mainStore";
import { useNavigate, useLocation } from "react-router-dom";
import { type TSidebarRoute } from "./hooks/routesStack";
import { routesStack } from "./hooks/routesStack";
import { type TThemeSettings } from "../../theming/themeSettings";

// ===========
// types
// ===========
type TListItemButton = {
  className: string;
  children: Array<React.ReactElement>;
  onClick: () => void;
  open: boolean;
  isFirefox: boolean;
};
type TUniversalDrawer = {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  isAnimation: boolean;
  groupRoute: string;
  isMobile: boolean;
  isTablet: boolean;
};
type TTheme = Theme & TThemeSettings;

// ===========
// const
// ===========
const DRAWER_WIDTH_OPEN = 240;
const DRAWER_WIDTH_OPEN_TABLET = 200;
const DRAWER_WIDTH_CLOSE = 65;
const DRAWER_WIDTH_CLOSE_TABLET = 56;
const OVERLAY_OPACITY = 16;
// const REGEX = /^\/[a-z]+/;

// ===========
// styles mixins
// ===========
const openedMixin = (theme: Theme, isTablet?: boolean): CSSObject => ({
  width: isTablet ? DRAWER_WIDTH_OPEN_TABLET : DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
});

const closedMixin = (theme: Theme, isTablet?: boolean): CSSObject => {
  return {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    //@ts-ignore
    [theme.breakpoints.up("sm")]: {
      width: isTablet
        ? DRAWER_WIDTH_CLOSE_TABLET
        : `calc(${theme.spacing(8)} + 1px)`,
    },
  };
};

// ===========
// styled components
// ===========
const StyledMiniDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => {
    return (
      prop !== "open" &&
      prop !== "isFirefox" &&
      prop !== "kefHeight" &&
      prop !== "isTablet"
    );
  },
})<{ isFirefox: boolean; kefHeight: number; isTablet: boolean }>((props) => {
  const { theme, open } = props;
  const themeWithType = theme as TTheme;
  const isDark = themeWithType.palette.mode === "dark";

  const backgroundOverlayOpacity =
    themeWithType.overlay.kefs[`k${OVERLAY_OPACITY}`];

  const backgroundImageColor = `rgba(${themeWithType.overlay.color.r}, ${themeWithType.overlay.color.g}, ${themeWithType.overlay.color.b}, ${backgroundOverlayOpacity})`;

  // ---------
  // CSSObject
  // ---------
  return {
    width: props.isTablet ? DRAWER_WIDTH_OPEN_TABLET : DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(themeWithType, props.isTablet),
      "& .MuiDrawer-paper": {
        ...openedMixin(themeWithType, props.isTablet),
        border: "none",
      },
    }),
    ...(!open && {
      ...closedMixin(themeWithType, props.isTablet),
      "& .MuiDrawer-paper": {
        ...closedMixin(themeWithType, props.isTablet),
        border: "none",
      },
    }),
    color: themeWithType.palette.text.icons,
    "& .MuiPaper-root": {
      backgroundColor: isDark
        ? themeWithType.palette.background.paper
        : themeWithType.palette.primary.main,
      backgroundImage: isDark
        ? `linear-gradient(${backgroundImageColor}, ${backgroundImageColor})`
        : "unset",
      color: isDark ? "inherit" : themeWithType.palette.text.icons,
      overflow: "hidden",
    },
    "& .MuiTypography-root": {
      letterSpacing: "0.1em",
      fontSize: props.isTablet ? "0.85em" : "1em",
    },
    "& .MuiList-root.MenuList_List_2": {
      marginTop: "60px",
      height: "fit-content",
      paddingBottom: "80px",
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root .MuiButtonBase-root": {
      // padding: 0,
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type": {
      position: "fixed",
      width: !open
        ? props.isTablet
          ? DRAWER_WIDTH_CLOSE_TABLET
          : DRAWER_WIDTH_CLOSE
        : props.isTablet
        ? DRAWER_WIDTH_OPEN_TABLET
        : DRAWER_WIDTH_OPEN,
      top: "72px",
      left: 0,
      transition: "width 300ms",
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root .MuiListItemText-root": {
      width: "0",
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type .MuiListItemText-root":
      {
        visibility: !open ? "hidden" : "visible",
        transition: "visibility 150ms",
        width: "0",
      },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type .MuiButtonBase-root::before":
      {
        // right: props.isFirefox ? "0" : "5px",
      },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type .RSidebarMuiItem_IconWrapper":
      {
        width: props.isTablet ? "56px" : "65px",
        // padding: props.isTablet ? "8px 16px" : "8px 20.5px",
        marginLeft: 0,
      },
  };
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "isFirefox",
})<{ isFirefox: boolean }>((props) => {
  const { theme, open } = props;
  const themeWithType = theme as TTheme;

  const backgroundOverlayOpacity =
    themeWithType.overlay.kefs[`k${OVERLAY_OPACITY}`];

  const backgroundImageColor = `rgba(${themeWithType.overlay.color.r}, ${themeWithType.overlay.color.g}, ${themeWithType.overlay.color.b}, ${backgroundOverlayOpacity})`;

  const isDark = theme.palette.mode === "dark" ? true : false;

  const bgColor = isDark
    ? themeWithType.palette.background.paper
    : themeWithType.palette.primary.main;

  const bgImage = isDark
    ? `linear-gradient(${backgroundImageColor}, ${backgroundImageColor})`
    : "unset";

  // ---------
  // CSSObject
  // ---------
  return {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(themeWithType),
      "& .MuiDrawer-paper": {
        ...openedMixin(themeWithType),
        border: "none",
      },
    }),
    ...(!open && {
      ...closedMixin(themeWithType),
      "& .MuiDrawer-paper": {
        ...closedMixin(themeWithType),
        border: "none",
      },
    }),
    color: themeWithType.palette.text.icons,
    "& .MuiPaper-root": {
      backgroundColor: bgColor,
      backgroundImage: bgImage,
      color: isDark ? "inherit" : themeWithType.palette.text.icons,
    },
    "& .MuiTypography-root": {
      letterSpacing: "0.1em",
    },
    "& .MuiList-root": {
      zIndex: 0,
    },
    "& .MuiList-root.MenuList_List_2": {
      paddingTop: "60px",
      height: "fit-content",
      paddingBottom: "80px",
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type": {
      position: "fixed",
      width: !open ? "65px" : "240px",
      top: "60px",
      zIndex: 10,
      left: 0,
      transition: "width 300ms",
      backgroundColor: bgColor,
      backgroundImage: bgImage,
    },
    "& .MuiList-root.MenuList_List_2 .MuiListItem-root:first-of-type .MuiListItemText-root":
      {
        visibility: !open ? "hidden" : "visible",
        transition: "visibility 150ms",
      },

    "& .MuiList-root.MenuList_List_1 .MuiListItem-root:first-of-type .MuiButtonBase-root":
      {
        left: props.isFirefox ? "unset" : "-5px",
      },
  };
});

const StyledListItemButton = styled(
  (props: TListItemButton) => (
    <ListItemButton {...props}>{props.children}</ListItemButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "isFirefox",
  }
)((props) => {
  // ---
  // CSSObject
  // ---
  return {
    height: "48px",
    justifyContent: "flex-start",
    padding: 0,
    px: 2.5,
    "& div": {
      color: "inherit",
    },
    "& .MuiListItemIcon-root": {
      // paddingLeft: PL,
      opacity: props.theme.palette.mode === "dark" ? 0.7 : 1,
    },
  };
});

// ===========
// subcomponents
// ===========
function MenuList(props: {
  isMobile: boolean;
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  isAnimation: boolean;
  groupRoute: string;
  isFirefox: boolean;
  isTablet: boolean;
  kefWidth: number;
}) {
  // ----------
  // local state
  // ----------
  const [list2Width, setList2Width] = React.useState<undefined | number>(
    undefined
  );

  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RMiniDrawer::MenuList";
  const { lang } = busContext.getDataFromBus([busNames.lang], fileID, setTrigg);
  const theme = busContext.getDataFromBusOne(busNames.theme);
  // **

  // ----------
  // refs
  // ----------
  const list2Ref = React.useRef<HTMLUListElement>(null);
  ///

  const navigate = useNavigate();
  const { Menu, Close } = iconsStore;
  const isDark = theme.palette.mode === "dark";

  // ---------
  // handlers
  // ---------
  const handleClick = React.useCallback(
    (event: React.MouseEvent, route: TSidebarRoute, routeId: string) => {
      event.preventDefault();

      const dynamicTo = noReduxSelectors.selectOneById(
        ETargetNames.get("navSlice"),
        routeId
      );

      window.setTimeout(() => {
        if (dynamicTo === undefined) {
          navigate(route.to);
        } else {
          navigate(dynamicTo);
        }

        if (props.isMobile) {
          props.handleDrawerClose();
        }
      }, 0);
    },
    // eslint-disable-next-line
    []
  );

  // ---------
  // effects
  // ---------
  React.useEffect(() => {
    const indicator = props.groupRoute !== "" ? props.groupRoute : "startPage";
    busContext.placeDataToBusReplace(
      busNames.activeIndicator,
      indicator,
      `RSidebarMuiItem${props.groupRoute}`
    );
  }, [props.groupRoute]);

  React.useEffect(() => {
    if (list2Ref.current) {
      setList2Width(
        list2Ref.current.offsetWidth - list2Ref.current.clientWidth
      );
    }
  }, [props.isTablet, props.kefWidth]);

  // ---------
  // memo
  // ---------
  const ListHeaderMemo = React.useMemo(
    () => {
      return (
        <List className="MenuList_List_1">
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            className="MenuList_ListItem"
          >
            <StyledListItemButton
              className="RMiniDrawer_Box_Menu"
              onClick={
                props.open ? props.handleDrawerClose : props.handleDrawerOpen
              }
              open={props.open as boolean}
              isFirefox={props.isFirefox}
            >
              <Box
                className="RMiniDrawer_ListItemIconWrapper"
                sx={_styledListItemIconWrapper(props.isTablet)}
              >
                <ListItemIcon
                  sx={_stylesListItemIcon(
                    props.open as boolean,
                    props.isTablet
                  )}
                >
                  {props.open ? <Close /> : <Menu />}
                </ListItemIcon>
              </Box>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </StyledListItemButton>
          </ListItem>
        </List>
      );
    },
    // eslint-disable-next-line
    [props.open, props.isTablet]
  ); //<

  ///
  const scrollbarFnMemo = React.useMemo(
    () => ({
      scrollbarColor: "transparent transparent",
      scrollbarWidth: `${props.isFirefox ? "initial" : "thin"}`,

      "&:hover": {
        scrollbarColor: `${
          isDark ? theme.palette.secondary.main : theme.palette.primary.main
        } ${
          isDark
            ? theme.palette.primary.main + "30"
            : theme.palette.primary.main + "20"
        }`,
      },
    }),
    // eslint-disable-next-line
    []
  );
  ///

  const routes = React.useMemo(() => routesStack(lang), [lang]);

  // ----------
  // basic menu
  // ----------
  const ListMemo = React.useMemo(
    () => {
      // ---
      // React.Node
      // ---
      return (
        <List
          className="MenuList_List_2"
          sx={{
            overflowY: "auto",
            direction: "rtl",
            ...scrollbarFnMemo,
          }}
          ref={list2Ref}
        >
          {routes.map((route) => {
            return (
              <RSidebarMuiItem
                key={route.id}
                open={props.open as boolean}
                route={route}
                parentHandleClick={handleClick}
                lang={lang}
                isFirefox={props.isFirefox}
                isTablet={props.isTablet}
                kefWidth={props.kefWidth}
                correction={list2Width}
              />
            );
          })}
        </List>
      );
    },
    // eslint-disable-next-line
    [props.open, lang, props.isTablet, props.kefWidth, list2Width]
  );

  // ---------
  // rendering
  // ---------
  return (
    <>
      {/* List Header */}
      {ListHeaderMemo}
      {/* List Body */}
      {ListMemo}
    </>
  );
}

// ===========
// sharedComponent
// ===========
const sharedComponent = (isMobile: boolean, comp: React.ReactElement) => {
  const cloneComp = React.cloneElement(comp, {
    isMobile,
  });
  return cloneComp;
};

// ===========
// UniversalDrawer
// ===========
function UniversalDrawer(props: TUniversalDrawer) {
  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RMiniDrawer::UniversalDrawer";
  const { kefHeight, kefWidth } = busContext.getDataFromBus(
    [busNames.kefHeight, busNames.kefWidth],
    fileID,
    setTrigg
  );
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);
  //<

  const mList = (
    <MenuList
      open={props.open}
      handleDrawerClose={props.handleDrawerClose}
      handleDrawerOpen={props.handleDrawerOpen}
      isMobile={false}
      isAnimation={props.isAnimation}
      groupRoute={props.groupRoute}
      isFirefox={isFirefox}
      isTablet={props.isTablet}
      kefWidth={kefWidth}
    />
  );

  const resultElemMemo = () => {
    if (!props.isMobile) {
      return (
        <StyledMiniDrawer
          variant="permanent"
          open={props.open}
          className="RMiniDrawer_Drawer"
          isFirefox={isFirefox}
          kefHeight={kefHeight}
          isTablet={props.isTablet}
        >
          {sharedComponent(false, mList)}
        </StyledMiniDrawer>
      );
    } else {
      return (
        <StyledDrawer
          open={props.open}
          className="RMiniDrawer_Drawer"
          isFirefox={isFirefox}
        >
          {sharedComponent(true, mList)}
        </StyledDrawer>
      );
    }
  };

  // ----------
  // rendering
  // ----------
  return resultElemMemo();
}

// ===========
// main
// ===========
export function RMiniDrawer() {
  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RMiniDrawer";
  const { isMiniDrawerOpen, isAnimation, isMobile, isTablet } =
    busContext.getDataFromBus(
      [
        busNames.isMiniDrawerOpen,
        busNames.isAnimation,
        busNames.isMobile,
        busNames.isTablet,
      ],
      fileID,
      setTrigg
    );

  // --------
  // constants
  // --------
  const DRAWER_WIDHT_CLOSE_LOCAL = !isMobile
    ? isTablet
      ? DRAWER_WIDTH_CLOSE_TABLET
      : DRAWER_WIDTH_CLOSE
    : 0;

  // --------
  // location
  // --------
  const routerPathname = useLocation().pathname;
  const groupRoute =
    routerPathname.indexOf("_") !== -1
      ? routerPathname.slice(1, routerPathname.indexOf("_"))
      : routerPathname.slice(1);

  // ---------
  // local store
  // ---------
  const localStore = React.useRef({
    isDrawerClicked: false as boolean,
    isAnimationStarted: false as boolean,
    isFallbackExit: false as boolean,
    driwerWidth: 0,
    aimOfMoving: isTablet ? DRAWER_WIDTH_OPEN_TABLET : DRAWER_WIDTH_OPEN,
  });

  // ---------
  // refs
  // ---------
  const refBox = React.useRef<typeof Box & { clientWidth: number }>(null);

  // ---------
  // hendlers
  // ---------
  const handleAnimationStart = React.useCallback(() => {
    if (localStore.current.isAnimationStarted) return;
    localStore.current.isAnimationStarted = true;

    // fallback
    window.setTimeout(() => {
      if (localStore.current.isAnimationStarted) {
        localStore.current.isDrawerClicked = true;
        localStore.current.isFallbackExit = true;
      }
    }, 0);
    // eslint-disable-next-line
  }, []);

  const handleAnimationEnd = React.useCallback(() => {
    if (
      localStore.current.isAnimationStarted &&
      localStore.current.isDrawerClicked
    ) {
      localStore.current.isAnimationStarted = false;
      localStore.current.isDrawerClicked = false;
      window.setTimeout(() => {
        busContext.placeDataToBus(busNames.isAnimation, false, fileID);
      }, 200);
    }
  }, []);

  const handleLocalDrawerOpen = React.useCallback(() => {
    if (!localStore.current.isDrawerClicked) {
      handleAnimationStart();
      localStore.current.isDrawerClicked = true;
      localStore.current.aimOfMoving = isTablet
        ? DRAWER_WIDTH_OPEN_TABLET
        : DRAWER_WIDTH_OPEN;
      busContext.placeDataToBus(busNames.isAnimation, true, fileID);
      busContext.placeDataToBus(busNames.isMiniDrawerOpen, true, fileID);
    }
  }, [handleAnimationStart]);

  const handleLocalDrawerClose = React.useCallback(() => {
    if (!localStore.current.isDrawerClicked) {
      handleAnimationStart();
      localStore.current.aimOfMoving = DRAWER_WIDHT_CLOSE_LOCAL;
      busContext.placeDataToBus(busNames.isAnimation, true, fileID);
      (function toCloseDrawer() {
        window.setTimeout(() => {
          if (
            localStore.current.isAnimationStarted ||
            localStore.current.isFallbackExit
          ) {
            localStore.current.isDrawerClicked = true;
            busContext.placeDataToBus(busNames.isMiniDrawerOpen, false, fileID);
            if (localStore.current.isFallbackExit) {
              localStore.current.isFallbackExit = false;
            }
          } else {
            toCloseDrawer();
          }
        }, 0);
      })();
    }
  }, [DRAWER_WIDHT_CLOSE_LOCAL, handleAnimationStart]);

  // ---------
  // effects
  // ---------
  React.useEffect(() => {
    const loop = () => {
      const aimOfMovingNum = isTablet
        ? Math.round(localStore.current.aimOfMoving)
        : Math.round(localStore.current.aimOfMoving);

      if (
        refBox.current &&
        refBox.current.clientWidth - aimOfMovingNum < Number.EPSILON
      ) {
        window.setTimeout(() => {
          handleAnimationEnd();
        });
      } else {
        if (localStore.current.isAnimationStarted) {
          window.setTimeout(() => loop(), 100);
        }
      }
    };
    loop();
  });

  // ---------
  // rendering
  // ---------
  return (
    <Box
      component="div"
      className="RMiniDrawer_Box"
      sx={_stylesBox()}
      ref={refBox}
    >
      <UniversalDrawer
        open={isMiniDrawerOpen}
        handleDrawerClose={handleLocalDrawerClose}
        handleDrawerOpen={handleLocalDrawerOpen}
        isAnimation={isAnimation}
        groupRoute={groupRoute}
        isMobile={isMobile}
        isTablet={isTablet}
      />
    </Box>
  );
}

// ===========
// styles
// ===========
function _stylesListItemIcon(
  open: boolean,
  // isFirefox: boolean,
  isTablet: boolean
) {
  return {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      width: isTablet ? "1em" : "1.5rem",
      height: isTablet ? "1em" : "1.5rem",
    },
  };
} //<

//>
function _stylesBox() {
  return {
    display: "flex",
  };
} //<

//>
function _styledListItemIconWrapper(isTablet: boolean) {
  return {
    display: "flex",
    padding: isTablet ? "8px 16px" : "8px 20.5px",
  };
}
