/**
 *
 * @param {Number} delay 'in milliseconds'
 * @param {Number} duration  'in milliseconds'
 * @param {Number} count 'number of repetitions'
 */
export function animataion_blink(
  delay: number = 5000,
  duration: number = 500,
  count: number = 8,
  fromColor: string = "transparent",
  toColor: string = "transparent"
  // fromTextColor: string = "#fff",
  // toTextColor: string = "#000"
): Record<string, string | number | Record<string, any>> {
  return {
    animationDelay: `${delay}ms`,
    animationDuration: `${duration}ms`,
    animationName: "changeColor",
    animationIterationCount: count,
    animationDirection: "alternate",

    "@keyframes changeColor": {
      from: {
        backgroundColor: fromColor,
        // color: fromTextColor,
      },

      to: {
        backgroundColor: toColor,
        // color: toTextColor,
      },
    },
  };
}
