// =============
// input
// =============
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
/* app */
import { iconsStore } from "../../hooks_g/useIcons";
import reference from "../../reference.json";
import config from "../../config.json";
import { ELangs } from "../../enums/ELangs";
import { styled } from "@mui/material/styles";
import { useAutocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import { type Breakpoint } from "@mui/material/styles";
import Grow from "@mui/material/Grow";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { makeSearchList } from "../../func/prepare/makeSearchList";
import { gray2gray } from "../../func/prepare/gray2gray";

// =============
// types
// =============
type TComponentProps = {
  isMobile?: boolean;
  modalRef?: React.ForwardedRef<HTMLDivElement>;
  fnModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

// =============
// constants
// =============
const SEARCH_WIDTH_MOBILE = "100%";
const BODY = document.querySelector("body");

// =============
// utils
// =============
function fnResetFocus(resetFocusRef: React.MutableRefObject<boolean>) {
  try {
    BODY?.focus();
    resetFocusRef.current = true;
  } catch (error) {
    console.info(
      "%cRTopbarSearch::resetFocusRef\nERROR: ",
      "color: red",
      error
    );
  }
}

// =============
// subcomponents
// =============
const Input = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & {
    fnIsActiveSearch: (isActive: boolean) => void;
    // fnIsActiveSearch: () => void;
  }
>((props, ref) => {
  const pureProps: Partial<typeof props> = Object.assign({}, props);
  delete pureProps.fnIsActiveSearch;

  // ---------
  // handlers
  // ---------
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
    props.onFocus?.(event);
    props.fnIsActiveSearch(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
    props.onBlur?.(event);
    props.fnIsActiveSearch(false);
  };

  // ---------
  // rendering
  // ---------
  return (
    <input {...pureProps} ref={ref} onFocus={handleFocus} onBlur={handleBlur} />
  );
});

const SearchWrapper = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & { isActiveSearch: boolean; isMobile: boolean }
>((props, ref) => {
  // ---------
  // rendering
  // ---------
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
});

// =============
// styled components
// =============
const StyledSearchWrapper = styled(SearchWrapper, {
  shouldForwardProp: (prop) => prop !== "isActiveSearch" && prop !== "isMobile",
})((styleProps) => {
  const isDark = styleProps.theme.palette.mode === "dark" ? true : false;
  return {
    display: "flex",
    posititon: "relative",
    borderTopLeftRadius: styleProps.theme.shape.borderRadius,
    borderTopRightRadius: styleProps.theme.shape.borderRadius,
    borderBottomLeftRadius: styleProps.isActiveSearch
      ? 0
      : styleProps.theme.shape.borderRadius,
    borderBottomRightRadius: styleProps.isActiveSearch
      ? 0
      : styleProps.theme.shape.borderRadius,
    backgroundColor: styleProps.isActiveSearch
      ? isDark
        ? styleProps.theme.palette.primary.dark
        : gray2gray(styleProps.theme.palette.primary.light, "light", 50)
      : styleProps.isMobile
      ? styleProps.theme.palette.background.paper
      : styleProps.theme.palette.background.paper,
    width: "auto",
    color: styleProps.isActiveSearch
      ? styleProps.theme.palette.text.primary
      : styleProps.theme.palette.text.secondary,
  };
});

const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>((styleProps) => {
  const theme = styleProps.theme;

  // --------
  // render
  // --------
  return {
    marginLeft: "1em",
    color: "inherit",
    backgroundColor: "transparent",
    border: "none",
    "&::placeholder": {
      opacity: 1,
    },
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus-visible::placeholder": {
      color: "inherit",
    },
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm" as Breakpoint)]: {
      width: "20ch",
      "&:focus": {
        width: "30ch",
      },
    },
    padding: styleProps.isMobile ? "1em 0.5em" : "initial",
    fontSize: styleProps.isMobile ? "1.2em" : "inherit",
  };
});

/// ---
const StyledSearchListbox = styled("ul")<{ isMobile: boolean }>(
  (styleProps) => {
    const isDark = styleProps.theme.palette.mode === "dark" ? true : false;
    const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);

    console.log("%cRTopbarSearch:::", "color: gold");
    console.log("%cisDark:", "color: gold", isDark);
    console.log(
      "%cstyleProps.theme.palette.primary.light",
      "color: gold",
      styleProps.theme.palette.primary.light
    );
    console.log(
      "%cstyleProps.theme.palette.primary",
      "color: gold",
      styleProps.theme.palette.primary
    );
    // ---
    // CSSObject
    // ---
    return {
      width: SEARCH_WIDTH_MOBILE,
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: "absolute",
      listStyle: "none",
      overflowX: "hidden",
      overflowY: "auto",
      maxHeight: "200px",
      backgroundColor: isDark
        ? styleProps.theme.palette.primary.light
        : gray2gray(styleProps.theme.palette.primary.light, "light", 70),
      color: isDark
        ? styleProps.theme.palette.grey[900]
        : styleProps.theme.palette.text.primary,

      "& > li": {
        padding: "0.5em 0 0.5em 1em",
        fontSize: styleProps.isMobile ? "1.2em" : "inherit",
      },
      "& li.Mui-focused": {
        backgroundColor: isDark
          ? "unset"
          : styleProps.theme.palette.secondary.light,
        backgroundImage: isDark
          ? "linear-gradient(rgba(0,0,0,0.09), rgba(0,0,0,0.09))"
          : "unset",
        color: styleProps.theme.palette.secondary.contrastText,
        cursor: "pointer",
      },
      scrollbarColor: "transparent transparent",
      scrollbarWidth: isFirefox ? "initial" : "thin",

      "&:hover": {
        scrollbarColor: `${
          isDark
            ? styleProps.theme.palette.secondary.main
            : styleProps.theme.palette.primary.main
        } ${
          isDark
            ? styleProps.theme.palette.primary.dark
            : styleProps.theme.palette.primary.main + "20"
        }`,
      },
    };
  }
);

// =============
// main
// =============
export function RTopbarSearch({
  isMobile = false,
  modalRef,
  fnModalOpen,
}: TComponentProps) {
  // ---------
  // local state
  // ---------
  const [isActiveSearch, setIsActiveSearch] = React.useState(false);
  const [isResetFocus, setResetFocus] = React.useState(false);
  const [value, setValue] = React.useState<{ title: string } | null>(null);
  const [inputValue, setInputValue] = React.useState<string | undefined>("");
  const [urlIndex, setUrlIndex] = React.useState("");
  const [isToNewRender, setIsToNewRender] = React.useState({});

  // ---------
  // local store
  // ---------
  const searchWrapperRef = React.useRef<HTMLDivElement>(null);
  const resetFocusRef = React.useRef(false);
  const isEnteredRef = React.useRef(false);
  const searchList = React.useMemo(() => makeSearchList(config, reference), []);

  // ---------
  // bus
  // ---------
  const [, setTrigg] = React.useState({});
  const fileID = "RTopbarSearch";
  const { lang } = busContext.getDataFromBus([busNames.lang], fileID, setTrigg);
  // **

  const localStore = React.useRef({
    currentListboxOption: null as string | null,
    isListboxOptionChanged: false,
    isEnterButton: false,
    isOpen: false,
    lastSearchString: "",
    isValueChanged: false,
    oldLang: lang,
    isLangChanged: false,
  });

  if (inputValue === "") {
    localStore.current.lastSearchString = "";
  }

  if (localStore.current.oldLang !== lang) {
    localStore.current.isLangChanged = true;
    localStore.current.oldLang = lang;
  }

  // ---------
  // router
  // ---------
  const navigate = useNavigate();
  const location = useLocation();

  // ---------
  // icons
  // ---------
  const SearchIcon = iconsStore.search;
  const CloseIcon = iconsStore.Close;

  // ---------
  // utils
  // ---------
  // const fnIsActiveSearch = (isActive: boolean) => setIsActiveSearch(isActive);
  const fnIsActiveSearch = (isActive: boolean) => setIsActiveSearch(isActive);
  // const fnIsActiveSearch = () => setIsActiveSearch(true);

  const navigateTo = (urlAddress: string) => navigate(urlAddress);

  const goToUrl = React.useCallback((forceReRender: boolean = false) => {
    setValue({
      title: localStore.current.currentListboxOption
        ? localStore.current.currentListboxOption
        : "",
    });
    window.setTimeout(() => {
      setUrlIndex(
        localStore.current.currentListboxOption
          ? localStore.current.currentListboxOption?.toLocaleLowerCase()
          : location.pathname
      );
      if (forceReRender) {
        setIsToNewRender({});
      }
      fnResetFocus(resetFocusRef);
      isEnteredRef.current = true;
    }, 0);
    // eslint-disable-next-line
  }, []);

  // ---------
  // useAutocomplete
  // ---------
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "search-use-autocomplete",
    options: searchList[lang as ELangs]["titles"],
    getOptionLabel: (option) => option.title,
    value,
    inputValue,
    open: isActiveSearch,
    onChange: (_event, useValue) => {
      if (useValue && value && useValue?.title === value.title) {
        goToUrl(true);
      }
      localStore.current.isValueChanged = true;
    },
    onInputChange: (_event, rawInputValue, muiName) => {
      let newInputValue: string;

      newInputValue = rawInputValue;

      if (!localStore.current.isOpen) {
        localStore.current.lastSearchString = !localStore.current.isLangChanged
          ? inputValue
            ? inputValue
            : ""
          : "";
        if (muiName === "input") {
          if (newInputValue === "" && Boolean(value)) {
            setValue(null);
          }
          !localStore.current.isLangChanged
            ? setInputValue(newInputValue)
            : setInputValue("");
        }
      } else if (localStore.current.lastSearchString === "") {
        if (muiName === "input") {
          setInputValue(newInputValue);
        }
      } else {
      }
      localStore.current.isOpen = false;
      if (localStore.current.isLangChanged) {
        setInputValue("");
        localStore.current.isLangChanged = false;
      }

      let correctElemTitle: { title: string } | undefined;
      const matchedTitles = (groupedOptions as Array<{ title: string }>).filter(
        (elem: any) =>
          elem.title.toLowerCase().includes(String(newInputValue).toLowerCase())
      );

      if (matchedTitles.length > 0) {
        correctElemTitle = matchedTitles.find(
          (obj) => newInputValue.toString().trim() === obj.title.trim()
        );
      }

      if (correctElemTitle?.title) {
        localStore.current.currentListboxOption = correctElemTitle.title;
        localStore.current.isListboxOptionChanged = true;
        if (localStore.current.isEnterButton) {
          goToUrl();
          localStore.current.isEnterButton = false;
        }
      } else {
        localStore.current.isListboxOptionChanged = false;
      }
    },
    openOnFocus: true,
    onOpen: () => {
      localStore.current.isOpen = true;
      if (localStore.current.lastSearchString) {
        setInputValue(localStore.current.lastSearchString);
      }
    },
    onClose: () => {
      localStore.current.isOpen = false;
    },
    selectOnFocus: false,
    // @ts-ignore
    isOptionEqualToValue: (v1, v2) => {
      return true;
    },
  });

  // ---------
  // handlers
  // ---------
  const handleKeyboardEvent = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      localStore.current.isEnterButton = true;
      if (localStore.current.isListboxOptionChanged) {
        goToUrl();
      }
    }

    if (event.key === "Escape") {
      fnResetFocus(resetFocusRef);
    }
  };

  const handleSearchListClickEvent = (
    _event: React.MouseEvent<HTMLUListElement> & {
      target: HTMLLIElement;
    }
  ) => {
    if (localStore.current.isListboxOptionChanged) {
      goToUrl();
    }
  };

  const handleOnBlur = (_event: React.FocusEvent) => {
    setInputValue(() => {
      if (value) {
        return value.title;
      }

      if (Boolean(inputValue)) {
        return localStore.current.lastSearchString;
      }

      // return undefined;
      return "";
      // return value ? value.title : localStore.current.lastSearchString;
    });
    if (!inputValue) {
      setValue(null);
    }
  };

  const handleOnFocus = (_event: React.FocusEvent) => {
    setInputValue(localStore.current.lastSearchString);
  };

  // ---------
  // effects
  // ---------
  React.useEffect(() => {
    if (resetFocusRef.current) {
      document.querySelector("body")?.focus();
      resetFocusRef.current = false;
      setResetFocus(false);
    }
  }, [isResetFocus]);

  React.useEffect(() => {
    if (urlIndex !== "" && urlIndex !== undefined && isEnteredRef.current) {
      const target = searchList[lang as ELangs].urls[urlIndex.toLowerCase()];
      navigateTo(target);
      isEnteredRef.current = false;
      isMobile && fnModalOpen && fnModalOpen(false);
    }
    // eslint-disable-next-line
  }, [urlIndex, lang, isToNewRender]);

  // --------
  // pre-rendering
  // --------
  const inputPropsPre = getInputProps();
  const val = inputPropsPre.value;
  let inputProps;

  if (!val && inputValue) {
    inputProps = Object.assign({}, inputPropsPre, { value: inputValue });
  } else {
    inputProps = inputPropsPre;
  }

  // --------
  // rendering
  // --------
  return (
    <Box sx={{ position: "relative" }} className="RTopbarSearch">
      <StyledSearchWrapper
        className="RTopbarSearch_StyledSearchWrapper"
        {...getRootProps()}
        isActiveSearch={isActiveSearch}
        ref={isMobile ? modalRef : searchWrapperRef}
        isMobile={isMobile}
        tabIndex={-1}
      >
        <StyledInput
          {...inputProps}
          className="StyledInput"
          placeholder={`${reference.utils.search.name[lang as ELangs]}`}
          fnIsActiveSearch={fnIsActiveSearch}
          onKeyDown={handleKeyboardEvent}
          isMobile={isMobile}
          onFocus={(e) => {
            handleOnFocus(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
        />
        <IconButton
          type="button"
          sx={{
            p: 1,
            color: "inherit",
          }}
          onClick={(event) => {
            event.preventDefault();
            if (isMobile) {
              setValue(null);
            } else {
              resetFocusRef.current = true;
              setIsActiveSearch(false);
              setResetFocus(true);
              isEnteredRef.current = true;
              setIsToNewRender({});
            }
          }}
        >
          {isMobile ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
      </StyledSearchWrapper>
      {groupedOptions.length > 0 ? (
        <Grow in={isActiveSearch}>
          <StyledSearchListbox
            {...getListboxProps()}
            className="StyledSearchListbox"
            onClick={handleSearchListClickEvent}
            isMobile={isMobile}
          >
            {(groupedOptions as (typeof searchList)[ELangs]["titles"]).map(
              (option, index) => {
                return (
                  <li {...getOptionProps({ option, index })}>{option.title}</li>
                );
              }
            )}
          </StyledSearchListbox>
        </Grow>
      ) : null}
    </Box>
  );
  // return returnMemo;
}

// =============
// styles
// =============
