// =============
// input
// =============
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { type Palette } from "@mui/material/styles";
import { iconsStore } from "../../hooks_g/useIcons";
import { RTopbarSwitchLang } from "./RTopbarSwitchLang";
import { RTopbarTargetSidebar } from "./RTopbarTargetSidebar";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { ELangs } from "../../enums/ELangs";

// =============
// types
// =============
type TComponentProps = {
  colors: Palette;
  lang: ELangs;
};

// =============
// main
// =============
export function RTopbarIcons({ colors, lang }: TComponentProps) {
  // -------
  // bus
  // -------
  const isTablet = busContext.getDataFromBusOne(busNames.isTablet);
  const fileID = "PTopbarIcons";
  // **

  // -------
  // handlers
  // -------
  const handleToggleColorMode = () => {
    const prev = busContext.getDataFromBusOne(busNames.colorMode);
    busContext.placeDataToBus(
      busNames.colorMode,
      prev === "light" ? "dark" : "light",
      fileID
    );
  };

  // -------
  // vars
  // -------
  const LightModeIcon = iconsStore.lightMode;
  const DarkModeIcon = iconsStore.darkMode;

  // --------
  // rendering
  // --------
  return (
    <Stack className="RTopbarIcons" direction="row" spacing={2}>
      {isTablet && <RTopbarTargetSidebar lang={lang} />}
      <IconButton onClick={handleToggleColorMode} sx={{ color: "inherit" }}>
        {colors.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
      <RTopbarSwitchLang />
    </Stack>
  );
}
