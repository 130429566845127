// ========
// import
// ========
import { type Theme, createTheme } from "@mui/material/styles";
import { themeSettings } from "../../theming/themeSettings";

// ========
// main
// ========
export function prepareTheme(muiTheme: Theme, colorMode: "dark" | "light") {
  const themeOptions = themeSettings(colorMode);
  const breakpoints = {
    values: {
      xs: 0, // 0:4
      sm: 600, // 1:3
      md: 900, // 2:2
      lg: 1280, // 3:1
      xl: 1440, // 4:0
    },
  };
  const newTheme = Object.assign({}, muiTheme, themeOptions, { breakpoints });
  const createdTheme = createTheme(newTheme);

  console.log("%cprepareTheme: ...", "color: yellowgreen");
  console.log("%cmuiTheme:", "color: yellowgreen", muiTheme);
  console.log("%cthemeOptions:", "color: yellowgreen", themeOptions);

  return createdTheme;
}
