import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { RMainContent } from "./components/content/RMainContent";
import { EWhat } from "./enums/EWhat";
import { RStartPage } from "./components/content/RStartPage";
import reference from "./reference.json";
// import TestComponent from "./components/testDir/testComponent";
// import DevelopViews from "./theming/developViews";

// ===========
// prepare 'paths objects' to array
// ===========
const pathsArray = Object.values(reference.targets).map((obj) => ({
  path: `/${obj.url}`,
  element: <RMainContent what={EWhat[`${obj.url.toUpperCase()}`]} />,
}));

// ===========
// main
// ===========
export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <RStartPage what={"startPage"} />,
        // element: <TestComponent />,
      },
      // {
      //   path: "/theme",
      //   element: <DevelopViews />,
      // },
    ].concat(pathsArray),
  },
]);
