// =============
// import
// =============
import * as bm from "./barrelModule";
import React from "react";
import { Box } from "@mui/material";
import { type TEWhatVal } from "./barrelModule";
import { webKitScrollbarFn2 } from "../../../../styled/WebKitScrollbar";
import { rgb2rgba } from "../../../../func/prepare/rgb2rgba";
import reference from "../../../../reference.json";
import { ELangs } from "../../../../enums/ELangs";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { type Theme } from "@mui/material/styles";
import { type TThemeSettings } from "../../../../theming/themeSettings";

// =============
// types
// =============
type TComponentProps = {
  data: Array<bm.TRline>;
  axisTitles: { x: string; y: string };
  lang: bm.ELangs;
  xArray: Array<string> | null;
  target: TEWhatVal;
  xRange: Array<string> | null;
  handleChangedXRange: (range: [number, number]) => void;
  rangeTupple: [] | [number, number];
  baseHeight: string;
};
type TRows = Array<{ [key: string]: string | number } & { x: string }> | null;

// =============
// constants
// =============
const WIDTH_KEF = 10;
const PADDING_KEF = 2;
const ROW_HEAD_KEF = 6;

// =============
// main
// =============
export function RBaseTable({
  data,
  axisTitles,
  lang,
  xArray,
  target,
  baseHeight,
}: TComponentProps) {
  // -------------
  // bus
  // -------------
  const [, setTrigg] = React.useState({});
  const fileID = "RBaseTable";
  const { isAnimation, isMobile } = busContext.getDataFromBus(
    [busNames.isAnimation, busNames.isMobile, busNames.colorMode],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);

  // -------------
  // local state
  // -------------
  const [order, setOrder] = bm.useState<bm.TOrder>("asc");
  const [orderBy, setOrderBy] = bm.useState<string>("");
  const isDark = theme.palette.mode === "dark";

  // -------------
  // id of the "data"
  // -------------
  const dataID = bm.useMemo(
    () =>
      data
        .map((elm) => elm.id)
        .sort()
        .join(""),
    [data]
  );

  // -------------
  // handlers
  // -------------
  const handleRequestSort = bm.useCallback(
    (
      _event: React.MouseEvent<unknown>,
      property: string | bm.TECountriesVal
    ) => {
      const newOrderBy = orderBy === "" ? property : orderBy;
      const isAsc = newOrderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  // -------------
  // local store
  // -------------
  const localStore = bm.useRef({
    rows: null as TRows,
    oldDataID: "",
    oldTarget: "",
  });
  const ls = localStore.current;

  // -------------
  // old data check
  // -------------
  ls.rows = _rowMaker(xArray, data, lang);
  ls.oldDataID = dataID;
  ls.oldTarget = target;
  // }

  // -------------
  // names array
  // -------------
  const names = data.map((elm) => ({ id: elm.id }));

  // ------------
  // Header Title to UpperCase
  // ------------
  axisTitles = Object.assign({}, axisTitles, {
    x: axisTitles.x.charAt(0).toUpperCase() + axisTitles.x.slice(1),
  });

  // -------------
  // effects
  // -------------
  React.useEffect(() => {
    busContext.placeDataToBus(busNames.targetInContext, target, fileID);
  }, [target]);

  // -------------
  // rendering
  // -------------
  if ((!isAnimation || isMobile) && ls.rows !== null && ls.rows.length > 0) {
    return (
      <Box sx={_stylerRBaseTableWrapper(theme)} className="RBaseTable_Wrapper">
        <bm.TableContainer
          className="TableContainer"
          sx={Object.assign(
            {},
            {
              height: "100%",
            },
            isMobile ? {} : { ...webKitScrollbarFn2(theme, isDark, isFirefox) }
          )}
        >
          <bm.Table stickyHeader aria-label="simple table">
            <bm.REnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              xArrayTitle={{ id: "x" }}
              titleIDs={names}
              axisTitles={axisTitles}
              lang={lang}
              // cellWidth={COLUMN_WIDTH}
              // rowHeadWidth={ROW_HEAD_WIDTH}
            />
            <bm.TableBody>
              {ls.rows
                .slice()
                .sort(bm.getComparator(order, orderBy))
                .map((row) => {
                  return (
                    <bm.TableRow
                      hover
                      key={row.x}
                      className="TableBody_TableRow"
                    >
                      <bm.TableCell
                        component="th"
                        align="left"
                        scope="row"
                        sx={{
                          width: 0,
                          borderBottom: `1px solid ${
                            isDark
                              ? theme.palette.grey[800]
                              : theme.palette.grey[300]
                          }`,
                        }}
                        className="TableBody_TableCell"
                      >
                        {row.x}
                      </bm.TableCell>
                      {names.map((obj) => (
                        <bm.TableCell
                          key={obj.id}
                          // sx={{ width: ROW_HEAD_WIDTH }}
                          sx={{
                            borderBottom: `1px solid ${
                              isDark
                                ? theme.palette.grey[800]
                                : theme.palette.grey[300]
                            }`,
                          }}
                          align="right"
                        >
                          {row[obj.id]}
                        </bm.TableCell>
                      ))}
                    </bm.TableRow>
                  );
                })}
            </bm.TableBody>
          </bm.Table>
        </bm.TableContainer>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          /*marginBottom: "1em"*/
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-between",
        }}
        className="RBaseTable_Wrapper"
        style={{ visibility: "hidden" }}
      >
        <bm.TableContainer
          className="TableContainer"
          sx={{
            height: baseHeight,
            maxWidth: `${
              names.length * WIDTH_KEF + ROW_HEAD_KEF + PADDING_KEF
            }rem`,
            ...webKitScrollbarFn2(theme, isDark, isFirefox),
          }}
        >
          <div></div>
        </bm.TableContainer>
      </Box>
    );
  }
}

// =============
// utils
// =============
function _rowMaker(
  xArray: Array<string> | null,
  arr: Array<bm.TRline>,
  lang: ELangs
) {
  if (xArray === null) {
    return null;
  }

  const xArrayReverse = [...xArray].reverse();

  const resultArray = [];

  for (const xStr of xArrayReverse) {
    const res = {} as { [key: string]: string | number } & { x: string };
    res.x = xStr;

    const result = arr.reduce((res, obj) => {
      const detected = obj.data.find((elm: { x: string }) => elm.x === xStr);
      if (detected === undefined) {
        return res;
      } else {
        return {
          ...res,
          [obj.id]:
            detected.y === null ? reference.ui.nodata.name[lang] : detected.y,
        };
      }
    }, res);

    if (Object.keys(result).length > 1) {
      resultArray.push(result);
    }
  }
  return resultArray;
}

// =============
// styles
// =============
function _stylerRBaseTableWrapper(theme: TThemeSettings & Theme) {
  const OVERLAY_HEADER_OPACITY = 9;
  const OVERLAY_YEARS_OPACITY = 7;
  const isDark = theme.palette.mode === "dark";

  const { color, kefs } = theme.overlay;
  const overlayHColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${
    kefs[`k${OVERLAY_HEADER_OPACITY}`]
  })`;
  const overlayYColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${
    kefs[`k${OVERLAY_YEARS_OPACITY}`]
  })`;
  const overlayHLColor = rgb2rgba(theme.palette.primary.light, 0.5);

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-between",
    height: "100%",
    // paddingBottom: "32px",

    "& .MuiTableCell-head": {
      backgroundImage: isDark
        ? `linear-gradient(${overlayHColor}, ${overlayHColor})`
        : `linear-gradient(${overlayHLColor}, ${overlayHLColor})`,
    },
    "& .MuiTableBody-root th": {
      backgroundImage: `linear-gradient(${overlayYColor}, ${overlayYColor})`,
    },
  };
}
