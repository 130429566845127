// =====
// types
// =====
type TArgs = {
  cntrs?: string;
  tab?: string;
  tabH?: string;
  rangeStart?: string;
  rangeEnd?: string;
  year?: string;
  clmode?: string;
};

// =====
// main
// =====
export default function changeUrl(args: TArgs) {
  // from here: https://developer.mozilla.org/en-US/docs/Web/API/URL#usage_notes
  const locationObj = new URL(window.location.href);
  const searchObj: TArgs = {};
  for (const [key, val] of locationObj.searchParams.entries()) {
    const key_t = key as keyof TArgs;
    searchObj[key_t] = val.toString();
  }

  const mergedArgs = Object.assign({}, searchObj, args);

  for (const [key, val] of Object.entries(mergedArgs)) {
    locationObj.searchParams.set(key, val.toString());
  }

  window.history.replaceState({}, "", locationObj.toString());
}
