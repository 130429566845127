// eslint-disable-next-line import/no-anonymous-default-export
export function chartWrapperWorker(props: Record<string, Function>) {
  const {
    prepareAxisTitles,
    getActiveCountries,
    serializedDataIds,
    getLineMinMax,
    prepareXArray,
  } = props;

  // ----------
  // get message
  // ----------
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (evnt: MessageEvent<any>) => {
    const data = Object.assign({}, evnt.data);
    switch (data.message) {
      case "chartData": {
        const objToExport = {
          axisTitles: {} as Record<string, string>,
          data: [] as Array<Record<string, any>>,
          isData: false,
          isDataChanged: false,
          isTargetChanged: false,
          isRangeXChanged: false,
          oldData: "",
          oldTarget: "",
          minMaxY: { minY: null, maxY: null } as {
            minY: number | null;
            maxY: number | null;
          },
          minMaxX: { min: null, max: null, step: null } as {
            min: number | null;
            max: number | null;
            step: number | null;
          },
          xArray: null as Array<string> | null,
          xRange: null as Array<string> | null,
          numCntrs: 0,
          targetFloatPrecision: 0,
        };

        objToExport.axisTitles = prepareAxisTitles(
          data.payload.target,
          data.payload.lang,
          data.payload.reference
        ) as Record<string, string>;

        objToExport.data = getActiveCountries(
          data.payload.dataRedux,
          data.payload.countriesDic
        ) as Array<Record<string, any>>;

        objToExport.numCntrs = objToExport.data.length;

        objToExport.isData = objToExport.data.length > 0 ? true : false;

        const dataSerialized = serializedDataIds(objToExport.data);

        if (
          dataSerialized !== data.payload.oldData ||
          data.payload.isCompressedLines !== data.payload.oldIsCompressedLines
        ) {
          objToExport.isDataChanged = true;
        }
        if (data.payload.target !== data.payload.oldTarget) {
          objToExport.isTargetChanged = true;
        }

        objToExport.oldData = dataSerialized;
        objToExport.oldTarget = data.payload.target;
        objToExport.targetFloatPrecision =
          data.payload.reference.targets[data.payload.target].floatPrecision;

        // xArray
        const cntr1XDataArr =
          objToExport.data[0] === undefined
            ? []
            : objToExport.data[0].data
                .map((cntrDataObj: { x: string; y: number }) => cntrDataObj.x)
                .sort();

        objToExport.xArray = cntr1XDataArr.length === 0 ? [] : cntr1XDataArr;

        const minX = objToExport.xArray ? objToExport.xArray[0] : null;
        const maxX = objToExport.xArray
          ? objToExport.xArray[objToExport.xArray?.length - 1]
          : null;

        const rangeXMin =
          data.payload.rangeXMin !== null &&
          (!objToExport.isTargetChanged || data.payload.isXRangeFixedMin)
            ? data.payload.rangeXMin
            : minX
            ? Number(minX)
            : null;
        const rangeXMax =
          data.payload.rangeXMax !== null &&
          (!objToExport.isTargetChanged || data.payload.isXRangeFixedMax)
            ? data.payload.rangeXMax
            : maxX
            ? Number(maxX)
            : null;

        // xRange
        if (
          rangeXMin !== null &&
          rangeXMax !== null &&
          (data.payload.isRangeTuppleChanged ||
            objToExport.isDataChanged ||
            objToExport.isTargetChanged ||
            data.payload.isXRangeFixedMax ||
            data.payload.isXRangeFixedMin)
        ) {
          const newData = objToExport.data.reduce(
            (result: Array<Record<string, any>>, countryObj) => {
              let sumY = 0;
              let isInside = false;
              countryObj.data = countryObj.data.filter(
                (dataObj: { x: string; y: number }) => {
                  isInside =
                    Number(dataObj.x) >= rangeXMin &&
                    Number(dataObj.x) <= rangeXMax;
                  if (isInside) {
                    sumY += dataObj.y;
                  }
                  return isInside;
                }
              );

              if (sumY !== 0) {
                result.push(countryObj);
              }

              return result;
            },
            []
          );

          objToExport.isRangeXChanged = true;
          objToExport.data = newData;
        } //< xRange

        const isData = objToExport.data.length > 0 ? true : false;

        objToExport.minMaxY = isData
          ? getLineMinMax()(
              objToExport.data,
              "y",
              data.payload.isCompressedLines
            )
          : { min: null, max: null };

        objToExport.minMaxX = isData
          ? getLineMinMax()(objToExport.data, "x")
          : { min: rangeXMin, max: rangeXMax, step: 1 };

        objToExport.xArray = objToExport.isData
          ? objToExport.xArray
          : data.payload.xArray;

        const minMaxProps = {
          min: objToExport.minMaxX.min,
          max: objToExport.minMaxX.max,
          originArray: objToExport.xArray,
        };

        objToExport.xRange = objToExport.isData
          ? prepareXArray()(minMaxProps)
          : data.payload.xRange;

        // ---------
        // send message
        // ---------
        /* eslint-disable-next-line no-restricted-globals */
        self.postMessage({
          message: "succeeded",
          payload: objToExport,
        });
        break;
      }

      default: {
        if (process.env.NODE_ENV === "development") {
          console.info("%cchartWrapperWorker::PROBLEM: ", "color: red");
        }
        break;
      }
    }
  };
}
