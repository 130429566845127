import * as React from "react";
import { iconsStore } from "../../hooks_g/useIcons";
import { IconButton, type Theme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { animataion_blink } from "../../func/styled/animation_blink";
import Tooltip, {
  type TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ELangs } from "../../enums/ELangs";
import reference from "../../reference.json";

// ===========
// types
// ===========
type TComponentProps = {
  lang: ELangs;
};

// ===========
// pre-component
// ===========
const PreStyledTargetSidebarIcon = React.forwardRef<any, any>(
  function PreStyledTargetSidebarIcon(props, ref) {
    // ----------
    // icons
    // ----------
    const { ListIcon } = iconsStore;

    // ----------
    // render
    // ----------
    return <ListIcon {...props} ref={ref} />;
  }
);
// ||
// from here: https://github.com/mui/material-ui/issues/33079#issuecomment-1151216761

// ===========
// styled
// ===========
const TooltipCustom = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
    }}
    classes={{ popper: className }}
  />
))((allProps) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: allProps.theme.palette.primary.dark,
      color: allProps.theme.palette.common.white,
      // fontWeight: "bold",
      lineHeight: 2.5,
      letterSpacing: "0.2em",
      borderRadius: 0,
      fontSize: "0.8em",
    },
  };
});

// =========
// main
// =========
export function RTopbarTargetSidebar(props: TComponentProps) {
  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RTopbarTargetSidebar";
  const { isTargetSidebarOpen } = busContext.getDataFromBus(
    [busNames.isTargetSidebarOpen],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  // **

  //--------
  // other data
  //--------
  const title = reference.ui.indicators_menu.name[props.lang];

  // ----------
  // handlers
  // ----------
  const handlerButtonClick = (evnt: React.MouseEvent) => {
    evnt.stopPropagation();

    busContext.placeDataToBus(
      busNames.isTargetSidebarOpen,
      !isTargetSidebarOpen,
      fileID
    );
  };

  // ---------
  // location
  // ---------
  const location = useLocation();
  const isShowButton = location.pathname === "/" ? false : true;

  // ----------
  // rendering
  // ----------
  return (
    <TooltipCustom title={title} placement="left">
      <IconButton
        className="RTopbarTargetSidebar"
        type="button"
        sx={_iconStyle(isShowButton, theme)}
        disabled={!isShowButton}
        onClick={handlerButtonClick}
      >
        <PreStyledTargetSidebarIcon />
      </IconButton>
    </TooltipCustom>
  );
}

// =========
// styles
// =========
function _iconStyle(isShowButton: boolean, theme: Theme) {
  if (isShowButton) {
    const isDarkMode = theme.palette.mode === "dark" ? true : false;

    return {
      p: 1,
      color: "inherit",
      ...animataion_blink(
        5000,
        500,
        8,
        "transparent",
        `${
          // isDarkMode
          //   ? `${theme.palette.secondary.main}5e`
          //   : `${theme.palette.secondary.dark}f5`
          isDarkMode
            ? `${theme.palette.secondary.main}`
            : `${theme.palette.secondary.dark}`
        }`
      ),
    };
  } else {
    return {
      p: 1,
      color: "inherit",
    };
  }
}
