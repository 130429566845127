/**
 * from here: https://stackoverflow.com/questions/76095729/how-can-i-add-my-custom-component-to-mui-theme-options-so-that-i-can-override-it
 */

// ===========
// import
// ===========
import * as React from "react";
import { styled } from "@mui/material/styles";
import { ELangs } from "../enums/ELangs";
import reference from "../reference.json";
import Box from "@mui/material/Box";
import ROtherCountries from "../components/other_countries/ROtherCountries";
import RWorldBankLink from "../components/other_countries/RWorldBankLink";
import { noReduxSelectors } from "../context/noredux/mainStore";

// ===========
// types
// ===========
type RStyledSourceProps = {
  lang: ELangs;
  baseHeight: string;
  lastupdated: string;
  ourlastupdated: string;
  target: string;
  targetHTTP: string;
};

const StyledSourceWrapper = styled("div", {
  name: "StyledSourceWrapper",
  slot: "Root",
  shouldForwardProp: (prop) => prop !== "baseHeight",
})<{ baseHeight: string }>((allProps) => {
  const isDark = allProps.theme.palette.mode === "dark" ? true : false;

  // --------
  // CSSObject
  // --------
  return {
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    maxWidth: "500px",
    justifyContent: "center",
    overflow: "hidden",

    "& .SourceDataBlock": {
      width: "100%",
      color: allProps.theme.palette.text.secondary,
      fontSize: "1.2em",
    },
    "& .SourceDataBlock .NameOfDate": {
      fontWeight: "bold",
    },
    "& .SourceDataBlock .Date": {
      color: isDark
        ? allProps.theme.palette.secondary.main
        : allProps.theme.palette.secondary.dark,
      marginLeft: "0.5em",
    },
  };
});

// ============
// main
// ============
export const RStyledSource = React.forwardRef(function RStyledSource(
  inProps: RStyledSourceProps,
  _ref
) {
  const indicatorID = noReduxSelectors.selectIndicatorID(inProps.target);

  // --------
  // rendering
  // --------
  return (
    <StyledSourceWrapper baseHeight={inProps.baseHeight}>
      <Box className="RStyledSource SourceDataBlock">
        {/* Link */}
        <p>
          <span className="NameOfDate">
            {reference.ui.pages.source.link.name[inProps.lang]}:{" "}
          </span>
          <span className="Date OuterLink">{indicatorID}</span>
        </p>
        {/* WB Update */}
        <p>
          <span className="NameOfDate">
            {reference.ui.pages.source.dateWB.name[inProps.lang]}:{" "}
          </span>
          <span className="Date">{inProps.lastupdated}</span>
        </p>
        {/* PU Update */}
        <p>
          <span className="NameOfDate">
            {reference.ui.pages.source.datePU.name[inProps.lang]}:{" "}
          </span>
          <span className="Date">{inProps.ourlastupdated}</span>
        </p>
        {/* Other Countries */}
        <ROtherCountries lang={inProps.lang} />
        <RWorldBankLink templateHTTP={inProps.targetHTTP} lang={inProps.lang} />
      </Box>
    </StyledSourceWrapper>
  );
});
