// ============
// import
// ============
import reference from "../reference.json";
import { CUtilIter } from "../classes/CUtilIter";

// ============
// types
// ============
type TCountriesID = keyof typeof reference.countries & string;
type TCountries = { [x: string]: TCountriesID };

// https://stackoverflow.com/questions/53662208/types-from-both-keys-and-values-of-object-in-typescript
type TCountriesKeys = keyof typeof ECountriesObj & string;
export type TECountriesVal = (typeof ECountriesObj)[TCountriesKeys];
// export type TECountries = { [x: TCountriesKeys]: TECountriesVal };
export type TECountries = Record<TCountriesKeys, TECountriesVal>;

// ============
// main
// ============
const ECountriesObj: TCountries = (function () {
  const targets = reference.countries;
  const iterTargets = new CUtilIter(targets);
  let countries = {} as TCountries;

  for (const country of iterTargets) {
    if (country === undefined) {
      break;
    }
    countries[country[0].toUpperCase()] = country[0] as TCountriesID;
  }

  countries = { ...countries } as const;

  return countries;
})();

export const ECountries = ECountriesObj as TECountries;
