export function replaceSymbolsInString(
  this: { str: string },
  symbolsForReplace: string, // exmp: "{@}"
  replacement_1: string, // exmp: ""
  replacement_2: string = "", // exmp: "\n"
  option: boolean = true
) {
  if (option) {
    return Object.assign({}, this, {
      str: this.str.replaceAll(symbolsForReplace, replacement_1),
    });
  } else {
    return Object.assign({}, this, {
      str: this.str.replaceAll(symbolsForReplace, replacement_2),
    });
  }
}
