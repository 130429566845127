// ===========
// import
// ===========
import * as bm from "../barrelModule";
import * as React from "react";
import { RStyledDescription } from "../../../../styled/RStyledDescription";
import Box from "@mui/material/Box";
import { /*type Theme*/ type Breakpoint } from "@mui/material/styles";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { type TThemeSettings } from "../../../../theming/themeSettings";

// ===========
// types
// ===========
type TComponentProps = {
  lang: bm.ELangs;
  target: bm.TEWhatVal;
  baseHeight: string;
  // theme: Theme;
  isMobile: boolean;
};
type TResult = {
  baseHeight: string;
  EN:
    | {
        id: string;
        name: string;
        description: string;
        sourceOrganization: string;
        topics: string;
      }
    | undefined;
  RU:
    | {
        id: string;
        name: string;
        description: string;
        sourceOrganization: string;
        topics: string;
      }
    | undefined;
};

// ===========
// constants
// ===========
const URL_TEMPLATE = "/data/description/:target/wb.json";

// ===========
// regex
// ===========
const regex = /([a-z]+)_([a-z_0-9]+)/;

// ===========
// main
// ===========
export function RDescription(props: TComponentProps) {
  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RDescription";
  const { isAnimation, isMobile, isTablet } = busContext.getDataFromBus(
    [busNames.isAnimation, busNames.isMobile, busNames.isTablet],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);

  // --------
  // local state
  // --------
  const [resultData, setResultData] = bm.useState<TResult>({} as TResult);
  const [isReady, setIsReady] = bm.useState(false);

  const regResult = props.target.match(regex);

  let targetAddress = "";

  if (regResult === null) {
    throw new Error("RDescription: 'regResult === null.");
  } else {
    targetAddress = regResult[2];
  }

  // --------
  // http address of a target
  // --------
  const httpAddress = URL_TEMPLATE.replace(":target", targetAddress);

  // --------
  // Effects
  // --------
  bm.useEffect(() => {
    (async () => {
      const result = await _fetchToServer(httpAddress);
      setResultData(
        Object.assign({}, result, { baseHeight: props.baseHeight })
      );
      setIsReady(true);
    })();
  }, [httpAddress]);

  //>
  React.useEffect(() => {
    busContext.placeDataToBus(busNames.targetInContext, props.target, fileID);
  }, [props.target]);

  // --------
  // pre-rendering
  // --------
  const isTbaletOrMobile = isTablet || isMobile;

  // --------
  // rendering
  // --------
  if (isAnimation && !isMobile) {
    return (
      <Box
        className="RStyledDescriptionWrapper"
        sx={_stylerRStyledDescriptionWrapper(props.isMobile)}
      >
        {!props.isMobile && (
          <Box
            className="RStyledDescriptionWrapper_MockBack"
            sx={_stylerRStyledDescriptionWrapper_MockBack(theme)}
          ></Box>
        )}
        <Box />
      </Box>
    );
  } else {
    return (
      <Box
        className="RStyledDescriptionWrapper"
        sx={_stylerRStyledDescriptionWrapper(props.isMobile)}
      >
        {!props.isMobile && (
          <Box
            className="RStyledDescriptionWrapper_MockBack"
            sx={_stylerRStyledDescriptionWrapper_MockBack(theme)}
          ></Box>
        )}
        <Box
          className="RStyledDescriptionWrapper_Data"
          sx={_stylerRStyledDescriptionWrapper_Data(theme)}
        >
          <Box
            className="RStyledDescriptionWrapper_Data_Container"
            sx={_stylerRStyledDescriptionWrapper_Data_Container()}
          >
            {isReady && (
              <RStyledDescription
                resultData={resultData}
                lang={props.lang}
                isTabletOrMobile={isTbaletOrMobile}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

// ===========
// utils
// ===========
async function _fetchToServer(httpAddress: string) {
  return await fetch(httpAddress).then((response) => response.json());
}

// ===========
// stylers
// ===========
function _stylerRStyledDescriptionWrapper(isMobile: boolean) {
  return {
    display: "grid",
    gridTemplateColumns: !isMobile ? "38.2% auto" : "auto",
    height: "100%",
    width: "100%",
  };
}

function _stylerRStyledDescriptionWrapper_MockBack(theme: TThemeSettings) {
  const OVERLAY_OPACITY = 16;
  const { color, kefs } = theme.overlay;
  const overlayColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${
    kefs[`k${OVERLAY_OPACITY}`]
  } )`;

  return {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(${overlayColor}, ${overlayColor})`,
  };
}

function _stylerRStyledDescriptionWrapper_Data(
  theme: TThemeSettings & { breakpoints: { up: (size: Breakpoint) => any } }
) {
  const isNotMobile = theme.breakpoints.up("lg");

  // -------
  // CSSObject
  // -------
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: isNotMobile ? "center" : "flex-start",
    // justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "hidden",
    padding: "1em 0",
    [theme.breakpoints.up("xl" as Breakpoint)]: {
      padding: 0,
    },
  };
}

function _stylerRStyledDescriptionWrapper_Data_Container() {
  return {
    overflowY: "auto",
  };
}
