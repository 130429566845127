import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { BusContext } from "./bus_context/busContext";
import { busContextStore } from "./bus_context/busContextStore";
import {
  busNames,
  iii_breakpoints,
  tabletSizes,
} from "./bus_context/busContextStore";
import { initWorker } from "./workers/initWorker";
import { initApp } from "./hooks_g/useInitApp";

// ==============
// BusContext
// ==============
export const busState: Record<symbol, any> = {};
export const busContext = new BusContext();
const fileID = "index";

const langTag = window.navigator.language;

busContextStore(busContext, langTag, window.navigator);

// ==============
// init
// ==============
const isMobile = window.innerWidth <= iii_breakpoints.sm;
const isTablet =
  window.innerWidth > iii_breakpoints.sm &&
  window.innerWidth <= tabletSizes.width;
const isDesktop =
  window.innerWidth > tabletSizes.width &&
  window.innerHeight > tabletSizes.height;

const kefHeight = window.outerHeight / window.innerHeight;
const kefWidth = window.outerWidth / window.innerWidth;

busContext.setBusContext(busNames.isMobile, isMobile, (data) => data);
busContext.setBusContext(busNames.isTablet, isTablet, (data) => data);
busContext.setBusContext(busNames.isDesktop, isDesktop, (data) => data);
busContext.setBusContext(busNames.kefHeight, kefHeight, (data) => data);
busContext.setBusContext(busNames.kefWidth, kefWidth, (data) => data);

// ---
// init App
// ---
initApp(busContext);

// ==============
// global events
// ==============
let stop = false;
let removeResizeEventFn = () => {};

(function resizeEventFn() {
  removeResizeEventFn();
  removeResizeEventFn = () => {
    window.removeEventListener("resize", resizeEventFn);
  };

  if (!stop) {
    stop = true;
    window.setTimeout(function timeOut() {
      if (!busContext) {
        window.setTimeout(() => timeOut());
      } else {
        // >---
        const isMobile = window.innerWidth <= iii_breakpoints.sm;

        const isTablet =
          window.innerWidth > iii_breakpoints.sm &&
          window.innerWidth <= tabletSizes.width;

        const isDesktop =
          window.innerWidth > tabletSizes.width &&
          window.innerHeight > tabletSizes.height;

        const kefHeight = window.devicePixelRatio;

        busContext.placeDataToBus(
          busNames.windowSizes,
          {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          fileID
        );
        busContext.placeDataToBus(busNames.isMobile, isMobile, fileID);
        busContext.placeDataToBus(busNames.isTablet, isTablet, fileID);
        busContext.placeDataToBus(busNames.isDesktop, isDesktop, fileID);
        busContext.placeDataToBus(
          busNames.kefWidth,
          window.devicePixelRatio,
          fileID
        );
        busContext.placeDataToBus(busNames.kefHeight, kefHeight, fileID);
        stop = false;
      }
    }, 100);
  }

  window.addEventListener("resize", resizeEventFn);
})();

// -----
// Zoom Event Listener
// from here: https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
// -----
let removeUpdPixelRatioFn = () => {};
(function updPixelRatioFn() {
  removeUpdPixelRatioFn();

  const pixRatio = window.devicePixelRatio;
  const k = pixRatio > 0.9 && pixRatio < 1.35 ? 1 / pixRatio : 1;
  const mqString = `(resolution: ${pixRatio}dppx)`;
  const media = matchMedia(mqString);
  media.addEventListener("change", updPixelRatioFn);
  const kefHeight = isDesktop ? k : window.innerHeight / tabletSizes.height;

  removeUpdPixelRatioFn = () => {
    media.removeEventListener("change", updPixelRatioFn);
  };

  busContext.placeDataToBus(busNames.kefHeight, kefHeight, fileID);
})();

// -----
// Keydown events
// -----

// ==============
// start url (location)
// ==============
const startURL = window.location.pathname;
const routeId = startURL.slice(1, startURL.indexOf("_"));

busContext.setBusContext(
  busNames.activeIndicator,
  Boolean(routeId) ? routeId : "startPage",
  (data) => data
);
busContext.setBusContext(busNames.isInitial, true, (data) => data);
busContext.placeDataToBusReplace(
  busNames.activeIndicator,
  Boolean(routeId) ? routeId : "startPage",
  `RSidebarMuiItem${routeId}`
);

// ==============
// worker
// ==============
export const chartWorker = initWorker();

// ==============
// rendering
// ==============
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
