/**
 * Выезжает справа
 */

// ===========
// import
// ===========
import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { iconsStore } from "../../../hooks_g/useIcons";
import { RTargetsNavTabs } from "../../content/targetsbar/RTargetsNavTabs";
import { useLocation } from "react-router-dom";
import { busContext } from "../../..";
import { busNames } from "../../../bus_context/busContextStore";
import config from "../../../config.json";

// ===========
// types
// ===========
type TListItemButton = {
  className: string;
  children: Array<React.ReactElement>;
  onClick: () => void;
  open: boolean;
};

// ===========
// const
// ===========
const drawerWidth = 240;

// ===========
// styles mixins
// ===========
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => {
  return {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    //@ts-ignore
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  };
};

const StyledDrawer = styled(Drawer)((props) => {
  const { theme, open } = props;

  // ---------
  // return
  // ---------
  return {
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.paper,
      // width: "300px",
      width: config.layout.grid.width.desktop["2clmn"],
    },
  };
});

const StyledListItemButton = styled(
  (props: TListItemButton) => (
    <ListItemButton {...props}>{props.children}</ListItemButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "open",
  }
)((props) => {
  const PL = "4.108px"; // Для центрировани иконки
  return {
    height: "48px",
    justifyContent: "flex-start",
    px: 2.5,
    "& div": {
      color: "inherit",
    },
    "& .MuiListItemIcon-root": {
      paddingLeft: PL,
      opacity: props.theme.palette.mode === "dark" ? 0.7 : 1,
    },
  };
});

// ===========
// main
// ===========
export function RTargetsDrawer() {
  const { Close } = iconsStore;

  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RTargetsDrawer";
  const { isTargetSidebarOpen, lang } = busContext.getDataFromBus(
    [busNames.isTargetSidebarOpen, busNames.lang],
    fileID,
    setTrigg
  );

  // ---------
  // location
  // ---------
  const location = useLocation();
  const isShowNavTabs = location.pathname === "/" ? false : true;

  // ---------
  // rendering
  // ---------
  if (!isShowNavTabs) {
    return <div></div>;
  }
  return (
    <Box className="RTargetsDrawer" sx={_stylesBox}>
      <StyledDrawer
        open={isTargetSidebarOpen}
        anchor="right"
        className="RTargetsDrawer_StyledDrawer"
      >
        <List className="RTargetsDrawer_List">
          <ListItem disablePadding sx={{ display: "block" }} className="Menu">
            <StyledListItemButton
              className="RMiniDrawer_Box_Menu"
              onClick={() => {
                busContext.placeDataToBus(
                  busNames.isTargetSidebarOpen,
                  !isTargetSidebarOpen,
                  fileID
                );
              }}
              open={isTargetSidebarOpen}
            >
              <ListItemText
                className="RMiniDrawer_ListItemText"
                primary={/*TITLE*/ ""}
                sx={_stylesListItemText(isTargetSidebarOpen)}
              />
              <ListItemIcon sx={_stylesListItemIcon()}>
                <Close />
              </ListItemIcon>
            </StyledListItemButton>
          </ListItem>
        </List>
        <RTargetsNavTabs
          isTargetSidebarOpen={isTargetSidebarOpen}
          isTargetsNavOnScreen={false}
          lang={lang}
        />
      </StyledDrawer>
    </Box>
  );
}

// ===========
// styles
// ===========
function _stylesListItemIcon() {
  return {
    minWidth: 0,
    // mr: open ? 3 : "auto",
    // ml: open ? "auto" : 3,
    ml: "auto",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      width: "1.5rem",
      height: "1.5rem",
    },
  };
}

function _stylesListItemText(open: boolean) {
  return {
    opacity: open ? 1 : 0,
    whiteSpace: "nowrap",
    transition: "opacity 0.01s 2s",
  };
}

function _stylesBox() {
  return {
    display: "flex",
  };
}
