// ===========
// import
// ===========
import * as React from "react";
import { Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import reference from "../../../../reference.json";
import { ELangs } from "../../../../enums/ELangs";
import { prepareCountries } from "../../../../func/prepare/prepareCountries";
import { ButtonBase } from "@mui/material";
import { iconsStore } from "../../../../hooks_g/useIcons";
import { ButtonGroup } from "@mui/material";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { type Breakpoint } from "@mui/material/styles";
import { RStyledGridContainer as GridStyledContainer } from "../../../../styled/RStyledGridContainer";
import { RStyledGridContent as GridStyledContent } from "../../../../styled/RStyledGridContent";
import { RStyledFlexContainer as FlexStyledContainer } from "../../../../styled/RStyledFlexContainer";
import { replaceSymbolsInString } from "../../../../func/prepare/replaceSymbolsInString";
import {
  toggleCountryActiveNRedux,
  toggleAllCountriesNRedux,
} from "../../../../context/noredux/countries/countriesSlice";
import {
  noReduxSelectors,
  noReduxEStateNames,
} from "../../../../context/noredux/mainStore";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import changeUrl from "../../../../func/url/changeUrl";

// ===========
// types
// ===========

// ===========
// constants
// ===========
const MAX_COUNTRIES = 15;

// ===========
// subcomponents
// ===========
function ButtonWithCountries(props: {
  country: {
    countryId: string;
    name: string;
    isActive: boolean;
    color: string;
  };
  isMobile: boolean;
  theme: Theme;
  isTablet: boolean;
}) {
  const { country } = props;
  const { CircleBound, CircleFill } = iconsStore;

  // ------------
  // handlers
  // ------------
  const onActiveCountry =
    (country: any) => (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();

      window.setTimeout(() => {
        toggleCountryActiveNRedux({
          id: country.countryId,
          changes: {
            id: country.countryId,
            active: !country.isActive,
          },
        });
      }, 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <ButtonBase
      key={country.countryId}
      className="RStartTab_ButtonWithCountries"
      sx={_buttonWCStyle(props.isMobile, props.isTablet, props.theme)}
      onClick={onActiveCountry(country)}
    >
      <span>
        {country.isActive ? (
          <CircleFill sx={{ color: country.color }} />
        ) : (
          <CircleBound />
        )}
      </span>
      <span className="RStartTab_ButtonWithCountries_CountryName">
        {country.name}
      </span>
    </ButtonBase>
  );
}

function ButtonGroupAllCntrs({
  countries,
  lang,
  isMobile = false,
  theme,
  activeCountries,
  isTablet,
}: {
  countries: any;
  lang: ELangs;
  isMobile?: boolean;
  theme?: Theme;
  activeCountries: number;
  isTablet: boolean;
}) {
  const { CircleBound, CircleFill } = iconsStore;

  const isAllActive = activeCountries === MAX_COUNTRIES;
  const isSomeActive = activeCountries > 0;

  // ------------
  // handlers
  // ------------
  const onAllCountriesActive =
    (cntrs: Array<any>, active: boolean) =>
    (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();
      const resultArrayNRedux = cntrs.map((cntr: any) => {
        return {
          id: cntr.id,
          active: active,
        };
      });

      window.setTimeout(() => {
        toggleAllCountriesNRedux(resultArrayNRedux);
      }, 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined button group"
      orientation="vertical"
      sx={{
        // paddingLeft: isMobile ? "1em" : 0,
        paddingLeft: "1em",
        color: theme?.palette.text.secondary,
      }}
    >
      <ButtonBase
        className="RStartTab_ButtonWithCountries_AllCountries_ON"
        sx={_buttonWCStyle(isMobile, isTablet, theme)}
        onClick={onAllCountriesActive(countries, true)}
      >
        <span>
          {isAllActive && (
            <CircleBound
              sx={{
                color: theme?.palette.text.disabled,
              }}
            />
          )}
          {!isAllActive && (
            <CircleFill
              sx={{
                color: "inherit",
              }}
            />
          )}
        </span>
        <span className="RStartTab_ButtonWithCountries_CountryName">
          {reference.utils.allCountries.on.name[lang]}
        </span>
      </ButtonBase>
      <ButtonBase
        className="RStartTab_ButtonWithCountries_AllCountries_OFF"
        sx={_buttonWCStyle(isMobile, isTablet, theme)}
        onClick={onAllCountriesActive(countries, false)}
      >
        <span>
          {!isSomeActive && (
            <CircleBound
              sx={{
                color: theme?.palette.text.disabled,
              }}
            />
          )}
          {isSomeActive && (
            <CircleFill
              sx={{
                color: "inherit",
              }}
            />
          )}
        </span>
        <span className="RStartTab_ButtonWithCountries_CountryName">
          {reference.utils.allCountries.off.name[lang]}
        </span>
      </ButtonBase>
    </ButtonGroup>
  );
}

// ===========
// main
// ===========
export function RStartTab() {
  // --------
  // bus
  // --------
  const [, setTrigg] = React.useState({});
  const fileID = "RStartTab";
  const { lang, isTablet, isDesktop } = busContext.getDataFromBus(
    [busNames.lang, busNames.isTablet, busNames.isDesktop],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);
  //<

  const countryHeadersObj = reference.headers.startPage.countries;

  const cntrs = noReduxSelectors.selectAll(
    noReduxEStateNames.get("countries")
  ) as Array<any>;

  const mode = theme.palette.mode;
  const countries = prepareCountries(lang, cntrs, mode);
  const allCountries = countries.length;
  const arrActiveCountries = countries.filter(
    (country) => country.isActive === true
  );
  const strActiveCountries = arrActiveCountries
    .map((cntr) => cntr.countryId)
    .join("-");

  // ---
  // change url in 'address bar'
  // ---
  changeUrl({ cntrs: strActiveCountries });
  //<

  const activeCountries = arrActiveCountries.length;
  const isWiderThanMobileScreen = useMediaQuery(
    theme.breakpoints.up("sm" as Breakpoint)
  );
  const isWiderThanLaptopMPI = useMediaQuery(
    // @ts-ignore
    theme.breakpoints.up(theme.breakpoints.values.lg + 1)
  );
  const isAllCountriesActive = activeCountries < MAX_COUNTRIES ? false : true;
  const isDark = mode === "dark" ? true : false;

  // ----------
  // styles objects
  // ----------
  const gridStyledContainerProps = React.useMemo(
    () => ({
      gridTemplateRows: "fit-content(2rem) 1fr",
      padding: isDesktop ? "2rem 1rem 1rem 0" : "1em 0 0 0",
      rowGap: "1rem",
      color: theme.palette.text.primary,
      isTablet: isTablet,
    }),
    // eslint-disable-next-line
    [isDesktop]
  );

  const gridStyledContentProps = React.useMemo(
    () => ({
      gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
      columnGap: "3rem",
      rowGap: !isTablet ? "3rem" : "unset",
      height: "100%",
      isFirefox: isFirefox,
      isMobile: !isWiderThanMobileScreen,
    }),
    // eslint-disable-next-line
    [isWiderThanMobileScreen]
  );

  const flexStyledContainerProps = React.useMemo(() => {
    // ---
    // CSSObject
    // ---
    return {
      flexDirection: "column" as const,
      marginBottom: "1em",
      rowGap: "0.5rem",
    };
  }, []);

  // ----------
  // markup for num of active countries
  // ----------
  const numOfActiveCntrsStr =
    reference.greetings.startPage.selected.part1[lang as ELangs] +
    ` <span class="numOfACountries">${activeCountries}</span> ` +
    reference.greetings.startPage.selected.part2[lang as ELangs] +
    ` ${allCountries}`;
  const markupNumOfACntrs = { __html: numOfActiveCntrsStr };

  // ----------
  // rendering
  // ----------
  return (
    <GridStyledContainer
      className="RStartTab_GridStyledContainer"
      {...gridStyledContainerProps}
    >
      {/* Header  */}
      <Box
        className="RStartTab_GridStyledContainer_Header"
        sx={_stylerHeader(isDesktop, theme)}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={{
            marginBottom: "0.2em",
            fontSize: isTablet ? "2em" : "2rem",
            "& .RStartTab_h2 em": {
              color: isDark
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            },
          }}
        >
          {isAllCountriesActive
            ? ((str) => {
                let result = { str, replaceSymbolsInString } as {
                  str: string;
                  replaceSymbolsInString: Function;
                };
                const markup = { __html: result.str };
                return (
                  <span
                    className="RStartTab_h2"
                    dangerouslySetInnerHTML={markup}
                  />
                );
              })(reference.greetings.startPage.selection_1[lang as ELangs])
            : ((str) => {
                let result = { str, replaceSymbolsInString } as {
                  str: string;
                  replaceSymbolsInString: Function;
                };
                result = result.replaceSymbolsInString(
                  "{@}",
                  "",
                  "\n",
                  isDesktop
                );
                return result.str;
              })(reference.greetings.startPage.selection_2[lang as ELangs])}
        </Typography>
        <Typography
          component="h4"
          sx={{
            color:
              mode === "light"
                ? theme.palette.primary.dark
                : theme.palette.text.secondary,
            marginLeft: "0.2em",
            fontSize: isTablet ? "1em" : "1.2em",
            "& .numOfACountries": {
              fontWeight: "bold",
              color: isDark
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            },
          }}
        >
          <span
            className="aCntrs"
            dangerouslySetInnerHTML={markupNumOfACntrs}
          />
        </Typography>
      </Box>
      {/* section  */}
      <GridStyledContent
        {...gridStyledContentProps}
        isTablet={isTablet}
        isScroll={true}
      >
        {/* All countries */}
        <FlexStyledContainer
          sx={flexStyledContainerProps}
          className="FlexStyledContainer"
        >
          <Typography
            variant="h5"
            sx={{
              borderBottom: isWiderThanMobileScreen
                ? (theme) => `1px solid ${theme.palette.text.disabled}`
                : "none",
              textTransform: "uppercase",
              marginTop: "2em",
              color: theme.palette.primary.main,
              paddingLeft: isDesktop ? 0 : "1em",
              fontWeight: "bold",
              fontSize: isTablet ? "1em" : "1rem",
            }}
          >
            {reference.headers.startPage.allCountries.all.name[lang as ELangs]}
          </Typography>
          <ButtonGroupAllCntrs
            countries={cntrs}
            lang={lang}
            isMobile={!isWiderThanMobileScreen}
            theme={theme}
            activeCountries={activeCountries}
            isTablet={isTablet}
          />
        </FlexStyledContainer>

        {/* Countries  */}
        {Object.values(countryHeadersObj).map((countryObj) => (
          <FlexStyledContainer
            key={countryObj.id}
            sx={flexStyledContainerProps}
            className="FlexStyledContainer RegionWrapper"
          >
            <Typography
              variant="h5"
              sx={{
                borderBottom: isWiderThanMobileScreen
                  ? (theme) => `1px solid ${theme.palette.text.disabled}`
                  : "none",
                textTransform: "uppercase",
                marginTop: "2em",
                color: theme.palette.primary.main,
                paddingLeft: isDesktop ? 0 : "1em",
                fontWeight: "bold",
                fontSize: isTablet ? "1em" : "1rem",
              }}
            >
              {countryObj.name[lang as ELangs]}
            </Typography>
            <FlexStyledContainer
              className="FlexStyledContainer CountriesWrapper"
              sx={{
                ...flexStyledContainerProps,
                marginBottom: "0",
                rowGap: isWiderThanLaptopMPI ? "0.5em" : "0.2em",
                paddingLeft: isDesktop ? 0 : "1em",
              }}
            >
              {countries
                .filter(
                  (countryRefObj) => countryRefObj.region === countryObj.id
                )
                .map((searchedCountry) => (
                  <ButtonWithCountries
                    key={searchedCountry.countryId}
                    country={searchedCountry}
                    isMobile={!isWiderThanMobileScreen}
                    theme={theme}
                    isTablet={isTablet}
                  />
                ))}
            </FlexStyledContainer>
          </FlexStyledContainer>
        ))}
      </GridStyledContent>
    </GridStyledContainer>
  );
}

// ============
// styles
// ============
function _buttonWCStyle(
  isMobile: boolean = false,
  isTablet: boolean,
  theme?: Theme
) {
  return {
    justifyContent: "flex-start",
    fontSize: isMobile ? "16px" : isTablet ? "1em" : "inherit",
    padding: isMobile ? "9px 27px" : "inherit",
    border: isMobile
      ? `1px solid ${theme?.palette.primary.main + "8c"}`
      : "inherit",
    borderRadius: isMobile ? "4px" : "inherit",
    width: isMobile ? "auto" : "inherit",
    margin: isMobile ? "8px" : "inherit",
    marginLeft: isMobile ? "0" : "inherit",
    marginRight: isMobile ? "1em" : "8px",
    whiteSpace: "nowrap",
    "& .MuiSvgIcon-root": {
      width: "0.8em",
      height: "0.8em",
    },
    "& .RStartTab_ButtonWithCountries_CountryName": {
      marginBottom: "0.2em",
      marginLeft: "1em",
      fontSize: isTablet ? "1em" : "1.2em",
    },
  };
}

function _stylerHeader(isDesktop: boolean, theme: Theme) {
  if (isDesktop) {
    return {
      padding: "initial",
      marginBottom: "1em",
      position: "relative",
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      paddingBottom: "0.2em",
    };
  } else {
    return {
      padding: "0 1em",
      position: "relative",
      marginBottom: "initial",
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      paddingBottom: "0.2em",
    };
  }
}
