// ==========
// import
// ==========
import { BusContext } from "./busContext";
import { type Theme } from "@mui/material/styles";
import { ELangs } from "../enums/ELangs";
import { TEWhatVal } from "../enums/EWhat";
import { type CountryType } from "../components/other_countries/ROtherCountries";

// =============
// const
// =============
const DEFAULT_TARGET = "aggregate_nygdpmktpcd";
export const tabletSizes = {
  width: 1390,
  height: 768,
};

// from: https://mui.com/material-ui/customization/breakpoints/
export const iii_breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

// ==========
// busNames
// ==========
export const busNames = {
  isAnimation: Symbol("isAnimation"),
  theme: Symbol("theme"),
  windowSizes: Symbol("windowSizes"),
  isTablet: Symbol("isTablet"),
  colorMode: Symbol("colorMode"),
  isMobile: Symbol("isMobile"),
  lang: Symbol("lang"),
  isDesktop: Symbol("isDesktop"),
  targetInContext: Symbol("targetInContext"),
  tabNumber: Symbol("tabNumber"),
  tabNumberH: Symbol("tabNumberH"), // Home
  activeIndicator: Symbol("activeIndicator"),
  isMiniDrawerOpen: Symbol("isMiniDrawerOpen"),
  isTargetSidebarOpen: Symbol("isTargetSidebarOpen"),
  otherCountries: Symbol("otherCountries"),
  yearsRangeTupple: Symbol("yearsRangeTupple"),
  isFirefox: Symbol("isFirefox"),
  isInitial: Symbol("isInitial"),
  kefHeight: Symbol("kefHeight"),
  kefWidth: Symbol("kefWidth"),
  yearInit: Symbol("yearInit"),
  isFullscreenMode: Symbol("isFullscreenMode"),
  currentXYear: Symbol("currentXYear"),
};

// ==========
// main
// ==========
export function busContextStore(
  busContext: BusContext,
  browserLang: string,
  // userAgent: string
  navigator: Record<string, any>
) {
  // -----------
  // isAnimation
  // -----------
  busContext.setBusContext(
    busNames.isAnimation,
    false,
    (data: boolean) => data
  );

  // -----------
  // windowSizes
  // -----------
  busContext.setBusContext(
    busNames.windowSizes,
    { width: window.innerWidth, height: window.innerHeight },
    (data: { width: string; height: string }) => {
      return data;
    }
  );

  // -----------
  // isTablet
  // -----------
  // see "index.ts"

  // -----------
  // colorMode
  // -----------
  // see useInitApp.ts::_fnPlaceClMode

  // -----------
  // theme
  // -----------
  busContext.setBusContext<Theme | {}, Theme & { [x: string]: any }>(
    busNames.theme,
    {},
    (data) => data
  );

  // -----------
  // isMobile
  // -----------
  // see "index.ts"

  // -----------
  // lang
  // -----------
  busContext.setBusContext(
    busNames.lang,
    browserLang.includes("ru") ? ELangs.RU : ELangs.EN,
    (data: typeof ELangs) => data
  );

  // -----------
  // isDesktop
  // -----------
  // see "index.ts"

  // -----------
  // targetInContext
  // -----------
  busContext.setBusContext<TEWhatVal, TEWhatVal>(
    busNames.targetInContext,
    DEFAULT_TARGET,
    (data) => data
  );

  // -----------
  // tabNumber
  // -----------
  // see useInitApp.ts::_fnPlaceTabNumber

  // -----------
  // tabNumberH
  // -----------
  // see useInitApp.ts::_fnPlaceTabNumber

  // -----------
  // activeIndicator
  // -----------
  // see index.ts

  // -----------
  // isMiniDrawerOpen
  // -----------
  busContext.setBusContext(busNames.isMiniDrawerOpen, false, (data) => data);

  // -----------
  // isTargetSidebarOpen
  // -----------
  busContext.setBusContext(busNames.isTargetSidebarOpen, false, (data) => data);

  // -----------
  // otherCountries
  // -----------
  busContext.setBusContext(
    busNames.otherCountries,
    [],
    (data: Array<CountryType>) => data
  );

  // -----------
  // yearsRangeTupple
  // -----------
  // see useInitApp::_fnPlaceRangeTupple

  // -----------
  // isFirefox
  // -----------
  busContext.setBusContext(
    busNames.isFirefox,
    navigator.userAgent.includes("Firefox") ||
      navigator.vendor.includes("Apple")
      ? true
      : false,
    () =>
      navigator.userAgent.includes("Firefox") ||
      navigator.vendor.includes("Apple")
        ? true
        : false
  );

  // -----------
  // isInitial
  // -----------
  // see index.ts

  // -----------
  // kefHeight
  // -----------
  // see index.ts

  // -----------
  // kefWidth
  // -----------
  // see index.ts

  // -----------
  // yearInit
  // -----------
  // see useInitApp.ts::

  // -----------
  // isFullscreenMode
  // -----------
  busContext.setBusContext(busNames.isFullscreenMode, false, (data) => data);

  // -----------
  // currentXYear
  // -----------
  busContext.setBusContext(busNames.currentXYear, 0, (data) => data);
}
