// =======
// import
// =======
import { type Theme } from "@mui/material/styles";
import { toggleAllCountriesNRedux } from "../../../../../context/noredux/countries/countriesSlice";
import { ELangs } from "../../../../../enums/ELangs";
import { iconsStore } from "../../../../../hooks_g/useIcons";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import reference from "../../../../../reference.json";

// =======
// main
// =======
export function RAllCntrsLegend({
  countries,
  lang,
  isMobile,
  theme,
}: {
  countries: any;
  lang: ELangs;
  isMobile: boolean;
  theme: Theme;
}) {
  const { CircleFill, CircleBound } = iconsStore;
  const isSomeActive = countries.some(
    (cntr: { isActive: boolean }) => cntr.isActive === true
  );
  const isAllActive = !countries.some(
    (cntr: { isActive: boolean }) => cntr.isActive === false
  );

  // ------------
  // handlers
  // ------------
  const onAllCountriesActive =
    (cntrs: Array<any>, active: boolean) =>
    (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();
      const resultArrayNRedux = cntrs.map((cntr: any) => ({
        id: cntr.countryId,
        active: active,
      }));

      window.setTimeout(() => toggleAllCountriesNRedux(resultArrayNRedux), 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <>
      <ButtonBase
        className="RLineLegend_AllCountries_ON"
        sx={_buttonWCStyle(isMobile, theme)}
        onClick={onAllCountriesActive(countries, true)}
      >
        <span style={_buttonIconStyle()}>
          {isAllActive && (
            <CircleBound
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          )}
          {!isAllActive && (
            <CircleFill
              sx={{
                color: "inherit",
              }}
            />
          )}
        </span>
        <Box
          component="span"
          className="RLineLegend_AllCountries_Switch"
          sx={() => _styleButtonCapture(isAllActive, "on")}
        >
          {reference.utils.allCountries.on.name[lang]}
        </Box>
      </ButtonBase>
      <ButtonBase
        className="RLineLegend_AllCountries_OFF"
        sx={_buttonWCStyle(isMobile, theme)}
        onClick={onAllCountriesActive(countries, false)}
      >
        <span style={_buttonIconStyle()}>
          {isSomeActive && (
            <CircleFill
              sx={{
                color: "inherit",
              }}
            />
          )}
          {!isSomeActive && (
            <CircleBound
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          )}
        </span>
        <Box
          component="span"
          className="RLineLegend_AllCountries_Switch"
          sx={() => _styleButtonCapture(isSomeActive, "off")}
        >
          {reference.utils.allCountries.off.name[lang]}
        </Box>
      </ButtonBase>
    </>
  );
}

// ============
// styles
// ============
function _buttonWCStyle(isMobile: boolean, theme: Theme) {
  return {
    // height: "2em",
    gap: "0.3rem",
    justifyContent: "flex-start",
    fontSize: isMobile ? "16px" : "inherit",
    border: isMobile ? `1px solid ${theme.palette.primary.light}` : "none",
    borderRadius: isMobile ? "4px" : "none",
    padding: isMobile ? "3px 9px" : "none",
    "& .MuiSvgIcon-root": {
      width: "0.8em",
      height: "0.8em",
    },
    "& .RLineLegend_AllCountries_Switch": {
      marginBottom: "0.2em",
    },
  };
}

function _styleButtonCapture(isActive: boolean, capture: "on" | "off") {
  if (capture === "off") {
    return {
      color: "inherit",
      opacity: isActive === true ? 1 : 0.7,
    };
  } else {
    return {
      color: "inherit",
      opacity: isActive === true ? 0.7 : 1,
    };
  }
}

function _buttonIconStyle() {
  return {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  };
}
