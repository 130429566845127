// ============
// import
// ============
import React from "react";
import {
  type Theme,
  type SxProps,
  styled,
  type ExtendButtonBase,
  type ButtonBaseTypeMap,
  type SvgIconTypeMap,
} from "@mui/material";
import { type OverridableComponent } from "@mui/types";
import { toggleCountryActiveNRedux } from "../../../../../context/noredux/countries/countriesSlice";
import {
  noReduxSelectors,
  noReduxEStateNames,
} from "../../../../../context/noredux/mainStore";
import { ELangs } from "../../../../../enums/ELangs";
import { type TECountriesVal } from "../../../../../enums/ECountries";
import Stack from "@mui/material/Stack";
import {
  prepareCountries,
  type TPrepareCountry,
} from "../../../../../func/prepare/prepareCountries";
import { iconsStore } from "../../../../../hooks_g/useIcons";
import { RAllCntrsLegend } from "./RAllCntrsLegend";
import ButtonBase from "@mui/material/ButtonBase";
import { busContext } from "../../../../..";
import { busNames } from "../../../../../bus_context/busContextStore";

// ============
// types
// ============
type TComponentProps = {
  lang: ELangs;
  colors: any;
  isDark: boolean;
  marginRight: number;
  toShowBlock?: boolean;
  isMobile: boolean;
  isFullscreenMode: boolean;
};
type TCountry = {
  countryId: TECountriesVal;
  name: string;
  isActive: boolean;
  color: string;
};
type TStyledStack = {
  children: React.ReactNode | Array<React.ReactNode>;
  sx?: SxProps<Theme>;
  className?: string;
} & Record<string, any>;

// ============
// styled
// ============
const StyledStack = styled((props: TStyledStack) => (
  <Stack {...props}>{props.children}</Stack>
))((allProps) =>
  Object.assign(
    {},
    {
      color: "inherit",
      "& .IIIg-noactive": {
        opacity: 0.7,
      },
    },
    {
      ...allProps.sx,
    }
  )
);

// ============
// main
// ============
export function RLineLegend({
  lang,
  marginRight,
  toShowBlock = true,
  isMobile,
  isFullscreenMode,
}: TComponentProps) {
  const theme = busContext.getDataFromBusOne(busNames.theme);
  const cntrs = noReduxSelectors.selectAll(
    noReduxEStateNames.get("countries")
  ) as Array<any>;

  const countries = prepareCountries(lang, cntrs, theme.palette.mode);
  const { CircleBound, CircleFill } = iconsStore;

  // ------------
  // handlers
  // ------------
  const onActiveCountry =
    (country: TCountry) => (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();

      window.setTimeout(() => {
        toggleCountryActiveNRedux({
          id: country.countryId,
          changes: {
            id: country.countryId,
            active: !country.isActive,
          },
        });
      }, 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <StyledStack
      className="RLineLegend"
      direction="row"
      sx={_rlineLegendStyle(
        marginRight,
        isMobile /*, toShowLegend*/,
        theme,
        toShowBlock
      )}
    >
      {!isFullscreenMode && (
        <RAllCntrsLegend
          countries={countries}
          lang={lang}
          isMobile={isMobile}
          theme={theme}
        />
      )}
      {_rowFromCountries({
        countries,
        isFullscreenMode,
        onActiveCountry,
        isMobile,
        theme,
        CircleFill,
        CircleBound,
        ButtonBase,
      })}
    </StyledStack>
  );
}

// ============
// styles
// ============
function _rlineLegendStyle(
  marginRight: number,
  isMobile: boolean,
  theme: Theme,
  toShowBlock: boolean = true
) {
  return {
    width: `calc(100% - ${marginRight}px)`,
    marginTop: isMobile ? "1em" : 0,
    marginBottom: isMobile ? "2em" : "0.2em",
    paddingBottom: isMobile ? "4em" : "initial",
    display: toShowBlock ? "grid" : "none",
    gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(auto-fill, 9em)",
    columnGap: "1rem",
    rowGap: isMobile ? "1em" : "0",
    justifyContent: "center",
    [`& .MuiSvgIcon-root`]: {
      width: "0.9rem",
      height: "0.9rem",
    },
    [`& *`]: {
      transitionDuration: "500ms",
    },
    color: theme.palette.text.secondary,
  };
} //<

//>
function _buttonBaseStyle(isMobile: boolean, theme: Theme) {
  return {
    justifyContent: "flex-start",
    fontSize: isMobile ? "16px" : "inherit",
    border: isMobile ? `1px solid ${theme.palette.primary.light}` : "none",
    borderRadius: isMobile ? "4px" : "none",
    padding: isMobile ? "3px 9px" : "none",
  };
} //<

//>
function _buttonIconStyle() {
  return {
    paddingLeft: "0.01rem",
    paddingRight: "0.01rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginRight: "0.3rem",
  };
} //<

//>
function _countryStyle() {
  return {
    marginBottom: "0.3em",
    color: "inherit",
  };
} //<

// ============
// utils
// ============
function _rowFromCountries({
  countries,
  isFullscreenMode,
  onActiveCountry,
  isMobile,
  theme,
  CircleFill,
  CircleBound,
  ButtonBase,
}: {
  countries: TPrepareCountry[];
  isFullscreenMode: boolean;
  onActiveCountry(
    country: TPrepareCountry
  ): (evnt: React.MouseEvent<HTMLButtonElement>) => void;
  isMobile: boolean;
  theme: Theme;
  CircleFill: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  CircleBound: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  ButtonBase: ExtendButtonBase<ButtonBaseTypeMap<{}, "button">>;
}) {
  if (!isFullscreenMode) {
    return countries.map((country) => (
      <ButtonBase
        key={country.countryId}
        className="RLineLegend_ButtonBase"
        onClick={onActiveCountry(country)}
        sx={_buttonBaseStyle(isMobile, theme)}
      >
        <span style={_buttonIconStyle()}>
          {country.isActive ? (
            <CircleFill sx={{ color: country.color }} />
          ) : (
            <CircleBound />
          )}
        </span>
        <span
          style={_countryStyle()}
          className={country.isActive ? "" : "IIIg-NoActive"}
        >
          {country.name}
        </span>
      </ButtonBase>
    ));
  } else {
    return countries.reduce<React.ReactNode[]>((resultArray, country) => {
      if (country.isActive) {
        resultArray.push(
          <ButtonBase
            key={country.countryId}
            className="RLineLegend_ButtonBase"
            // onClick={onActiveCountry(country)}
            sx={_buttonBaseStyle(isMobile, theme)}
          >
            <span style={_buttonIconStyle()}>
              {country.isActive ? (
                <CircleFill sx={{ color: country.color }} />
              ) : (
                <CircleBound />
              )}
            </span>
            <span
              style={_countryStyle()}
              className={country.isActive ? "" : "IIIg-NoActive"}
            >
              {country.name}
            </span>
          </ButtonBase>
        );
      }
      return resultArray;
    }, []);
  }
}
