// ============
// import
// ============
import { type TMinState } from "../mainStore";
// import { EStatus, type TEStatus } from "../../../enums/EStatus";

// ============
// main
// ============
export const updateOne = <U, T extends { id: string; changes: U }>(
  state: TMinState,
  action: T
  // status?: TEStatus
): TMinState => {
  try {
    const stateCopy = Object.assign({}, state.entities);
    stateCopy[action.id] = action.changes;
    return { ids: state.ids, entities: stateCopy, indicatorID: "" };
  } catch (error) {
    console.info("%cnoredux/handlers/updateOne: ", "color: red", error);
    throw new Error();
  }
};
