// =======
// import
// =======
import { type Theme } from "@mui/material/styles";
import { toggleAllCountriesNRedux } from "../../../../../context/noredux/countries/countriesSlice";
import { ELangs } from "../../../../../enums/ELangs";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import reference from "../../../../../reference.json";
import { iconsStore } from "../../../../../hooks_g/useIcons";

// ============
// constants
// ============
const SVG_WIDTH_HEIGHT = "0.6em";

// =======
// main
// =======
export function RAllCntrsLegendV({
  countries,
  lang,
  isMobile,
  theme,
}: {
  countries: any;
  lang: ELangs;
  isMobile: boolean;
  theme: Theme;
}) {
  const isSomeActive = countries.some(
    (cntr: { isActive: boolean }) => cntr.isActive === true
  );
  const isAllActive = !countries.some(
    (cntr: { isActive: boolean }) => cntr.isActive === false
  );

  const { CircleBound, CircleFill } = iconsStore;

  // ------------
  // handlers
  // ------------
  const onAllCountriesActive =
    (cntrs: Array<any>, active: boolean) =>
    (evnt: React.MouseEvent<HTMLButtonElement>) => {
      evnt.stopPropagation();
      const resultArrayNRedux = cntrs.map((cntr: any) => ({
        id: cntr.countryId,
        active: active,
      }));

      window.setTimeout(() => toggleAllCountriesNRedux(resultArrayNRedux), 0);
    };

  // ------------
  // rendering
  // ------------
  return (
    <>
      <ButtonBase
        className="RLineLegendV_AllCountries_ON"
        sx={_buttonWCStyle(isMobile, theme, "on", isAllActive)}
        onClick={onAllCountriesActive(countries, true)}
      >
        <span style={_buttonIconStyle()}>
          {!isAllActive ? (
            <CircleFill
              sx={{
                color: "inherit",
                width: SVG_WIDTH_HEIGHT,
                height: SVG_WIDTH_HEIGHT,
              }}
            />
          ) : (
            <CircleBound
              sx={{ width: SVG_WIDTH_HEIGHT, height: SVG_WIDTH_HEIGHT }}
            />
          )}
        </span>

        <Box
          component="span"
          className="RLineLegend_AllCountries_Switch"
          sx={() => _styleButtonCapture(/*isAllActive, "on"*/)}
        >
          {reference.utils.allCountries.on.name[lang].toUpperCase()}
        </Box>
      </ButtonBase>
      <ButtonBase
        className="RLineLegendV_AllCountries_OFF"
        sx={_buttonWCStyle(isMobile, theme, "off", isSomeActive)}
        onClick={onAllCountriesActive(countries, false)}
      >
        <span style={_buttonIconStyle()}>
          {isSomeActive ? (
            <CircleFill
              sx={{
                color: "inherit",
                width: SVG_WIDTH_HEIGHT,
                height: SVG_WIDTH_HEIGHT,
              }}
            />
          ) : (
            <CircleBound
              sx={{ width: SVG_WIDTH_HEIGHT, height: SVG_WIDTH_HEIGHT }}
            />
          )}
        </span>

        <Box
          component="span"
          className="RLineLegend_AllCountries_Switch"
          sx={() => _styleButtonCapture(/*isSomeActive, "off"*/)}
        >
          {reference.utils.allCountries.off.name[lang].toUpperCase()}
        </Box>
      </ButtonBase>
    </>
  );
}

// ============
// styles
// ============
function _buttonWCStyle(
  isMobile: boolean,
  theme: Theme,
  what: "on" | "off",
  mode: boolean
) {
  const isActive =
    what === "on"
      ? mode === true
        ? false
        : true
      : mode === true
      ? true
      : false;

  // ---------
  // CSSObject
  // ---------
  return {
    // gap: "0.3rem",
    justifyContent: "flex-start",
    fontSize: isMobile ? "16px" : "0.75em",
    border: isMobile ? `1px solid ${theme.palette.primary.light}` : "none",
    borderRadius: isMobile ? "4px" : "unset",
    padding: isMobile ? "3px 9px" : "unset",

    "& .MuiSvgIcon-root": {
      // width: "0.8em",
      width: SVG_WIDTH_HEIGHT,
      // height: "0.8em",
      height: SVG_WIDTH_HEIGHT,
    },

    "& .RLineLegend_AllCountries_Switch": {},

    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "inherit",
    opacity:
      what === "on" ? (mode === true ? 0.5 : 1) : mode === true ? 1 : 0.5,

    "&:hover": {
      color: isActive ? theme.palette.text.disabled : "inherit",
    },
  };
}

function _styleButtonCapture(/*capture: "on" | "off"*/) {
  return {
    color: "inherit",
    paddingBottom: "0.1em",
    // fontVariant: "all-small-caps",
  };
}

function _buttonIconStyle() {
  return {
    paddingLeft: "0.01rem",
    paddingRight: "0.01rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginRight: "0.2em",
    // marginRight: "0.5em",
  };
}
