// ============
// import
// ============
import { store, type TRootState, type TMinState } from "../mainStore";
import { addMany } from "../handlers/addMany";
import { deleteOne } from "../handlers/deleteOne";
import { TEWhatVal } from "../../../enums/EWhat";
import { EStatus } from "../../../enums/EStatus";
import { genericFetchData } from "../handlers/generiFetchData";
import { prepareData } from "../../../func/prepare/prepareData";

// ============
// types
// ============
type TData = {
  id: string;
  data: Array<{ x: string; y: number }>;
  minX: null;
  maxX: null;
  minY: null;
  maxY: null;
  stepX: null;
  stepY: null;
};
type TGenericSlice = {
  generics: {
    actions: Record<string, Function> | null;
    extraActions: Record<string, Function> | null;
  };
};

// ============
// constants
// ============
const TIMER_DELAY = 1000 * 60 * 5; // ms * sec * min

// ============
// utils
// ============
const sortingAlgo = (a: string, b: string) => a.localeCompare(b);
const targetsTimerObj: Record<string, any> = {};

/** */
const targetsTimerRun = (name: string, state: TRootState) => {
  const timerDescriptor = window.setTimeout(() => {
    deleteOne(state, name);
  }, TIMER_DELAY);
  targetsTimerObj[name] = timerDescriptor;
}; // targetsTimerRun

// ============
// main
// ============
export const genericSlice: TGenericSlice = {
  generics: { actions: {}, extraActions: {} },
};

export function genericSliceFactory(target: TEWhatVal) {
  const len = store._state[target] && store._state[target]?.ids.length;

  if (len === undefined || len < 1) {
    // ---------
    // slice
    // ---------
    genericSlice.generics = store.createSlice({
      name: target,
      initialState: {
        ids: [] as Array<string>,
        entities: {} as Record<string, TData>,
        status: EStatus.IDLE,
      } as TMinState,
      // reducers(self) {
      //   return {
      //     addManyCountriesNRedux(state, dispatch) {
      //       if (state[self.name] === undefined) {
      //         state[self.name] = self.initialState;
      //       }
      //       return (action) => {
      //         dispatch(
      //           self.name,
      //           addMany(state[self.name], action, undefined, sortingAlgo)
      //         );
      //       };
      //     },
      //   };
      // },
      extraReducers(self) {
        return {
          [`fetchGeneric_${self.name}`](state, dispatch) {
            if (state[self.name] === undefined) {
              state[self.name] = self.initialState;
            }

            return async () => {
              const copyStateSelfName = Object.assign({}, state[self.name]);
              if (copyStateSelfName?.ids.length === 0) {
                // status 'loading'
                dispatch(
                  self.name,
                  Object.assign({}, state[self.name], {
                    status: EStatus.PENDING,
                  })
                );

                // fetching
                const { countries, data } = await genericFetchData(
                  state,
                  self.name
                );

                // adding data to the 'state'
                if (data) {
                  const result = countries.map((country, inx) =>
                    prepareData(country, data[inx])
                  );

                  const indicatorID = data[0][0].indicator.id;

                  dispatch(
                    self.name,
                    addMany(
                      copyStateSelfName,
                      result,
                      indicatorID,
                      EStatus.SUCCEEDED,
                      sortingAlgo
                    )
                  );
                  // Запускаем таймер времени хранения данных
                  targetsTimerRun(self.name, /*dispatch,*/ state);
                } else {
                  console.info(
                    "%cnoredux/generic/genericSlice: 'data' === undefined",
                    "color: red"
                  );
                }
              }
            };
          },
        };
      },
    });
  } else {
    // Обнуляем таймер + запускаем таймер заново
    if (targetsTimerObj[target]) {
      window.clearTimeout(targetsTimerObj[target].timer);
      delete targetsTimerObj[target];
      const newTimerDescriptor = window.setTimeout(() => {
        deleteOne(store.getState(), target);
      }, TIMER_DELAY);
      targetsTimerObj[target] = newTimerDescriptor;
    }
  }
}

// ============
// reducer
// ============

// ============
// actions
// ============
