// =========
// import
// =========
import * as bm from "./barrelModule";

// =========
// types
// =========
type TComponentProps = {
  children?: bm.React.ReactElement | Array<bm.React.ReactElement>;
  value: number;
  onChange?: (_event: bm.React.SyntheticEvent, newValue: number) => void;
  "aria-label": string;
  className?: string;
  textColor?: "inherit" | "primary" | "secondary";
  sx?: bm.SxProps<bm.Theme>;
} & Record<string, any>;

// =========
// main
// =========
export const RStyledHorizontalTabs = bm.styled(
  (props: TComponentProps) => <bm.Tabs {...props}>{props.children}</bm.Tabs>,
  {
    name: "RStyledHorizontalTabs",
    slot: "Root",
  }
)((allProps) => {
  return Object.assign(
    {},
    {
      overflow: "hidden",
      color: "inherit",
      "& .MuiTab-root.Mui-selected": {
        opacity: 0.7,
      },
      "& .MuiTab-root": {
        opacity: 1,
      },
      [`& .MuiTabs-indicator`]: {
        // backgroundColor: allProps.theme.palette.primary.main,
        backgroundColor: allProps.theme.palette.primary.main,
        height: "3px",
      },
    },
    {
      ...allProps.sx,
    }
  );
});
