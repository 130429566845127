// =========
// import
// =========
import * as React from "react";
import * as bm from "./barrelModule";
import { webKitScrollbarFn } from "./WebKitScrollbar";
import { styled } from "@mui/material";

// =========
// types
// =========
type TComponentProps = {
  children: bm.React.ReactElement | Array<bm.React.ReactElement>;
  name?: string;
  slot?: string;
  sx?: bm.SxProps<bm.Theme>;
  className?: string;
  isStart: boolean;
  isMobile: boolean;
  isTablet: boolean;
} & Record<string, any>;

// https://stackoverflow.com/a/54677641/8529067
// type TDivProps = React.HTMLProps<HTMLDivElement>;

// =========
// pre
// =========
const PreH = React.forwardRef(function PreH(inProps, ref) {
  return <bm.Box {...inProps} ref={ref} />;
});

// =========
// main
// =========
export const RStyledHorizontalTabsWrap = styled(PreH, {
  name: "RStyledHorizontalTabsWrap",
  slot: "Root",
  shouldForwardProp: (prop) =>
    prop !== "isStart" && prop !== "isMobile" && prop !== "isTablet",
})<TComponentProps>((allProps) => {
  const isDark = allProps.theme.palette.mode === "dark";

  // -------
  // pre-rendering
  // -------
  const scrollbarFnMemo = React.useMemo(
    () => webKitScrollbarFn(allProps.theme),
    []
  );

  const returnMemo = React.useMemo(
    () =>
      Object.assign(
        {},
        {
          display: "flex",
          flexDirection: "column" as const,
          color: "inherit",
          width: "100%",
          // marginTop: allProps.isStart ? "1em" : "unset",
          marginTop: allProps.isStart ? "0" : "unset",
          paddingTop: allProps.isStart ? "unset" : "1em",
          backgroundColor: allProps.theme.palette.background.paper,
          boxShadow: isDark
            ? allProps.theme.shadows[5]
            : allProps.theme.shadows[1],
          paddingBottom: !allProps.isMobile ? "32px" : 0,
          marginBottom: 0,
          overflowY: "hidden" as const,
          ...scrollbarFnMemo,
          height: "100%",
        },
        {
          ...allProps.sx,
        }
      ),
    // eslint-disable-next-line
    [
      allProps.isStart,
      isDark,
      allProps.isMobile,
      allProps.isTablet,
      allProps.sx,
    ]
  );

  // -------
  // rendering
  // -------
  return returnMemo;
});
