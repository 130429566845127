// =============
// Import
// =============
// import { colorsKit } from "./colorsKit";
import { fonts } from "./fonts";
import CL01 from "./colorsPalettes/light/colors_light_01.json";
import CD01 from "./colorsPalettes/dark/colors_dark_02.json";

// =============
// colors object for tests
// =============
const colorsObj = {
  light: {
    CL01,
  },
  dark: {
    CD01,
  },
};

// =============
// constants
// =============
const CURRENT_LIGHT_COLORS = "01";
const CURRENT_DARK_COLORS = "01";

// =============
// themeSettings
// =============
export function themeSettings(mode: "dark" | "light") {
  // Light Colors
  const CL = colorsObj.light[`CL${CURRENT_LIGHT_COLORS}`];
  // Dark Colors
  const CD: Record<string, any> = colorsObj.dark[`CD${CURRENT_DARK_COLORS}`];

  return {
    palette: {
      mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: CD.primary.main,
              contrastText: CD.primary.contrastText,
              light: CD.primary.light,
              dark: CD.primary.dark,
            },
            secondary: {
              main: CD.secondary.main,
              light: "",
            },
            background: {
              default: CD.background.default,
              paper: CD.background.paper,
            },
            text: {
              primary: CD.text.primary,
              secondary: CD.text.secondary,
              disabled: CD.text.disabled,
              icons: CD.text.icons,
            },
            divider: CD.divider,
          }
        : {
            primary: {
              main: CL.primary.main,
              contrastText: CL.primary.contrastText,
            },
            secondary: {
              main: CL.secondary.main,
            },
            text: {
              primary: CL.text.primary,
              secondary: CL.text.secondary,
              disabled: CL.text.disabled,
              icons: CL.text.icons,
            },
            divider: CL.divider,
            background: {
              paper: CL.background.paper,
            },
          }),
    },
    typography: fonts,
    overlay: {
      color: mode === "dark" ? CD.overlay : CL.overlay,
      kefs: {
        k0: 0,
        k5: 0.05,
        k7: 0.07,
        k8: 0.08,
        k9: 0.09,
        k11: 0.11,
        k12: 0.12,
        k14: 0.14,
        k15: 0.15,
        k16: 0.16,
      },
    },
  };
}

// =============
// types
// =============
export type TThemeSettings = ReturnType<typeof themeSettings>;
export type TPalette = TThemeSettings["palette"];
