// =============
// import
// =============
import { TECountriesVal } from "../../enums/ECountries";
import { TRline } from "../../context/noredux/noreduxTypes";

// -------------
// prepareData
// -------------
export function prepareData(country: TECountriesVal, data: any) {
  const firstPocket = data.slice().reverse();

  const resultData = firstPocket.map((obj: any) => {
    const x = String(obj.date);
    const y = obj.value === null ? null : Number(obj.value);
    return { x, y };
  });

  const mms = _minMaxStep(resultData);

  const resultObj = {
    id: country,
    data: resultData,
    minX: mms.minX,
    maxX: mms.maxX,
    stepX: mms.stepX,
    minY: mms.minY,
    maxY: mms.maxY,
    stepY: mms.stepY,
  } as TRline;

  return resultObj;
}

// -------------
// _minMaxStep
// -------------
function _minMaxStep(data: Array<any>) {
  const { minX, maxX, stepX } = _minMaxX(data);
  const { minY, maxY, stepY } = _minMaxY(data);
  return {
    minX,
    maxX,
    stepX,
    minY,
    maxY,
    stepY,
  };
}

// -------------
// _minMaxX
// -------------
function _minMaxX(data: Array<any>) {
  return {
    minX: data[0].x,
    maxX: data[data.length - 1].x,
    stepX: _step(data, "x"),
  };
}

// -------------
// _minMaxY
// -------------
function _minMaxY(data: Array<any>) {
  const sortData = [...data].sort((a, b) => a.y - b.y);
  return {
    minY: Math.floor(sortData[0].y),
    maxY: Math.ceil(sortData[sortData.length - 1].y),
    stepY: _step(data, "y"),
  };
}

// -------------
// _step
// -------------
function _step(data: Array<any>, axis: "x" | "y") {
  let elem_1 = data[0][axis];
  let elem_2 = data[1][axis];

  if (typeof elem_1 === "string") {
    elem_1 = Number.parseFloat(data[0][axis]);
    elem_2 = Number.parseFloat(data[1][axis]);
  }

  return Math.ceil(elem_2) - Math.floor(elem_1);
}
