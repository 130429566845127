// The example code is from here:
// https://mui.com/material-ui/react-table/#sorting-amp-selecting
// =============
// import
// =============
import * as React from "react";
import * as bm from "../barrelModule";

// =============
// types
// =============
export type TOrder = "asc" | "desc";

type TEnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: TOrder;
  orderBy: string;
  xArrayTitle: { id: string };
  titleIDs: Array<{ id: bm.TECountriesVal }>;
  axisTitles: { x: string; y: string };
  lang: bm.ELangs;
  // cellWidth: string;
  // rowHeadWidth: string;
};

// =============
// main
// =============
export function REnhancedTableHead(props: TEnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    xArrayTitle,
    titleIDs,
    axisTitles,
    lang,
    // cellWidth,
    // rowHeadWidth,
  } = props;

  const headerTitles = [xArrayTitle, ...titleIDs];
  const initHeaderNames = [axisTitles.x];

  const preparedCountries = bm.prepareCountries(lang, titleIDs);
  const headerNames = preparedCountries.reduce((resultArray, nextCountry) => {
    return [...resultArray, nextCountry.name];
  }, initHeaderNames);

  // ------------
  // handlers
  // ------------
  const createSortHandler = bm.useCallback(
    (property: string | bm.TECountriesVal) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      },
    [order, orderBy]
  );

  // ------------
  // rendering
  // ------------
  return (
    <bm.TableHead>
      <bm.TableRow>
        {headerTitles.map((headerTitle, index) => (
          <bm.TableCell
            key={headerTitle.id}
            align={index === 0 ? "left" : "right"}
            padding="normal"
            sortDirection={orderBy === headerTitle.id ? order : false}
            // sx={{ width: index === 0 ? rowHeadWidth : cellWidth }}
          >
            <bm.TableSortLabel
              active={orderBy === headerTitle.id}
              direction={orderBy === headerTitle.id ? order : "asc"}
              onClick={createSortHandler(headerTitle.id)}
            >
              {headerNames[index]}
              {orderBy === headerTitle.id ? (
                <bm.Box component="span" sx={bm.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </bm.Box>
              ) : null}
            </bm.TableSortLabel>
          </bm.TableCell>
        ))}
      </bm.TableRow>
    </bm.TableHead>
  );
}
