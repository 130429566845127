// ===========
// import
// ===========
import { styled } from "@mui/material/styles";
import { webKitScrollbarFn2Str } from "./WebKitScrollbar";

// ===========
// types
// ===========
type TComponentProps = {
  gridTemplateColumns: string;
  columnGap: string;
  isTablet: boolean;
  isScroll?: boolean;
  rowGap?: string;
  height?: string;
  maxHeight?: string;
  isFirefox: boolean;
  isMobile?: boolean;
};

/*
      overflow-y: ${
        allProps.isTablet
          ? "auto"
          : allProps.isScroll
          ? !allProps.isMobile
            ? "auto"
            : "hidden"
          : "hidden"
      };
*/

// ===========
// main
// ===========
export const RStyledGridContent = styled("section")<TComponentProps>(
  (allProps) => {
    return `
      display: grid;
      grid-template-columns: ${allProps.gridTemplateColumns};
      column-gap: ${allProps.columnGap};
      row-gap: ${allProps.rowGap};
      height: ${allProps.isTablet ? allProps.height : "unset"};
      max-height: ${allProps.maxHeight};
      padding-bottom: ${!allProps.isTablet ? "unset" : "3em"};
      overflow-y: ${
        allProps.isTablet
          ? "scroll"
          : allProps.isScroll
          ? !allProps.isMobile
            ? "scroll"
            : "hidden"
          : "hidden"
      };
      ${webKitScrollbarFn2Str(
        allProps.theme,
        allProps.theme.palette.mode === "dark",
        allProps.isFirefox
      )}

      & .FlexStyledContainer.RegionWrapper:last-of-type {
        padding-bottom: ${allProps.isMobile ? "5em" : "unset"}
      }
    `;
  }
);
