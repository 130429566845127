// =============
// import
// =============
import { RTargetsNavTabs } from "../targetsbar/RTargetsNavTabs";
import reference from "../../../reference.json";
import * as React from "react";
import { type TCountries } from "../../../context/noredux/noreduxTypes";
import {
  genericSliceFactory,
  genericSlice,
} from "../../../context/noredux/genericData/genericSlice";
import {
  noReduxSelectors,
  noReduxEStateNames,
} from "../../../context/noredux/mainStore";
import { RLegendH } from "./legend/RLegendH";
import { RLegendV } from "./legend/RLegendV";
import { type TEWhatVal } from "../../../enums/EWhat";
import { type ELangs } from "../../../enums/ELangs";
import config from "../../../config.json";
import Box from "@mui/material/Box";
import { RBasicTabs } from "./tabs/RBasicTabs";
import { type Theme } from "@mui/material";
import { busContext, chartWorker } from "../../..";
import { noReduxUpdater } from "../../../context/noredux/mainStore";
import { busNames } from "../../../bus_context/busContextStore";
import { type TThemeSettings } from "../../../theming/themeSettings";
import changeUrl from "../../../func/url/changeUrl";

// =============
// types
// =============
type TComponentProps = {
  target: TEWhatVal;
  lang: ELangs;
  theme: TThemeSettings & Theme;
  isTablet: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  colorMode: "dark" | "light";
  isFullscreenMode: boolean;
  fnOpenInFullscreen(): void;
  isReady: boolean;
};
export type TThemeColors = Record<"colors" | "theme", Record<string, any>>;

// =============
// main
// =============
export function RMainChartWrapper(props: TComponentProps) {
  const fileID = "RMainChartWrapper";

  // ---------
  // local state
  // ---------
  const [isChangedFlagObj, setIsChangedFlag] = React.useState({ flag: true });
  const [isRangeTuppleChanged, setIsRangeTuppleChanged] = React.useState(false);
  const [toShowLegend, setToShowLegend] = React.useState(true);
  const [, setUpdater] = React.useState({});

  // const toShowLegend = props.isDesktop || props.isTablet ? true : false;

  const themeColors = props.theme.palette;

  const marginRight = !props.isMobile
    ? Number(config.layout.charts.line.margin.right.md)
    : Number(config.layout.charts.line.margin.right.xs);

  /*noredux_start***********/
  // to make a 'slice' for the target
  genericSliceFactory(props.target);
  const dataNoRedux = noReduxSelectors.selectAll(props.target);

  // to fetch 'countries' from 'noredux'
  const countriesDic = noReduxSelectors.selectEntities(
    noReduxEStateNames.get("countries")
  );

  const dataRedux = dataNoRedux === undefined ? undefined : dataNoRedux;
  /*noredux_end*************/

  // -----------
  // range tupple
  // -----------
  const initRangeTupple = busContext.getDataFromBusOne(
    busNames.yearsRangeTupple
  );
  const rangeTuppleRef = React.useRef<[number, number]>(initRangeTupple);
  const setRangeTupple = React.useCallback(
    (newRangeTupple: [number, number]) => {
      rangeTuppleRef.current[0] = newRangeTupple[0];
      rangeTuppleRef.current[1] = newRangeTupple[1];
    },
    []
  );
  const rangeTupple = rangeTuppleRef.current;

  // if (rangeTupple[0] === 0 || rangeTupple[1] === 0) {
  //   setRangeTupple(initRangeTupple);
  // }

  // -----------
  // fixed tupple
  // -----------
  const fixedTuppleRef = React.useRef<[number, number]>(
    [] as unknown as [number, number]
  );
  const setFixedTupple = (newFixedTupple: [number, number]) => {
    fixedTuppleRef.current[0] = newFixedTupple[0];
    fixedTuppleRef.current[1] = newFixedTupple[1];
  };
  const fixedTupple = fixedTuppleRef.current;

  // -----------
  // local store
  // -----------
  const localStore = React.useRef({
    isMinMaxChanged: false,
    isCountryStatusChanged: false,
    lineMM: { minY: null, maxY: null, minX: null, maxX: null, stepX: null } as {
      minY: number | null;
      maxY: number | null;
      minX: number | null;
      maxX: number | null;
      stepX: number | null;
    },
    oldData: "",
    oldTarget: "",
    workerStatus: "idle" as "idle" | "running" | "succeeded" | "failed",
    data: null as null | Array<{
      id: string;
      maxX: string;
      maxY: number;
      minX: string;
      minY: number;
      stepX: number;
      stepY: number;
    }>,
    xArray: null as Array<string> | null,
    xRange: null as Array<string> | null,
    axisTitles: null as null | { x: string; y: string },
    transactionInAction: false as boolean,
    oldLang: "" as ELangs,
    numCntrs: 0,
    oldLegendMemoHeight: 0,
    targetFloatPrecision: 0,
    isCompressedLines: false,
    oldIsComressedLines: false,
    oldToShowLegend: true,
    isXRangeFixedMin: false,
    isXRangeFixedMax: false,
    currentTabNumber: busContext.getDataFromBusOne(busNames.tabNumber),
  });

  // ----------
  // countries IDs
  // ----------
  const newData = Object.values(countriesDic)
    .reduce((result, element) => {
      if ((element as TCountries).active === true) {
        result.push((element as TCountries).id);
      }
      return result;
    }, [] as Array<string>)
    .join("-");

  // data changing status
  if (
    newData !== localStore.current.oldData ||
    props.target !== localStore.current.oldTarget ||
    isRangeTuppleChanged
  ) {
    localStore.current.transactionInAction = true;
  }

  // pre-to-worker
  let rangeXMax: number | null = null;
  let rangeXMin: number | null = null;

  // if (props.target === localStore.current.oldTarget) {
  //   if (rangeTupple[1]) {
  //     rangeXMax = rangeTupple[1];
  //   } else {
  //     rangeXMax = null;
  //   }

  //   if (rangeTupple[0]) {
  //     rangeXMin = rangeTupple[0];
  //   } else {
  //     rangeXMin = null;
  //   }
  // } else {
  //   if (
  //     localStore.current.isXRangeFixedMax ||
  //     localStore.current.isXRangeFixedMin
  //   ) {
  //     if (fixedTupple[1]) {
  //       rangeXMax = fixedTupple[1];
  //     } else {
  //       rangeXMax = rangeTupple[1] ? rangeTupple[1] : null;
  //     }
  //     if (fixedTupple[0]) {
  //       rangeXMin = fixedTupple[0];
  //     } else {
  //       rangeXMin = rangeTupple[0] ? rangeTupple[0] : null;
  //     }
  //   } else {
  //     rangeXMax = rangeTupple[1] ? rangeTupple[1] : null;
  //     rangeXMin = rangeTupple[0] ? rangeTupple[0] : null;
  //   }
  // }

  if (
    localStore.current.isXRangeFixedMax ||
    localStore.current.isXRangeFixedMin
  ) {
    if (fixedTupple[1]) {
      rangeXMax = fixedTupple[1];
    } else {
      rangeXMax = rangeTupple[1] ? rangeTupple[1] : null;
    }
    if (fixedTupple[0]) {
      rangeXMin = fixedTupple[0];
    } else {
      rangeXMin = rangeTupple[0] ? rangeTupple[0] : null;
    }
  } else if (props.target === localStore.current.oldTarget) {
    if (rangeTupple[1]) {
      rangeXMax = rangeTupple[1];
    } else {
      rangeXMax = null;
    }

    if (rangeTupple[0]) {
      rangeXMin = rangeTupple[0];
    } else {
      rangeXMin = null;
    }
  } else {
    rangeXMax = rangeTupple[1] ? rangeTupple[1] : null;
    rangeXMin = rangeTupple[0] ? rangeTupple[0] : null;
  }

  // ----------
  // utility fn
  // ----------
  const fnSetCurrentTabName = React.useCallback(
    (isShowLegend: boolean, currentTabNumber: number) => {
      if (isShowLegend !== localStore.current.oldToShowLegend) {
        busContext.placeDataToBus(busNames.isAnimation, true, fileID);
        window.setTimeout(() => {
          busContext.placeDataToBus(busNames.isAnimation, false, fileID);
        }, 1500);
      }
      localStore.current.oldToShowLegend = isShowLegend;
      localStore.current.currentTabNumber = currentTabNumber.toString();
      busContext.placeDataToBus(
        busNames.tabNumber,
        Number(currentTabNumber),
        fileID
      );

      // change url.search in the 'address bar'
      changeUrl({
        tab: localStore.current.currentTabNumber,
      });

      setToShowLegend(isShowLegend);
    },
    []
  );

  const fnSetIsFixedXRangeMin = () =>
    (localStore.current.isXRangeFixedMin =
      !localStore.current.isXRangeFixedMin);
  const fnSetIsFixedXRangeMax = () =>
    (localStore.current.isXRangeFixedMax =
      !localStore.current.isXRangeFixedMax);

  //<<

  /**********/
  /* WORKER */
  /**********/
  // ----------
  // to worker
  // ----------
  if (
    window.Worker &&
    localStore.current.transactionInAction === true &&
    localStore.current.workerStatus === "idle" &&
    dataRedux !== undefined
  ) {
    const dataForWorkerObj = {
      message: "chartData",
      payload: {
        target: props.target,
        lang: props.lang,
        reference,
        dataRedux,
        countriesDic,
        oldData: localStore.current.oldData,
        oldTarget: localStore.current.oldTarget,
        rangeXMin,
        rangeXMax,
        isRangeTuppleChanged,
        xRange: localStore.current.xRange,
        xArray: localStore.current.xArray,
        isCompressedLines: localStore.current.isCompressedLines,
        oldIsCompressedLines: localStore.current.oldIsComressedLines,
        isXRangeFixedMax: localStore.current.isXRangeFixedMax,
        isXRangeFixedMin: localStore.current.isXRangeFixedMin,
      },
    };
    localStore.current.workerStatus = "running";
    chartWorker.postMessage(dataForWorkerObj);
    localStore.current.oldIsComressedLines =
      localStore.current.isCompressedLines;
  } else {
    // --------------
    // if lang changed (worker === 'idle')
    // --------------
    if (localStore.current.oldLang !== props.lang) {
      localStore.current.axisTitles = {
        x: reference.targets[props.target].x[props.lang],
        y: reference.targets[props.target].y[props.lang],
      };
    }
  }

  // ----------
  // from worker
  // ----------
  if (window.Worker) {
    chartWorker.onmessage = <
      T extends { message: string; payload: Record<string, any> }
    >(
      evnt: MessageEvent<T>
    ) => {
      const result = evnt.data;

      switch (result.message) {
        case "succeeded": {
          if (process.env.NODE_ENV === "development") {
            // Some 'bag' in Chrome browser
            if (localStore.current.workerStatus === "succeeded") break;
          }

          if (
            result.payload.isDataChanged ||
            result.payload.isTargetChanged ||
            result.payload.isRangeXChanged
          ) {
            (<
              T extends Partial<typeof localStore.current>,
              U extends T & Record<"minMaxY" | "minMaxX", any>,
              K extends keyof T
            >(
              mainObject: T,
              payload: U
            ) => {
              (
                [
                  "oldData",
                  "oldTarget",
                  "data",
                  "xArray",
                  "xRange",
                  "axisTitles",
                  "numCntrs",
                  "targetFloatPrecision",
                ] as Array<K>
              ).forEach((item) => (mainObject[item] = payload[item]));

              let lineMM = {} as typeof mainObject.lineMM;

              ["min", "max"].forEach(
                (item) =>
                  (lineMM = Object.assign({}, lineMM, {
                    [`${item}Y`]: payload.minMaxY[item],
                  }))
              );
              ["min", "max", "step"].forEach(
                (item) =>
                  (lineMM = Object.assign({}, lineMM, {
                    [`${item}X`]: payload.minMaxX[item],
                  }))
              );

              mainObject.lineMM = lineMM;
              if (lineMM && lineMM.minX && lineMM.maxX) {
                setRangeTupple([lineMM?.minX, lineMM?.maxX]);
              }

              localStore.current = Object.assign(
                {},
                localStore.current,
                mainObject
              );
            })({}, result.payload);

            if (result.payload.isRangeXChanged) {
              setIsRangeTuppleChanged(() => {
                return false;
              });
            }

            localStore.current.transactionInAction = false;
          }

          setIsChangedFlag(() => {
            return Object.assign({}, { flag: !isChangedFlagObj.flag });
          });

          if (localStore.current.transactionInAction === false) {
            localStore.current.workerStatus = "idle";
          }

          break;
        }

        default:
          break;
      }
    };
  }

  const minMaxY = {
    minY: localStore.current.lineMM.minY,
    maxY: localStore.current.lineMM.maxY,
  };

  // --------------
  // handlers
  // --------------
  const handleChangedXRange = React.useCallback((rangeX: [number, number]) => {
    if (rangeX[0] !== rangeTupple[0] || rangeX[1] !== rangeTupple[1]) {
      setRangeTupple([rangeX[0], rangeX[1]]);

      // changing 'yearsRangeTupple'
      busContext.placeDataToBus(
        busNames.yearsRangeTupple,
        [rangeX[0], rangeX[1]],
        fileID
      );

      setIsRangeTuppleChanged(() => {
        return true;
      });
    }
    // eslint-disable-next-line
  }, []);

  const toggleIsCompressedLines = React.useCallback(() => {
    localStore.current.isCompressedLines =
      !localStore.current.isCompressedLines;
    localStore.current.transactionInAction = true;
    window.setTimeout(() => {
      setIsChangedFlag(() => {
        return { flag: true };
      });
    }, 0);
  }, []);

  // -------------
  // effects
  // -------------
  React.useEffect(() => noReduxUpdater(setUpdater), []);

  // noredux fetch
  React.useEffect(() => {
    if (!dataNoRedux) {
      genericSlice.generics.extraActions &&
        genericSlice.generics.extraActions[`fetchGeneric_${props.target}`]();
    }
  }, [props.target, dataNoRedux]);

  // -------------
  // Memo
  // -------------
  const isDark = React.useMemo(
    () => (themeColors.mode === "dark" ? true : false),
    [themeColors.mode]
  );

  const TargetsNavBarMemoComponent = React.useMemo(
    () => (
      <RTargetsNavTabs
        toShowLegend={toShowLegend}
        isTargetsNavOnScreen={true}
        lang={props.lang}
      />
    ),
    // eslint-disable-next-line
    [
      props.target,
      themeColors,
      localStore.current.oldLang,
      toShowLegend,
      props.lang,
    ]
  );

  // -------------
  // before rendering
  // -------------
  localStore.current.oldLang = props.lang;
  const area = [rangeXMin, rangeXMax];

  //>
  const rLegendHProps = {
    xArray: localStore.current.xArray,
    axisTitles: localStore.current.axisTitles,
    toShowBlock: toShowLegend,
    themeColors,
    target: props.target,
    xRange: localStore.current.xRange,
    handleChangedXRange,
    rangeTupple,
    isDesktop: props.isDesktop,
    isTablet: props.isTablet,
    isMobile: props.isMobile,
    numCntrs: localStore.current.numCntrs,
    lang: props.lang,
    isDark,
    marginRight,
    fnSetIsFixedXRangeMin,
    fnSetIsFixedXRangeMax,
    setFixedTupple,
    area,
    isReady: props.isReady,
    isFullscreenMode: props.isFullscreenMode,
  }; //<

  //>
  const rBasicTabsProps = {
    data: localStore.current.data === null ? [] : localStore.current.data,
    axisTitles:
      localStore.current.axisTitles === null
        ? { x: "", y: "" }
        : localStore.current.axisTitles,
    lang: props.lang,
    minMaxY: minMaxY,
    xArray: localStore.current.xArray,
    xRange: localStore.current.xRange,
    target: props.target,
    handleChangedXRange: handleChangedXRange,
    rangeTupple: rangeTupple,
    dataID: localStore.current.oldData,
    fnSetCurrentTabName: fnSetCurrentTabName,
    theme: props.theme,
    targetFloatPrecision: localStore.current.targetFloatPrecision,
    isCompressedLines: localStore.current.isCompressedLines,
    toggleIsCompressedLines: toggleIsCompressedLines,
    isTablet: props.isTablet,
    isDesktop: props.isDesktop,
    isMobile: props.isMobile,
    toShowLegend: toShowLegend,
    area,
    fnOpenInFullscreen: props.fnOpenInFullscreen,
    isFullscreenMode: props.isFullscreenMode,
  }; ///

  localStore.current.oldToShowLegend = toShowLegend;

  const renderingMemo = React.useMemo(
    () => {
      return (
        <Box
          className="RMainChartWrapper"
          sx={_wrapperStyle(
            props.isMobile,
            props.isTablet,
            toShowLegend,
            props.isReady,
            props.isFullscreenMode
          )}
        >
          {props.isReady && <RBasicTabs {...rBasicTabsProps} />}
          {props.isDesktop &&
            props.isReady &&
            !props.isFullscreenMode &&
            TargetsNavBarMemoComponent}
          {!props.isFullscreenMode && props.isTablet && (
            <RLegendV {...rLegendHProps} />
          )}
          {props.isReady &&
            (props.isDesktop || props.isMobile || props.isFullscreenMode) && (
              <RLegendH {...rLegendHProps} />
            )}
        </Box>
      );
    },
    // eslint-disable-next-line
    [
      isChangedFlagObj,
      localStore.current.oldLang,
      localStore.current.xRange,
      localStore.current.numCntrs,
      props.isMobile,
      props.isDesktop,
      props.isTablet,
      toShowLegend,
      props.colorMode,
      props.isReady,
      props.isFullscreenMode,
    ]
  );

  // -------------
  // changes the 'address bar'
  // -------------
  changeUrl({
    cntrs: newData,
    tab: localStore.current.currentTabNumber,
    rangeStart: rangeTupple[0].toString(),
    rangeEnd: rangeTupple[1].toString(),
    clmode: busContext.getDataFromBusOne(busNames.colorMode),
  });

  // -------------
  // rendering
  // -------------
  return renderingMemo;
}

// =============
// styles
// =============
function _wrapperStyle(
  isMobile: boolean,
  isTabletMode: boolean = false,
  toShowLegend: boolean,
  isReady: boolean,
  isFullscreenMode: boolean
) {
  const desktopWidth = config.layout.grid.width.desktop;
  const tabletWidth = config.layout.grid.width.tablet;
  const mobileWidth = config.layout.grid.width.mobile;

  let DESKTOP_WIDTH = "";

  if (!isFullscreenMode && isReady) {
    DESKTOP_WIDTH = !isTabletMode
      ? `${desktopWidth["1clmn"]} ${desktopWidth["2clmn"]}`
      : toShowLegend
      ? `${tabletWidth.show_legend["1clmn"]} ${tabletWidth.show_legend["2clmn"]}`
      : `${tabletWidth.no_show_legend["1clmn"]} ${tabletWidth.no_show_legend["2clmn"]}`;
  } else {
    DESKTOP_WIDTH = "100%";
  }

  const MOBILE_WIDTH = `${mobileWidth["1clmn"]}`;

  const tabletHeight = config.layout.grid.height.tablet;
  const mobileHeight = config.layout.grid.height.mobile;

  let DESKTOP_HEIGHT = "";

  if (!isFullscreenMode) {
    DESKTOP_HEIGHT = isTabletMode
      ? `${tabletHeight["1row"]} ${tabletHeight["2row"]}`
      : isMobile
      ? `${mobileHeight["1row"]} ${mobileHeight["2row"]}`
      : "unset";
  } else {
    // DESKTOP_HEIGHT = "80% 20%";
    DESKTOP_HEIGHT = "1fr";
  }

  // -----
  // @media
  // -----
  const mediaQueryBlock = {
    "@media (1080px < height <= 10000px)": {
      gridTemplateRows: toShowLegend
        ? "minmax(0%, 78%) minmax(0%, 22%)"
        : "minmax(0, 100%) minmax(0, 0.0001%)",
    },

    "@media (980px < height <= 1080px)": {
      gridTemplateRows: toShowLegend
        ? "minmax(0%, 74%) minmax(0%, 26%)"
        : "minmax(0, 100%) minmax(0, 0.0001%)",
    },

    "@media (860px < height <= 980px)": {
      gridTemplateRows: toShowLegend
        ? "minmax(0%, 72%) minmax(0%, 28%)"
        : "minmax(0, 100%) minmax(0, 0.0001%)",
    },

    "@media (800px < height <= 860px)": {
      gridTemplateRows: toShowLegend
        ? "minmax(0%, 70%) minmax(0%, 30%)"
        : "minmax(0, 0.0001%)",
    },
  };

  // -----
  // CSSObject
  // -----
  return Object.assign(
    {},
    {
      // visibility: isReady ? "visible" : "hidden",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `${!isMobile ? DESKTOP_WIDTH : MOBILE_WIDTH}`,
      minWidth: 0,
      backgroundColor: "unset",
      overflow: isMobile ? "visible" : "hidden",
      transition: "grid-template 1s 200ms",
      position: "relative",

      gridTemplateRows: !isMobile ? DESKTOP_HEIGHT : "minmax(0, auto)",
    },
    isFullscreenMode ? {} : isMobile || isTabletMode ? {} : mediaQueryBlock
  );
}
