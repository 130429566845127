// =============
// Const
// =============
const FONT_FAMILY = ["Source Sence Pro", "sans-serif"].join(",");

const BASE_FONT_SIZE = 12;

// =============
// fonts
// =============
export const fonts = {
  fontFamily: FONT_FAMILY,
  fontSize: BASE_FONT_SIZE,
  h1: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 3.33),
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 2.66),
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 2),
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 1.66),
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 1.33),
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontSize: Math.ceil(BASE_FONT_SIZE * 1.16),
  },
};
