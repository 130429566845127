import Box from "@mui/material/Box";
// import { type Theme } from "@mui/material/styles";
import { RHeader } from "./page_header/RHeader";
import { TEWhatVal } from "../../enums/EWhat";
import { ELangs } from "../../enums/ELangs";
import type { TColors } from "../../types/main-types";
import { RMainChartWrapper } from "./charts/RMainChartWrapper";
import { webKitScrollbarFn } from "../../styled/WebKitScrollbar";
import * as React from "react";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { type TThemeSettings } from "../../theming/themeSettings";
import prepareBGImageColors from "../../func/prepare/prepareBGImageColors";

// =============
// types
// =============
export type TPageUtils = {
  colors: TColors;
  lang: ELangs;
};
type TComponentProps = {
  what: TEWhatVal;
};

// =============
// main
// =============
export function RMainContent({ what }: TComponentProps) {
  // ------
  // bus
  // ------
  const [, setTrigg] = React.useState({});
  const fileID = "RMainContent";
  const { lang, isMobile, isTablet, isDesktop, colorMode, isFullscreenMode } =
    busContext.getDataFromBus(
      [
        busNames.lang,
        busNames.isMobile,
        busNames.isTablet,
        busNames.isDesktop,
        busNames.colorMode,
        busNames.isFullscreenMode,
      ],
      fileID,
      setTrigg
    );

  const theme = busContext.getDataFromBusOne(busNames.theme);
  //<

  // -------------
  // local state
  // -------------
  const [isReady, setIsReady] = React.useState(true);

  // -------------
  // ref
  // -------------
  const mainContentRef = React.useRef<HTMLDivElement>(null);
  const fullscreenSwitcherRef = React.useRef({ switch: false });

  // -------------
  // handlers
  // -------------
  const fnOpenInFullscreen = function () {
    if (mainContentRef.current) {
      setIsReady(false);

      if (!isFullscreenMode) {
        busContext.placeDataToBus(busNames.isFullscreenMode, true, fileID);
        mainContentRef.current.requestFullscreen().then(() => setIsReady(true));
      } else {
        busContext.placeDataToBus(busNames.isFullscreenMode, false, fileID);
        document.exitFullscreen().then(() => setIsReady(true));
      }
    }
  }; //<

  //>
  const fnSwitchOffFullScreen = () => {
    if (!document.fullscreenElement) {
      setIsReady(false);

      busContext.placeDataToBus(busNames.isFullscreenMode, false, fileID);
      fullscreenSwitcherRef.current.switch =
        !fullscreenSwitcherRef.current.switch;
    }
  }; //<

  const keydownEventHandler = (evnt: React.KeyboardEvent<HTMLDivElement>) => {
    if (evnt.key === "Escape") {
      if (busContext.getDataFromBusOne(busNames.isFullscreenMode)) {
        busContext.placeDataToBus(busNames.isFullscreenMode, false, fileID);
      }
    }
  };

  // -------------
  // effects
  // -------------
  React.useEffect(() => {
    const elm = mainContentRef.current;
    elm?.addEventListener("fullscreenchange", fnSwitchOffFullScreen);

    return () =>
      elm?.removeEventListener("fullscreenchange", fnSwitchOffFullScreen);
  }, []);

  React.useEffect(() => {
    setIsReady(true);
  }, [fullscreenSwitcherRef.current.switch]);

  // -------------
  // rendering
  // -------------
  return (
    <Box
      sx={_boxStyles(theme, isMobile, isFullscreenMode)}
      component={"div"}
      className="RMainContent"
      ref={mainContentRef}
      onKeyDown={keydownEventHandler}
    >
      {isReady && (
        <RHeader
          title={what.toLowerCase()}
          lang={lang}
          theme={theme}
          isTablet={isTablet}
          isMobile={isMobile}
          isDesktop={isDesktop}
          isFullscreenMode={isFullscreenMode}
          isReady={isReady}
        />
      )}

      {isReady && (
        <RMainChartWrapper
          target={what}
          lang={lang}
          theme={theme}
          isTablet={isTablet}
          isMobile={isMobile}
          isDesktop={isDesktop}
          colorMode={colorMode}
          isFullscreenMode={isFullscreenMode}
          fnOpenInFullscreen={fnOpenInFullscreen}
          isReady={isReady}
        />
      )}
    </Box>
  );
}

// =============
// styles
// =============
function _boxStyles(
  theme: TThemeSettings,
  isMobile: boolean,
  isFullscreenMode: boolean = false
) {
  const backgroundImageColor = prepareBGImageColors(theme);

  // ---
  // CSSObject
  // ---
  return Object.assign(
    {},
    {
      display: "grid",
      gridTemplateRows: "min-content 1fr",
      overflowY: "auto",
      height: "100%",
      padding: !isFullscreenMode ? "initial" : "2em",
      backgroundColor: !isFullscreenMode
        ? "unset"
        : theme.palette.background.default,
      backgroundImage: !isFullscreenMode
        ? "none"
        : `linear-gradient(${backgroundImageColor}, ${backgroundImageColor})`,

      "& .RMainChartWrapper .RBasicTabs .TabPanel_Box .RLine_ZeroToggle, & .RMainChartWrapper .RBasicTabs .TabPanel_Box .RLine_FullscreenToggle":
        {
          visibility: !isFullscreenMode ? "visible" : "hidden",
        },

      "& .RMainChartWrapper .RBasicTabs:hover .TabPanel_Box .RLine_ZeroToggle, & .RMainChartWrapper .RBasicTabs:hover .TabPanel_Box .RLine_FullscreenToggle":
        {
          visibility: "visible",
        },

      "& .RMainChartWrapper .RBasicTabs .TabPanel_Box .RLine_PostUssr": {
        visibility: !isFullscreenMode ? "hidden" : "visible",
      },

      "& .RMainChartWrapper .RBasicTabs:hover .TabPanel_Box .RLine_PostUssr": {
        visibility: "hidden",
      },

      "& .RMainChartWrapper .RBasicTabs .TabPanel_Box .RMainResponsiveBar_FullscreenToggle":
        {
          visibility: !isFullscreenMode ? "visible" : "hidden",
        },

      "& .RMainChartWrapper .RBasicTabs:hover .TabPanel_Box .RMainResponsiveBar_FullscreenToggle":
        {
          visibility: "visible",
        },

      "& .RMainChartWrapper .RBasicTabs .TabPanel_Box .RMainResponsiveBar_PostUssr":
        {
          visibility: !isFullscreenMode ? "hidden" : "visible",
        },

      "& .RMainChartWrapper .RBasicTabs:hover .TabPanel_Box .RMainResponsiveBar_PostUssr":
        {
          visibility: "hidden",
        },
    },
    isMobile ? {} : { ...webKitScrollbarFn(theme) }
  );
}
