// ========
// info
// ========
// from here: https://mui.com/material-ui/react-autocomplete/#customized-hook

// ========
// Import
// ========
import { useAutocomplete, AutocompleteGetTagProps } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled, type Theme } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { countries } from "../../assets/other_countries";
import * as React from "react";
import { ELangs } from "../../enums/ELangs";
import reference from "../../reference.json";
import { type TThemeSettings } from "../../theming/themeSettings";

// ========
// types
// ========
export interface CountryType {
  id: string;
  label: {
    [ELangs: string]: string;
  };
  suggested?: boolean;
}
type TComponentProps = {
  lang: ELangs;
};
type TTheme = Theme & TThemeSettings;

// ========
// constants
// ========
const OVERLAY_OPACITY_DARK = "k5";
const OVERLAY_OPACITY_LIGHT = "k7";

// ========
// Styled
// ========
const Root = styled("div")(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: "2em",
  "& #other-countries-label": {
    paddingBottom: "0.5em",
  },
}));

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(({ theme }) => ({
  width: "300px",
  border: `1px solid ${theme.palette.text.disabled}`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  padding: "1px",
  display: "flex",
  flexWrap: "wrap",

  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },

  "&.focused": {
    borderColor: theme.palette.secondary.main,
    boxShadow: "0 0 0 2px rgba(24, 144, 255, 0.2)",
  },

  "& input": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    height: "30px",
    boxSizing: "border-box",
    padding: "4px 6px",
    width: "0",
    minWidth: "30px",
    flexGrow: "1",
    border: "0",
    margin: "0",
    outline: "0",
  },
}));

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

// =========
// Sub Components
// =========
function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;

  // -------
  // rendering
  // -------
  return (
    <div className="ROtherCountries_StyledTag" {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

// -------
// -------
const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

// ------
// ------
const Listbox = styled("ul")(({ theme }) => {
  const isDark = _isDark(theme as TTheme);
  const overlayOpacity = _overlayOpacity(
    theme as TTheme,
    OVERLAY_OPACITY_DARK,
    OVERLAY_OPACITY_LIGHT,
    isDark
  );
  const backImageColor = _backImageColor(theme as TTheme, overlayOpacity);
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);

  // ------
  // CSS
  // ------
  return `
    width: 300px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.background.default};
    background-image: linear-gradient(${backImageColor}, ${backImageColor});
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;

    & li {
      padding: 5px 12px;
      display: flex;

      & span {
        flex-grow: 1;
      }

      & svg {
        color: transparent;
      }
    }

    & li[aria-selected='true'] {
      background-color: ${theme.palette.background.default};
      font-weight: 600;

      & svg {
        color: ${theme.palette.secondary.dark};
      }
    }

    & li.${autocompleteClasses.focused} {
      background-color: ${theme.palette.background.default};
      cursor: pointer;

      & svg {
        color: currentColor;
      }
    }

    scrollbar-color: transparent transparent;
    scrollbar-width: ${isFirefox ? "initial" : "thin"};

    &:hover {
      scrollbar-color: ${
        isDark ? theme.palette.secondary.main : theme.palette.primary.main
      } ${
    isDark
      ? theme.palette.primary.main + "30"
      : theme.palette.primary.main + "20"
  }; 
    }
  `;
});

// =======
// main
// =======
export default function ROtherCountries(props: TComponentProps) {
  const [, setUpdate] = React.useState({});
  // -------
  // busContext
  // -------
  const savedValue = busContext.getDataFromBusOne(busNames.otherCountries);
  const fileID = "ROtherCountries";

  const sortedCountries = _sortCountries(countries, props.lang);

  // -------
  // config useAutocomplete
  // -------
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "other-countries",
    // defaultValue: [top100Films[1]],
    multiple: true,
    // options: top100Films,
    // options: countries,
    options: sortedCountries,
    // getOptionLabel: (option) => option.title,
    getOptionLabel: (option) => option.label[props.lang],
    value: savedValue,
    // onChange: (event, newValue, reason, details) =>
    onChange: (_, newValue) => {
      busContext.placeDataToBus(busNames.otherCountries, newValue, fileID);
      setUpdate({
        /*re-render*/
      });
    },
  });

  // ---------
  // rendering
  // ---------
  return (
    <Root className="ROtherComponents">
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>
          {reference.ui.other_countries.greetings.name[props.lang]}
        </Label>
        <InputWrapper
          ref={setAnchorEl}
          className={
            focused
              ? "focused ROtherComponents_InputWrapper"
              : "ROtherComponents_InputWrapper"
          }
        >
          {value.map((option: CountryType, index: number) => {
            return (
              <StyledTag
                label={option.label[props.lang]}
                {...getTagProps({ index })}
              />
            );
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {/* List With Options */}
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} className="ROtherCountries_Listbox">
          {(groupedOptions as typeof countries).map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span className="ROtherCountries_Listbox_span">
                {option.label[props.lang]}
              </span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

// ========
// utils
// ========

function _isDark(theme: TTheme): boolean {
  return theme.palette.mode === "dark";
}
/**_ */

/**+ */
function _overlayOpacity(
  theme: TTheme,
  overlayDark: keyof typeof theme.overlay.kefs,
  overlayLight: keyof typeof theme.overlay.kefs,
  isDark: boolean
): number {
  return theme.overlay.kefs[`${isDark ? overlayDark : overlayLight}`];
}
/**_ */

/**+ */
function _backImageColor(theme: TTheme, overlayOpacity: number): string {
  return `rgba(${theme.overlay.color.r}, ${theme.overlay.color.g}, ${theme.overlay.color.b}, ${overlayOpacity})`;
}
/**_ */

/**+ */
function _sortCountries(countires: readonly CountryType[], lang: ELangs) {
  const copy_coutries = countires.slice();
  return copy_coutries.sort((a, b) =>
    new Intl.Collator().compare(a.label[lang], b.label[lang])
  );
}
