// ===========
// import
// ===========
import * as React from "react";
import { Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import reference from "../../../../reference.json";
import { ELangs } from "../../../../enums/ELangs";
import { ButtonBase } from "@mui/material";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { type Breakpoint } from "@mui/material/styles";
import { RStyledGridContainer as GridStyledContainer } from "../../../../styled/RStyledGridContainer";
import { RStyledGridContent as GridStyledContent } from "../../../../styled/RStyledGridContent";
import { RStyledFlexContainer as FlexStyledContainer } from "../../../../styled/RStyledFlexContainer";
import { useNavigate } from "react-router-dom";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";

// ===========
// types
// ===========
type ComponentProps = {
  lang: ELangs;
};

// ===========
// subcomponents
// ===========
function ButtonWithIndicator(props: {
  indicator: {
    url: string;
    name: { EN: string; RU: string };
    shortName: { EN: string; RU: string };
  };
  isMobile: boolean;
  theme: Theme;
  lang: ELangs;
  isTablet: boolean;
}) {
  // ------------
  // navigation
  // ------------
  const navigate = useNavigate();

  // ------------
  // handlers
  // ------------
  const handleClick = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    evnt.stopPropagation();
    navigate(props.indicator.url);
  };

  // ------------
  // rendering
  // ------------
  return (
    <ButtonBase
      key={props.indicator.url}
      className="RIndicatorsTab_ButtonWithIndex"
      sx={_buttonWCStyle(props.isMobile, props.theme, props.isTablet)}
      onClick={handleClick}
    >
      <span className="RIndicatorsTab_ButtonWithIndicators_IndicatorName">
        {!props.isMobile
          ? props.indicator.name[props.lang]
          : props.indicator.shortName[props.lang]}
      </span>
    </ButtonBase>
  );
}

// ===========
// main
// ===========
export function RIndicatorsTab(props: ComponentProps) {
  const indicatorHeadersObj = React.useMemo(
    () => reference.indicatorHeaders.startPage.indicators,
    []
  );
  const indicators = React.useMemo(
    () =>
      Object.keys(reference.targets).map((key) => ({
        [key]: reference.targets[key as keyof typeof reference.targets],
      })),
    []
  );

  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RIndicatorsTab";
  const { isTablet } = busContext.getDataFromBus(
    [busNames.isTablet, busNames.colorMode],
    fileID,
    setTrigg
  );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);
  ///<

  const isWiderThanMobileScreen = useMediaQuery(
    theme.breakpoints.up("sm" as Breakpoint)
  );
  const isWiderThanLaptopMPI = useMediaQuery(
    // @ts-ignore
    theme.breakpoints.up(theme.breakpoints.values.lg + 1)
  );

  // ----------
  // styles objects
  // ----------
  const gridStyledContainerProps = React.useMemo(
    () => ({
      gridTemplateRows: "fit-content(2rem) 1fr",
      padding: isWiderThanMobileScreen ? "2rem 1rem 1rem 0" : "2em 0 0 0",
      rowGap: "1rem",
      color: theme.palette.text.primary,
      isTablet: isTablet,
    }),
    // eslint-disable-next-line
    []
  );

  const gridStyledContentProps = React.useMemo(
    () => ({
      gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
      columnGap: "3rem",
      rowGap: "4em",
      maxHeight: isTablet
        ? "initial"
        : isWiderThanMobileScreen
        ? "none"
        : "none",
      height: "60vh",
      isFirefox: isFirefox as boolean,
    }),
    // eslint-disable-next-line
    []
  );

  const flexStyledContainerProps = React.useMemo(
    () => ({
      flexDirection: "column" as const,
      marginBottom: "1em",
      rowGap: "0.5rem",
    }),
    []
  );

  // ----------
  // pre-rendering
  // ----------
  const sortedIndicatorObjs = React.useMemo(
    () =>
      Object.values(indicatorHeadersObj)
        .slice()
        .sort((a, b) => {
          const aInx = a.block as keyof typeof reference.directory.blocks;
          const bInx = b.block as keyof typeof reference.directory.blocks;
          const catA = reference.directory.blocks[aInx].name[props.lang];
          const catB = reference.directory.blocks[bInx].name[props.lang];

          return catA.localeCompare(catB);
        }),
    // eslint-disable-next-line
    [props.lang]
  );

  const sortedIndicatorObjsLast = React.useMemo(
    () => sortedIndicatorObjs.length - 1,
    // eslint-disable-next-line
    [props.lang]
  );

  // ----------
  // rendering MEMO
  // ----------
  const renderMemo = React.useMemo(() => {
    return (
      <GridStyledContainer
        className="RIndicatorsTab_GridStyledContainer"
        {...gridStyledContainerProps}
      >
        {/* Header  */}
        <Box
          className="RIndicatorsTab_GridStyledContainer_Header"
          sx={_stylerHeader(isWiderThanMobileScreen, theme)}
        >
          <Typography
            variant="h2"
            component="h2"
            sx={{
              marginBottom: "0.2em",
              fontSize: isTablet ? "2em" : "2rem",
            }}
          >
            {reference.greetings.startPage.indicators[props.lang]}
          </Typography>
          <Typography
            component="h4"
            sx={{
              color:
                theme.palette.mode === "light"
                  ? theme.palette.primary.dark
                  : theme.palette.text.secondary,
              marginLeft: "0.2em",
              fontSize: isTablet ? "1em" : "1.2em",
            }}
          >
            {reference.greetings.startPage.indicatorInstuction[props.lang]}
          </Typography>
        </Box>
        {/* section */}
        <GridStyledContent
          {...gridStyledContentProps}
          isTablet={isTablet}
          isScroll={true}
          isMobile={!isWiderThanMobileScreen}
        >
          {sortedIndicatorObjs.map((indicatorObj, inx) => {
            const categoryIndex =
              indicatorObj.block as keyof typeof reference.directory.blocks;
            // -----
            // JSX
            // -----
            return (
              <FlexStyledContainer
                key={indicatorObj.id}
                {...flexStyledContainerProps}
                {...(inx === sortedIndicatorObjsLast && {
                  marginBottom: "3em",
                })}
                className="FlexStyledContainer RegionWrapper"
              >
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "2em",
                    color: theme.palette.primary.main,
                    paddingLeft: isWiderThanMobileScreen ? 0 : "16px",
                    fontSize: isTablet ? "0.7em" : "0.8em",
                    lineHeight: "0.1",
                    fontStyle: "italic",
                  }}
                >
                  {reference.directory.blocks[categoryIndex].name[props.lang]}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    borderBottom: isWiderThanMobileScreen
                      ? (theme) => `1px solid ${theme.palette.text.disabled}`
                      : "none",
                    textTransform: "uppercase",
                    color: theme.palette.primary.main,
                    paddingLeft: isWiderThanMobileScreen ? 0 : "1em",
                    paddingBottom: isWiderThanMobileScreen ? "0.5em" : "0",
                    fontWeight: "bold",
                    fontSize: isTablet ? "1em" : "1rem",
                  }}
                >
                  {indicatorObj.name[props.lang]}
                </Typography>
                <FlexStyledContainer
                  {...flexStyledContainerProps}
                  className="FlexStyledContainer CountriesWrapper"
                  sx={{
                    marginBottom: "0",
                    rowGap: isWiderThanLaptopMPI ? "0.5em" : "0.5em",
                    paddingLeft: isWiderThanMobileScreen ? 0 : "1em",
                  }}
                >
                  {/* indicators */}
                  {indicators
                    .filter((indicator) => {
                      return (
                        indicator[Object.keys(indicator)[0]].category ===
                        indicatorObj.id
                      );
                    })
                    .map((searchedIndicator) => {
                      const key = Object.keys(searchedIndicator)[0];

                      // -------
                      // JSX
                      // -------
                      return (
                        <ButtonWithIndicator
                          key={key}
                          indicator={searchedIndicator[key]}
                          isMobile={!isWiderThanMobileScreen}
                          theme={theme}
                          lang={props.lang}
                          isTablet={isTablet}
                        />
                      );
                    })}
                </FlexStyledContainer>
              </FlexStyledContainer>
            );
          })}
        </GridStyledContent>
      </GridStyledContainer>
    );
    // eslint-disable-next-line
  }, [props.lang, isTablet, indicators, theme]);

  // ----------
  // rendering
  // ----------
  return renderMemo;
}

// ============
// styles
// ============
function _buttonWCStyle(
  isMobile: boolean = false,
  theme: Theme,
  isTablet: boolean
) {
  // ---
  // CSSObject
  // ---
  return {
    justifyContent: "flex-start",
    fontSize: isMobile ? "16px" : "inherit",
    padding: isMobile ? "9px 27px" : "inherit",
    border: isMobile
      ? `1px solid ${theme?.palette.primary.main + "8c"}`
      : "inherit",
    borderRadius: isMobile ? "4px" : "inherit",
    width: isMobile ? "auto" : "inherit",
    margin: isMobile ? "8px" : "inherit",
    marginLeft: isMobile ? "0" : "inherit",
    marginRight: isMobile ? "1em" : "8px",
    whiteSpace: isMobile ? "nowrap" : "wrap",
    "& .RIndicatorsTab_ButtonWithIndicators_IndicatorName": {
      marginBottom: "0.2em",
      overflowX: "hidden",
      textAlign: "start",
      lineHeight: "1.4",
      fontSize: isTablet ? "1em" : "1.2em",
    },
  };
}

function _stylerHeader(isDesktop: boolean, theme: Theme) {
  if (isDesktop) {
    return {
      padding: "initial",
      marginBottom: "1em",
      position: "relative",
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      paddingBottom: "0.2em",
    };
  } else {
    return {
      padding: "0 1em",
      position: "relative",
      marginBottom: "initial",
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      paddingBottom: "0.2em",
    };
  }
}
