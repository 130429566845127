// ============
// import
// ============
import { type TMinState } from "../mainStore";
import { EStatus, type TEStatus } from "../../../enums/EStatus";

// ============
// main
// ============
export const addMany = (
  state: TMinState,
  action: Array<{ id: string }>,
  indicatorID: string,
  status?: TEStatus,
  sortingAlgo?: (a: string, b: string) => number
): TMinState => {
  const newEntities = action.reduce((result, elem) => {
    const entity = { [elem.id]: elem };
    result = Object.assign({}, result, { ...entity });
    return result;
  }, {} as Record<string, any>);

  const entities = Object.assign({}, state.entities, newEntities);

  const ids = Object.keys(entities).sort(sortingAlgo);

  status = status ? status : EStatus.IDLE;

  return { ids, entities, status, indicatorID };
};
