/**
 * Вариант "Desktop", размещено справа на экране
 */

// ==========
// import
// ==========
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { type NavigateFunction, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { styled, type SxProps, type Theme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { rgb2rgba } from "../../../func/prepare/rgb2rgba";
import { upsertOneNavActiveNRedux } from "../../../context/noredux/navState/navSlice";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  type TTargetsBarRoute,
  getListItemsStack,
} from "../targetsbar/hooks/useListItemsStack";
import { busContext } from "../../..";
import { busNames } from "../../../bus_context/busContextStore";
import { webKitScrollbarFn2 } from "../../../styled/WebKitScrollbar";
import { RAdvertising } from "../../advertising/RAdvertising";
import { ELangs } from "../../../enums/ELangs";
import { type TThemeSettings } from "../../../theming/themeSettings";

// ==========
// types
// ==========
type TComponentProps = {
  isTargetSidebarOpen?: boolean;
  toShowLegend?: boolean;
  isTargetsNavOnScreen?: boolean;
  lang: ELangs;
};

type TTabs = {
  children: React.ReactNode | Array<React.ReactNode>;
  sx?: SxProps<Theme>;
  orientation?: "vertical" | "horizontal";
  value: number;
  onChange: (_event: React.SyntheticEvent, newValue: number) => void;
  "aria-label": string;
} & Record<string, any>;

type TTabsMemoComponentProps = {
  navigate: NavigateFunction;
  isTargetSidebarOpen: boolean | undefined;
  ls: Record<string, Record<string, string | number>>;
  groupTarget: string;
  flag: React.MutableRefObject<boolean>;
  value: React.MutableRefObject<number>;
  routes: Array<TTargetsBarRoute>;
  elIDStr: string;
  isChangePath: React.MutableRefObject<boolean>;
  kefWidth: number;
};

// ==========
// constants
// ==========
const BOTTOM_MARGIN = "32px";
const ADVERTISING_MARGIN = "8px";

// ==========
// styled
// ==========
const StyledTabs = styled(
  (props: TTabs) => <Tabs {...props}>{props.children}</Tabs>,
  {
    shouldForwardProp: (prop) =>
      [
        "children",
        "sx",
        "orientation",
        "value",
        "onChange",
        "aria-label",
      ].includes(prop as string),
  }
)((allProps) => {
  type TTheme = typeof allProps.theme & TThemeSettings;
  const themeWithTypes = allProps.theme as TTheme;

  const isDark = themeWithTypes.palette.mode === "dark";
  const selectorWidth = "4px";
  const selectorColor = isDark
    ? themeWithTypes.palette.primary.light
    : themeWithTypes.palette.primary.dark;
  const selectedBackColor = rgb2rgba(themeWithTypes.palette.primary.light, 0.7);
  const selectedColor = themeWithTypes.palette.primary.contrastText;
  const overlay = themeWithTypes.overlay;
  const overlayColor = `rgba(${overlay.color.r}, ${overlay.color.g}, ${overlay.color.b}, ${overlay.kefs.k16})`;

  return Object.assign(
    {},
    {
      color: "inherit",
      [`& .MuiTabs-indicator`]: {
        left: 0,
        width: selectorWidth,
        backgroundColor: selectorColor,
      },
      overflow: "clip",
      "& .MuiTab-root.Mui-selected": {
        color: selectedColor,
        backgroundImage: isDark
          ? `linear-gradient(${overlayColor}, ${overlayColor})`
          : `linear-gradient(${selectedBackColor}, ${selectedBackColor})`,
      },
      "& .MuiButtonBase-root::before": {
        position: "absolute",
        content: `""`,
        top: 0,
        left: 0,
        bottom: 0,
        width: selectorWidth,
        backgroundColor: themeWithTypes.palette.primary.light,
        visibility: "hidden",
      },
      "& .MuiButtonBase-root:hover::before": {
        visibility: "visible",
      },
    },
    { ...allProps.sx }
  );
});

const TabWithRef = React.forwardRef<any, any>((props, ref) => {
  return <Tab {...props} ref={ref} />;
});

const StyledTab = styled(TabWithRef, {
  shouldForwardProp: (prop) => prop !== "kefWidth",
})<{ kefWidth: number }>((allProps) =>
  Object.assign(
    {},
    {
      color: "inherit",
      alignItems: "start",
      // fontSize: "0.75em",
      fontSize: allProps.kefWidth <= 1.1 ? "0.75em" : "0.75rem",

      /* some bag with Types for 'whiteSpace' */
      "&": {
        whiteSpace: "nowrap",
      },
    },
    { ...allProps.sx }
  )
);

const BasicNavTabs = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className: string;
    sx?: any;
    toShowLegend: boolean;
    isTargetsNavOnScreen: boolean;
    // kefHeight: number;
    kefWidth: number;
    isFirefox: boolean;
  }
>((props, ref) => {
  return (
    <Box {...props} ref={ref}>
      {props.children}
    </Box>
  );
});

const StyledNavTabs = styled(BasicNavTabs, {
  shouldForwardProp: (prop) =>
    prop !== "toShowLegend" &&
    prop !== "isTargetsNavOnScreen" &&
    prop !== "kefWidth" &&
    prop !== "isFirefox",
})((allProps) => {
  const heightDesktop = allProps.toShowLegend
    ? "100%"
    : `calc(80% - ${BOTTOM_MARGIN} - ${ADVERTISING_MARGIN})`;
  const height = allProps.isTargetsNavOnScreen
    ? heightDesktop
    : `calc(74% - ${ADVERTISING_MARGIN})`;
  const isDark = allProps.theme.palette.mode === "dark" ? true : false;

  // ------
  // CSSObject
  // ------
  return Object.assign(
    {},
    {
      fontSize: `${
        allProps.kefWidth <= 1.15
          ? Number(1.1).toFixed(2)
          : Number(allProps.kefWidth * 0.9).toFixed(2)
      }rem`,
      width: "100%",
      borderLeft: "unset",
      overflowY: "auto",
      height: height,
      transition: "height 1s",
      ...webKitScrollbarFn2(allProps.theme, isDark, allProps.isFirefox),
    },
    { ...allProps.sx }
  );
});

// ==========
// subcomponents
// ==========
const TabsMemoComponent = (props: TTabsMemoComponentProps) => {
  const fileID = "RTargetsNavTabs::TabsMemoComponent";
  const {
    navigate,
    isTargetSidebarOpen,
    ls,
    groupTarget,
    flag,
    value,
    routes,
    elIDStr,
    isChangePath,
    kefWidth,
  } = props;
  // ------------
  // refs
  // ------------
  const nodeRefs = React.useRef<React.ReactElement>();
  const isClicked = React.useRef(false);

  // ------------
  // handlers
  // ------------
  const handleClick = (event: any, route: string) => {
    event.preventDefault();

    const DELAY = 0;
    const parent =
      route.indexOf("_") === -1
        ? route.slice(1)
        : route.slice(1, route.indexOf("_"));

    window.setTimeout(() => {
      navigate(route);
      upsertOneNavActiveNRedux({
        id: parent,
        active: route,
      });

      busContext.placeDataToBus(
        busNames.isTargetSidebarOpen,
        !isTargetSidebarOpen,
        fileID
      );
    }, DELAY);
    isClicked.current = true;
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    ls[groupTarget].value = newValue;
    ls[groupTarget].active = Object.keys(ls[groupTarget]).filter((elm) =>
      elm.startsWith(groupTarget.toString())
    )[newValue];
    flag.current = true;
  };

  // --------
  // effects
  // --------
  React.useEffect(() => {
    if (isChangePath.current) {
      if (nodeRefs.current && !isClicked.current) {
        (nodeRefs.current as unknown as HTMLElement).focus();

        (nodeRefs.current as unknown as HTMLElement).scrollIntoView({
          block: "center",
        });
      }
      if (isClicked) {
        isClicked.current = false;
      }
      isChangePath.current = false;
    }
  });

  // --------
  // rendering
  // --------
  return (
    <StyledTabs
      orientation="vertical"
      value={value.current}
      onChange={handleChange}
      aria-label="nav tabs"
    >
      {routes.map((route) => {
        const ref = route.id === elIDStr ? nodeRefs : null;

        return (
          <StyledTab
            className="RTargetsNavTabs_StyledTabs_StyledTab"
            key={route.id}
            label={route.title}
            onClick={(event: React.SyntheticEvent) =>
              handleClick(event, route.to)
            }
            ref={ref}
            kefWidth={kefWidth}
          />
        );
      })}
    </StyledTabs>
  );
};

// ==========
// main
// ==========
export function RTargetsNavTabs({
  isTargetSidebarOpen = undefined,
  toShowLegend = true,
  isTargetsNavOnScreen = true,
  lang,
}: TComponentProps) {
  // ----------
  // bus
  // ----------
  const [, setTrigg] = React.useState({});
  const fileID = "RTargetsNavTabs";
  const { /*targetInContext: target, kefHeight */ kefWidth } =
    busContext.getDataFromBus(
      [/*busNames.targetInContext, busNames.kefHeight */ busNames.kefWidth],
      fileID,
      setTrigg
    );
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);

  const routerPathname = useLocation().pathname;

  const preciseTarget = routerPathname.slice(1);

  const routes = getListItemsStack(preciseTarget as any);

  const navigate = useNavigate();

  // ------------
  // local store
  // ------------
  const flag = useRef(false);
  const value = useRef<number>(0);
  const isChangePath = useRef(false);
  const oldTarget = useRef<string>(preciseTarget);
  const localStore = useRef<Record<string, Record<string, number | string>>>(
    {}
  );
  const ls = localStore.current;

  // ------------
  //
  // ------------
  const groupTarget: string = React.useMemo(() => {
    const gt =
      preciseTarget.indexOf("_") === -1
        ? preciseTarget
        : preciseTarget.slice(0, preciseTarget.indexOf("_"));

    if (ls[gt] === undefined) {
      ls[gt] = {} as Record<string, number | string>;
    }

    if (ls[gt][preciseTarget] === undefined) {
      ls[gt][preciseTarget] = `/${preciseTarget}`;
    }

    return gt;
  }, [preciseTarget, ls]);

  value.current = React.useMemo(() => {
    const initValueArray = Object.entries(routes).find(
      ([_key, valueObj]) => valueObj.to === routerPathname
    );

    oldTarget.current = preciseTarget;
    isChangePath.current = true;

    if (initValueArray !== undefined) {
      return Number(initValueArray[0]);
    } else {
      return 0;
    }

    // eslint-disable-next-line
  }, [routerPathname, lang]);

  const refApp = React.useRef<HTMLDivElement>(null);

  // ----------
  // pre-rendering
  // ----------
  const propsTabsMemoComponent = {
    navigate,
    isTargetSidebarOpen,
    ls,
    groupTarget,
    flag,
    value,
    routes,
    elIDStr: routes[value.current].id,
    isChangePath,
    kefWidth,
  };

  // ----------
  // rendering
  // ----------
  return (
    <StyledNavTabs
      className="RTargetsNavTabs"
      ref={refApp}
      toShowLegend={toShowLegend}
      isTargetsNavOnScreen={isTargetsNavOnScreen}
      kefWidth={kefWidth}
      isFirefox={isFirefox}
    >
      <TabsMemoComponent {...propsTabsMemoComponent} />
      {<RAdvertising isTargetsNavOnScreen={isTargetsNavOnScreen} lang={lang} />}
    </StyledNavTabs>
  );
}
