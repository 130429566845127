// =======
// import
// =======
import { /*EWhat,*/ TEWhatVal } from "../../enums/EWhat";

// =======
// main
// =======
export function prepareFormatY(target: TEWhatVal) {
  switch (target) {
    // case EWhat.OUTPUT_GDP: {
    //   // return (value: number) => `${(value / 1000).toFixed(2)}K`;
    //   return (value: number) => value;
    // }

    // case EWhat.OUTPUT_NYGDPPCAPCD: {
    //   return (value: number) => `${(value / 1000).toFixed(1)}K`;
    // }

    // case EWhat.INFLATION_CPI: {
    //   return (value: number) => value;
    // }

    default:
      return (value: number) => value;
    // throw new Error("RLine::formatY: 'target' is not found.");
  }
}
