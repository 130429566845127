// =============
// import
// =============
import type { Dictionary } from "@reduxjs/toolkit";
import type { TRline, TCountries } from "../../context/noredux/noreduxTypes";

// =============
// main
// =============
export function getActiveCountries(
  data: Array<TRline>,
  countriesDic: Dictionary<TCountries>
) {
  const result = data.filter((obj) => {
    return countriesDic[obj.id]?.active === true;
  });

  return result;
}
