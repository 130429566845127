export function gray2gray(
  rgb: string,
  direction: "dark" | "light",
  quantity: number
) {
  const rgbArray = rgb
    .slice(rgb.indexOf("(") + 1, rgb.indexOf(")"))
    .split(",")
    .map((elm) => {
      let num = Number(elm.trim());
      if (direction === "dark") {
        num = num - quantity;
        num = num < 0 ? 0 : num;
      } else {
        num = num + quantity;
        num = num > 255 ? 255 : num;
      }
      return num;
    });

  return `rgb(${rgbArray.join(",")})`;
}
