// =========
// import
// =========
// import * as bm from "./barrelModule";
import { type Theme } from "@mui/material/styles";
import { type TThemeSettings } from "../theming/themeSettings";

// =========
// main
// =========
// export const WebKitScrollbar = (reactComponentName: any) =>
//   bm.styled(reactComponentName)((propsAll) => {
//     const { theme, ...propsOther } = propsAll;

//     // ---------
//     // render
//     // ---------
//     return Object.assign(
//       {},
//       // firefox
//       {
//         scrollbarColor: `${theme.palette.secondary.light} ${
//           theme.palette.primary.light + "30"
//         }`,
//       },
//       // chrome
//       {
//         "&::-webkit-scrollbar": {
//           width: "0.5rem",
//           height: "0.5rem",
//         },
//         "&::-webkit-scrollbar-track": {
//           borderRadius: "10px",
//         },
//         "&:hover::-webkit-scrollbar-track": {
//           backgroundColor: theme.palette.primary.light,
//         },
//         "&::-webkit-scrollbar-thumb": {
//           borderRadius: "10px",
//         },
//         "&:hover::-webkit-scrollbar-thumb": {
//           backgroundColor: theme.palette.secondary.light,
//         },
//         "&::-webkit-scrollbar-corner": {
//           opacity: 0,
//         },
//       },
//       { ...propsOther }
//     );
//   });

// ==========
// function
// ==========
export const webKitScrollbarFn = (
  theme: Theme | TThemeSettings,
  thumbColor?: string,
  trackColor?: string
) => {
  return Object.assign(
    {},
    // firefox
    {
      scrollbarColor: `${
        thumbColor ? thumbColor : theme.palette.secondary.light
      } ${trackColor ? trackColor : theme.palette.primary.light + "30"}`,
    },
    // chrome
    {
      "&::-webkit-scrollbar": {
        width: "0.5rem",
        height: "0.5rem",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
      },
      "&:hover::-webkit-scrollbar-track": {
        backgroundColor: trackColor ? trackColor : theme.palette.primary.light,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: thumbColor
          ? thumbColor
          : theme.palette.secondary.light,
      },
      "&::-webkit-scrollbar-corner": {
        opacity: 0,
      },
    }
  );
};

//>
export const webKitScrollbarStr = (
  theme: Theme | TThemeSettings,
  thumbColor?: string,
  trackColor?: string
) => {
  return `
      scrollbar-color: ${
        thumbColor ? thumbColor : theme.palette.secondary.light
      } ${trackColor ? trackColor : theme.palette.primary.light};
    

      & ::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      & ::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &:hover ::-webkit-scrollbar-track {
        background-color: ${
          trackColor ? trackColor : theme.palette.primary.light
        };
      }

      & ::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }

      &:hover ::-webkit-scrollbar-thumb {
        background-color: ${
          thumbColor ? thumbColor : theme.palette.secondary.light
        };
      }

      & ::-webkit-scrollbar-corner {
        opacity: 0;
      }
    `;
};

//>
export const webKitScrollbarFn2 = (
  theme: Theme | TThemeSettings,
  isDark: boolean,
  isFirefox: boolean
) => ({
  scrollbarColor: "transparent transparent",
  scrollbarWidth: `${isFirefox ? "initial" : "thin"}` as const,

  "&:hover": {
    scrollbarColor: `${
      isDark ? theme.palette.secondary.main : theme.palette.primary.main
    } ${
      isDark
        ? theme.palette.primary.main + "30"
        : theme.palette.primary.main + "20"
    }`,
  },
});

//>
export const webKitScrollbarFn2Str = (
  theme: Theme | TThemeSettings,
  isDark: boolean,
  isFirefox: boolean
) =>
  `scrollbar-color: transparent transparent;
  scrollbar-width: ${isFirefox ? "initial" : "thin"};

  &:hover {
    scrollbar-color: ${
      isDark ? theme.palette.secondary.main : theme.palette.primary.main
    } ${
    isDark
      ? theme.palette.primary.main + "30"
      : theme.palette.primary.main + "20"
  };
  }
`;
