// =============
// types
// =============
type TMinMax = {
  minY?: number;
  maxY?: number;
  minX?: string;
  maxX?: string;
};
type TD = TMinMax;
type TStepParams = Array<{
  data: Array<{ x: string | number }>;
}>;
type TReturn = {
  min: number | null;
  max: number | null;
  step: number | null;
};

// =============
// main
// =============
export function getLineMinMax(data: TD[], axis: "x" | "y"): TReturn {
  const step = data.length === 0 ? null : _calculateStep(data as TStepParams);
  let exMin = _extremeMin(data, axis);
  let exMax = _extremeMax(data, axis);
  if (axis === "x") {
    return _makeStrBorders(exMin, exMax, step);
  } else {
    return _makeNumBorders(exMin, exMax);
  }
}

// -------------
// _extremeMin
// -------------
function _extremeMin(data: Array<TD>, axis: "x" | "y") {
  if (axis === "x") {
    return _findMinX(data);
  } else {
    return _findMinY(data);
  }
}

// -------------
// _extremeMax
// -------------
function _extremeMax(data: Array<TD>, axis: "x" | "y") {
  if (axis === "x") {
    return _findMaxX(data);
  } else {
    return _findMaxY(data);
  }
}

// -------------
// _findMinX
// -------------
function _findMinX(data: Array<TD>) {
  const minElems = data.map((elem) => {
    const eX = elem.minX === undefined ? "" : elem.minX;
    return isNaN(parseInt(eX)) ? null : parseInt(eX);
  });

  return minElems.includes(null)
    ? null
    : Math.min(...(minElems as Array<number>));
}

// -------------
// _findMaxX
// -------------
function _findMaxX(data: Array<TD>) {
  const maxElems = data.map((elem) => {
    const eX = elem.maxX === undefined ? "" : elem.maxX;
    return isNaN(parseInt(eX)) ? null : parseInt(eX);
  });

  return maxElems.includes(null)
    ? null
    : Math.min(...(maxElems as Array<number>));
}

// -------------
// _findMinY
// -------------
function _findMinY(data: Array<TD>) {
  const minElems = data.map((elem) => elem.minY);
  if (minElems.includes(undefined)) {
    return 0;
  }
  return Math.min(...(minElems as Array<number>));
}

// -------------
// _findMaxY
// -------------
function _findMaxY(data: Array<TD>) {
  const maxElems = data.map((elem) => elem.maxY);
  if (maxElems.includes(undefined)) {
    return 0;
  }
  return Math.max(...(maxElems as Array<number>));
}

// -------------
// _makeStrBorders
// -------------
function _makeStrBorders(
  strMin: number | null,
  strMax: number | null,
  step: number | null
) {
  if (strMin === null || strMax === null) {
    return {
      min: null,
      max: null,
      step: null,
    };
  }

  return {
    min: strMin,
    max: strMax,
    step: step,
  };
}

// -------------
// _makeNumBorders
// -------------
function _makeNumBorders(exMin: number | null, exMax: number | null) {
  if (exMin === null || exMax === null) {
    return {
      min: null,
      max: null,
      step: null,
    };
  }
  const PERCENT = 0.05;
  return {
    min: Math.floor(exMin * (1 - PERCENT)),
    max: Math.ceil(exMax * (1 + PERCENT)),
    step: null,
  };
}

// -------------
// _calculateStep
// -------------
function _calculateStep(arr: TStepParams) {
  const outerObject = arr[0];
  const innerObject_1 = outerObject.data[0];
  const innerObject_2 = outerObject.data[1];
  const elem_1 = innerObject_1.x;
  const elem_2 = innerObject_2.x;
  if (typeof elem_1 === "string" && typeof elem_2 === "string") {
    return _calcStrStep(elem_1, elem_2);
  }

  if (typeof elem_1 === "number" && typeof elem_2 === "number") {
    return elem_2 - elem_1;
  }

  return null;
}

// -------------
// _calcStep
// -------------
function _calcStrStep(elem_1: string, elem_2: string) {
  const numEl_1 = parseInt(elem_1);
  const numEl_2 = parseInt(elem_2);
  const isNoResult =
    isNaN(numEl_1) || isNaN(numEl_2) || !Boolean(numEl_1) || !Boolean(numEl_2);

  if (isNoResult) {
    return null;
  }

  return numEl_2 - numEl_1;
}

// =============
// for worker
// =============
export function getLineMinMaxWorker() {
  // -------------
  // _extremeMin
  // -------------
  const _extremeMin = (
    data: Array<string | number>,
    axis: "x" | "y",
    _findMinX: (data: Array<string>) => number | null,
    _findMinY: (data: Array<string>, isMinEqualNull: boolean) => number | null,
    isMinEqualNull: boolean
  ) => {
    if (axis === "x") {
      return _findMinX(data as Array<string>);
    } else {
      return _findMinY(data as Array<string>, isMinEqualNull);
    }
  };

  // -------------
  // _extremeMax
  // -------------
  const _extremeMax = (
    data: Array<string | number>,
    axis: "x" | "y",
    _findMaxX: (data: Array<string>) => number | null,
    _findMaxY: (data: Array<string>) => number | null
  ) => {
    if (axis === "x") {
      return _findMaxX(data as Array<string>);
    } else {
      return _findMaxY(data as Array<string>);
    }
  };

  // -------------
  // _findMinX
  // -------------
  const _findMinX = (data: Array<string>) => {
    const minElems = data.map((elem) =>
      isNaN(parseInt(elem)) ? null : parseInt(elem)
    );

    return minElems.includes(null)
      ? null
      : Math.min.apply(null, minElems as Array<number>);
  };

  // -------------
  // _findMaxX
  // -------------
  const _findMaxX = (data: Array<string>) => {
    const maxElems = data.map((elem) =>
      isNaN(parseInt(elem)) ? null : parseInt(elem)
    );

    return maxElems.includes(null)
      ? null
      : Math.max.apply(null, maxElems as Array<number>);
  };

  // -------------
  // _findMinY
  // -------------
  const _findMinY = (data: Array<string>, isMinEqualNull: boolean) => {
    const minElems = data.map((elem) =>
      // isNaN(parseFloat(elem)) ? 0 : parseFloat(elem)
      isNaN(parseFloat(elem)) ? Number.MAX_SAFE_INTEGER : parseFloat(elem)
    );

    const belowNull = Math.min.apply(null, minElems);

    if (isMinEqualNull && belowNull >= 0) {
      return Math.min(0, Math.floor(Math.min.apply(null, minElems)));
    } else {
      return belowNull;
    }
  };

  // -------------
  // _findMaxY
  // -------------
  const _findMaxY = (data: Array<string>) => {
    const maxElems = data.map((elem) =>
      isNaN(parseFloat(elem)) ? 0 : parseFloat(elem)
    );
    return Math.max.apply(null, maxElems);
  };

  // -------------
  // _makeStrBorders
  // -------------
  const _makeStrBorders = (
    strMin: number | null,
    strMax: number | null,
    step: number | null
  ) => {
    if (strMin === null || strMax === null) {
      return {
        min: null,
        max: null,
        step: null,
      };
    }

    return {
      min: strMin,
      max: strMax,
      step: step,
    };
  };

  // -------------
  // _makeNumBorders
  // -------------
  const _makeNumBorders = (
    exMin: number | null,
    exMax: number | null,
    isMinEqualNull: boolean = false
  ) => {
    if (exMin === null || exMax === null) {
      return {
        min: null,
        max: null,
        step: null,
      };
    }

    const PERCENT_MIN = 0.1;
    const PERCENT_MAX = 0.05;
    if (isMinEqualNull) {
      return {
        min: exMin > 0 ? 0 : exMin * (1 + PERCENT_MIN),
        max: exMax > 0 ? exMax * (1 + PERCENT_MAX) : 0,
        step: null,
      };
    } else {
      return {
        min: exMin,
        max: exMax,
        step: null,
      };
    }
  };

  // -------------
  // _calculateStep
  // -------------
  const _calculateStep = (arr: TStepParams) => {
    const outerObject = arr[0];
    const innerObject_1 = outerObject.data[0];
    const innerObject_2 = outerObject.data[1];
    const elem_1 = innerObject_1.x;
    const elem_2 = innerObject_2.x;
    if (typeof elem_1 === "string" && typeof elem_2 === "string") {
      return _calcStrStep(elem_1, elem_2);
    }

    if (typeof elem_1 === "number" && typeof elem_2 === "number") {
      return elem_2 - elem_1;
    }

    return null;
  };

  // -------------
  // _calcStep
  // -------------
  const _calcStrStep = (elem_1: string, elem_2: string) => {
    const numEl_1 = parseInt(elem_1);
    const numEl_2 = parseInt(elem_2);
    const isNoResult =
      isNaN(numEl_1) ||
      isNaN(numEl_2) ||
      !Boolean(numEl_1) ||
      !Boolean(numEl_2);

    if (isNoResult) {
      return null;
    }

    return numEl_2 - numEl_1;
  };

  // -------------
  // prepare data
  // -------------
  const _prepareData = (data: Array<TD & { data: any }>, axis: "x" | "y") =>
    data
      .map((elem) =>
        elem.data.map((fig: { x: string; y: number }) => fig[axis])
      )
      .flat();

  // -------------
  // main
  // -------------
  return (
    data: Array<TD & { data: any }>,
    axis: "x" | "y",
    isMinEqualNull: boolean = false
  ): TReturn => {
    const step = data.length === 0 ? null : _calculateStep(data as TStepParams);
    const preparedData = _prepareData(data, axis);

    let exMin = _extremeMin(
      preparedData,
      axis,
      _findMinX,
      _findMinY,
      isMinEqualNull
    );

    let exMax = _extremeMax(preparedData, axis, _findMaxX, _findMaxY);

    if (axis === "x") {
      return _makeStrBorders(exMin, exMax, step);
    } else {
      return _makeNumBorders(exMin, exMax, isMinEqualNull);
    }
  };
}
