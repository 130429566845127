// ===========
// import
// ===========
import * as React from "react";
import { RStyledHorizontalTabsWrap as StyledHomeTabs } from "../../../styled/RStyledHorizontalTabsWrap";
import { RStyledHorizontalTabs as StyledTabs } from "../../../styled/RStyledHorizontalTabs";
import { RStartTab } from "./tabs/RStartTab";
import { RIndicatorsTab } from "./tabs/RIndicatorsTab";
import { busContext } from "../../..";
import { busNames } from "../../../bus_context/busContextStore";
import { ELangs } from "../../../enums/ELangs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import reference from "../../../reference.json";
import { type Theme } from "@mui/material/styles";
import { RAbout } from "../../about/RAbout";
import { webKitScrollbarFn2 } from "../../../styled/WebKitScrollbar";
import changeUrl from "../../../func/url/changeUrl";

// ===========
// types
// ===========
type TTabsMemoComponent = {
  theme: Theme;
  lang: ELangs;
  tabNumberH: number;
  isTablet: boolean;
};
type TComponentProps = {
  lang: ELangs;
  isMobile: boolean;
  isTablet: boolean;
  theme: Theme;
  tabNumberH: number;
};

// ===========
// sub components
// ===========
const TabsMemoComponent = (props: TTabsMemoComponent) => {
  const fileID = "RHomeTabs::TabsMemoComponent";

  // ---------
  // handlers
  // ---------
  const handleOnChange = (_e: React.SyntheticEvent, newValue: number) => {
    busContext.placeDataToBus(busNames.tabNumberH, newValue, fileID);
  };

  // ---------
  // rendering
  // ---------
  return (
    <StyledTabs
      className="StyledTabs"
      value={props.tabNumberH}
      onChange={handleOnChange}
      aria-label="basic tabs"
      textColor="inherit"
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: props.theme.palette.primary.main,
          opacity: 1,
        },
      }}
    >
      <Tab
        value={0}
        label={reference.ui.selectCountries.name[props.lang]}
        {...allyProps(0)}
        className="RHomeTabs_StyledTabs_Tab_0"
        sx={{ fontSize: props.isTablet ? "0.75em" : "0.75rem" }}
      />
      <Tab
        value={1}
        label={reference.ui.indicators.name[props.lang]}
        {...allyProps(1)}
        className="RHomeTabs_StyledTabs_Tab_1"
        sx={{ fontSize: props.isTablet ? "0.75em" : "0.75rem" }}
      />
      <Tab
        value={2}
        label={reference.ui.about.name[props.lang]}
        {...allyProps(2)}
        className="RHomeTabs_StyledTabs_Tab_2"
        sx={{ fontSize: props.isTablet ? "0.75em" : "0.75rem" }}
      />
    </StyledTabs>
  );
};

// ===========
// main
// ===========
export function RHomeTabs(props: TComponentProps) {
  // -----------
  // bus
  // -----------
  const isFirefox = busContext.getDataFromBusOne(busNames.isFirefox);

  // -----------
  // rendering
  // -----------
  return (
    <StyledHomeTabs
      className="RHomeTabs"
      isStart={true}
      isMobile={props.isMobile}
      isTablet={props.isTablet}
    >
      <Box
        className="RHomeTabs_before_TabsMemoComponent"
        sx={{
          borderBottom: 1,
          borderColor:
            props.theme.palette.mode === "light"
              ? props.theme.palette.primary.light
              : props.theme.palette.text.disabled,
        }}
      >
        <TabsMemoComponent
          theme={props.theme}
          lang={props.lang}
          tabNumberH={props.tabNumberH}
          isTablet={props.isTablet}
        />
      </Box>
      {/* Start   */}
      <TabPanel
        index={0}
        tabNumberH={props.tabNumberH}
        isMobile={props.isMobile}
        theme={props.theme}
        isFirefox={isFirefox}
      >
        <RStartTab />
      </TabPanel>
      {/* Indicators */}
      <TabPanel
        index={1}
        tabNumberH={props.tabNumberH}
        isMobile={props.isMobile}
        theme={props.theme}
        isFirefox={isFirefox}
      >
        <RIndicatorsTab lang={props.lang} />
      </TabPanel>
      {/* About */}
      <TabPanel
        index={2}
        tabNumberH={props.tabNumberH}
        isMobile={props.isMobile}
        isScroll={true}
        theme={props.theme}
        isFirefox={isFirefox}
      >
        <RAbout
          lang={props.lang}
          isFirefox={isFirefox}
          isTablet={props.isTablet}
        />
      </TabPanel>
    </StyledHomeTabs>
  );
}

// ===========
// subcomponents
// ===========
type TTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  tabNumberH: number;
  isMobile?: boolean;
  isScroll?: boolean;
  theme: Theme;
  isFirefox: boolean;
};

// ---
// TabPanel
// ---
function TabPanel(props: TTabPanelProps) {
  const isScroll = props.isScroll ? props.isScroll : false;
  changeUrl({
    tabH: props.tabNumberH.toString(),
  });

  type TWebScroll = {
    scrollbarColor: string;
    scrollbarWidth: "initial" | "thin";
  };

  const webScroll: TWebScroll = webKitScrollbarFn2(
    props.theme,
    props.theme.palette.mode === "dark",
    props.isFirefox
  );

  // -----------
  // rendering
  // -----------
  return (
    <Box
      role="tabpanel"
      hidden={props.tabNumberH !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
      className="TabPanel"
      sx={{
        overflowY: isScroll ? "auto" : !props.isMobile ? "unset" : "auto",
        flex: "1 1 auto",
        height: "100%",
        ...webScroll,
        // --transition_test--
        // transition: "all 4s",
      }}
    >
      {props.tabNumberH === props.index && (
        <Box
          sx={{
            p: props.isMobile ? 0 : "0 24px 24px 24px",
            height: !props.isMobile ? "100%" : "fit-content",
            // --transition_test__
            // transition: "all 4s",
          }}
          className="RHomeTabs_TabPanel_Box"
        >
          {props.children}
        </Box>
      )}
    </Box>
  );
}

// ===========
// allies
// ===========
function allyProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
