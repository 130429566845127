// =============
// import
// =============
import reference from "../../reference.json";
import { ELangs } from "../../enums/ELangs";
import { TEWhatVal } from "../../enums/EWhat";

// =============
// main
// =============
export function prepareAxisTitles(target: TEWhatVal, lang: ELangs) {
  return {
    x: reference.targets[target].x[lang],
    y: reference.targets[target].y[lang],
  };
}

export function prepareAxisTitlesWorker(
  target: TEWhatVal,
  lang: ELangs,
  reference: Record<string, any>
) {
  return {
    x: reference.targets[target].x[lang],
    y: reference.targets[target].y[lang],
  };
}
