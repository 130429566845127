// ============
// import
// ============
import { store, type TMinState } from "../mainStore";
import { addMany } from "../handlers/addMany";
import { updateOne } from "../handlers/updateOne";
import { updateAll } from "../handlers/updateAll";
import { type TECountriesVal } from "../../../enums/ECountries";

// ============
// types
// ============
export type TCountry = {
  id: TECountriesVal;
  active: boolean;
};
type TUpdateOneAction = {
  id: TECountriesVal;
  changes: TCountry;
};
type TUpdateAllAction = Array<{ id: string }>;
type TCountrySlice = {
  actions: Record<string, Function>;
};

// ============
// utils
// ============
const sortingAlgo = (a: string, b: string) => a.localeCompare(b);

// ============
// slice
// ============
const countriesSlice: TCountrySlice = store.createSlice({
  name: "countries",
  initialState: {
    ids: [],
    entities: {},
    indicatorID: "",
  } as TMinState<TCountry>,
  reducers(self) {
    return {
      addManyCountriesNRedux(state, dispatch) {
        if (state[self.name] === undefined) {
          state[self.name] = self.initialState;
        }
        return (action) => {
          dispatch(
            self.name,
            addMany(
              state[self.name] as TMinState,
              action,
              "",
              undefined,
              sortingAlgo
            )
          );
        };
      },
      toggleCountryActiveNRedux(state, dispatch) {
        return (action: TUpdateOneAction) => {
          dispatch(self.name, updateOne(state[self.name] as TMinState, action));
        };
      },
      toggleAllCountriesNRedux(state, dispatch) {
        return (action: TUpdateAllAction) => {
          dispatch(self.name, updateAll(state[self.name] as TMinState, action));
        };
      },
    };
  },
});

// ============
// reducer
// ============
// export default countriesSlice.reducer;

// ============
// actions
// ============
if (!countriesSlice.actions) {
  throw new Error(
    "noredux/countries/countriesSlice:\ncountriesSlice.actions === null"
  );
}

export const {
  addManyCountriesNRedux,
  toggleCountryActiveNRedux,
  toggleAllCountriesNRedux,
} = countriesSlice.actions;
