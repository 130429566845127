// ===========
// import
// ===========
import { ECountries } from "../enums/ECountries";
import type { TECountriesVal } from "../enums/ECountries";
import { addManyCountriesNRedux } from "../context/noredux/countries/countriesSlice";
import { upsertOneNavActiveNRedux } from "../context/noredux/navState/navSlice";
import { BusContext } from "../bus_context/busContext";
import { busNames } from "../bus_context/busContextStore";

// ===========
// main
// ===========
export function initApp(busContext: BusContext) {
  const searchedParamsInit = new URL(window.location.href).searchParams;

  const searchedCntrs = _fnCntrsFromSearchParams(searchedParamsInit);
  addManyCountriesNRedux(_countries(searchedCntrs));
  upsertOneNavActiveNRedux(null);

  _fnPlaceRangeTupple(busContext, searchedParamsInit);
  _fnPlaceTabNumber(busContext, searchedParamsInit);
  _fnPlaceTabNumberH(busContext, searchedParamsInit);
  _fnPlaceYear(busContext, searchedParamsInit);
  _fnPlaceClMode(busContext, searchedParamsInit);
}

// ===========
// utils
// ===========
function _countries(
  searchedCntrs: Array<string> | undefined
): Array<{ id: TECountriesVal; active: boolean }> {
  const cntrs = Object.values(ECountries) as Array<TECountriesVal>;

  if (!searchedCntrs) {
    return cntrs.map((cntr) => ({
      id: cntr,
      active: true,
    }));
  } else {
    return cntrs.map((cntr) => ({
      id: cntr,
      active: searchedCntrs.includes(cntr) ? true : false,
    }));
  }
}

//>
function _fnCntrsFromSearchParams(
  searchedParamsInit: URLSearchParams
): Array<string> | undefined {
  const urlCntrs = searchedParamsInit.get("cntrs");

  return urlCntrs ? urlCntrs.split("-") : undefined;
}

//>
function _fnPlaceRangeTupple(
  busContext: BusContext,
  searchedParamsInit: URLSearchParams
): void {
  const urlRangeStart = searchedParamsInit.get("rangeStart") ?? 0;
  const urlRangeEnd = searchedParamsInit.get("rangeEnd") ?? 0;

  busContext.setBusContext(
    busNames.yearsRangeTupple,
    [Number(urlRangeStart), Number(urlRangeEnd)],
    (data) => data
  );
}

//>
function _fnPlaceTabNumber(
  busContext: BusContext,
  searchedParamsInit: URLSearchParams
): void {
  const urlTab = searchedParamsInit.get("tab") ?? 0;

  busContext.setBusContext(busNames.tabNumber, Number(urlTab), (data) => data);
}

//>
function _fnPlaceTabNumberH(
  busContext: BusContext,
  searchedParamsInit: URLSearchParams
): void {
  const urlTabH = searchedParamsInit.get("tabH") ?? 0;

  busContext.setBusContext(
    busNames.tabNumberH,
    Number(urlTabH),
    (data) => data
  );
}

//>
function _fnPlaceYear(
  busContext: BusContext,
  searchedParamsInit: URLSearchParams
): void {
  const urlYear = searchedParamsInit.get("year") ?? 0;

  busContext.setBusContext(busNames.yearInit, Number(urlYear), (data) => data);
}

//>
function _fnPlaceClMode(
  busContext: BusContext,
  searchedParamsInit: URLSearchParams
): void {
  const urlClmodeRaw = searchedParamsInit.get("clmode") ?? "dark";

  const urlClmode =
    urlClmodeRaw === "dark" || urlClmodeRaw === "light" ? urlClmodeRaw : "dark";

  busContext.setBusContext(busNames.colorMode, urlClmode, (data) => data);
}
