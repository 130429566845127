// =============
// import
// =============
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import ListIcon from "@mui/icons-material/List";
import LanguageIcon from "@mui/icons-material/Language";
// import ShowChartIcon from "@mui/icons-material/ShowChart";
import ConstructionIcon from "@mui/icons-material/Construction";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import ElderlyWomanOutlinedIcon from "@mui/icons-material/ElderlyWomanOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import CompressOutlinedIcon from "@mui/icons-material/CompressOutlined";
import ExpandOutlinedIcon from "@mui/icons-material/ExpandOutlined";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import SchoolIcon from "@mui/icons-material/School";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import ChurchIcon from "@mui/icons-material/Church";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BiotechIcon from "@mui/icons-material/Biotech";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import TableChartIcon from "@mui/icons-material/TableChart";
import FloodIcon from "@mui/icons-material/Flood";
import MoneyIcon from "@mui/icons-material/Money";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import HikingIcon from "@mui/icons-material/Hiking";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BalanceIcon from "@mui/icons-material/Balance";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

// =============
// types
// =============
type TIcons = typeof HomeOutlinedIcon;
type TIconsStore = { [x: string]: TIcons };

// =============
// main
// =============
export const iconsStore: TIconsStore = {
  Home: HomeOutlinedIcon,
  People: PeopleOutlinedIcon,
  Menu: MenuOutlinedIcon,
  lightMode: LightModeOutlinedIcon,
  darkMode: DarkModeOutlinedIcon,
  search: SearchOutlinedIcon,
  CircleBound: RadioButtonUncheckedIcon,
  CircleFill: CircleIcon,
  Up: ArrowDropUpIcon,
  Down: ArrowDropDownIcon,
  Play: PlayArrowIcon,
  Pause: PauseIcon,
  NotInterested: NotInterestedIcon,
  Checked: CheckCircleOutlineIcon,
  CapitalIcon: AccountBalanceIcon,
  Close: CloseIcon,
  ListIcon: ListIcon,
  Countries: LanguageIcon,
  TrendUpIcon: TrendingUpIcon,
  Construction: ConstructionIcon,
  ArrowRight: ArrowRightAltIcon,
  Signal: SignalCellularAltOutlinedIcon,
  ElderlyWoman: ElderlyWomanOutlinedIcon,
  Bar: LocalBarOutlinedIcon,
  CompressIcon: CompressOutlinedIcon,
  ExpandIcon: ExpandOutlinedIcon,
  HealthIcon: HealthAndSafetyIcon,
  EducationIcon: SchoolIcon,
  DefenceIcon: LocalPoliceOutlinedIcon,
  PollutionIcon: Co2OutlinedIcon,
  WarningIcon: WarningAmberIcon,
  ReproductionIcon: ChildFriendlyIcon,
  ChurchIcon: ChurchIcon,
  MoneyOffIcon: MoneyOffIcon,
  MilitaryTechIcon: MilitaryTechIcon,
  AccountBalanceIcon: AccountBalanceWalletIcon,
  ThumbUpIcon: ThumbUpOffAltIcon,
  TechIcon: BiotechIcon,
  AgricultureIcon: AgricultureIcon,
  ExportIcon: LogoutIcon,
  ImportIcon: LoginIcon,
  TableIcon: TableChartIcon,
  PovertyIcon: FloodIcon,
  MoneyIcon: MoneyIcon,
  ReceiptIcon: ReceiptIcon,
  PointOfSaleIcon: PointOfSaleIcon,
  AggregateIcon: DataUsageIcon,
  ExpenditureIcon: FormatListNumberedIcon,
  RatesIcon: StackedLineChartIcon,
  PppIcon: AlignVerticalCenterIcon,
  MigrationIcon: HikingIcon,
  ShoppingCartIcon: ShoppingCartIcon,
  VaccinesIcon: VaccinesIcon,
  HailIcon: EmojiPeopleIcon,
  BalanceIcon: BalanceIcon,
  CurrencyIcon: CurrencyExchangeIcon,
  FullscreenIcon: FullscreenIcon,
  FullscreenExitIcon: FullscreenExitIcon,
};
