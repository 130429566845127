import { iconsStore } from "../../hooks_g/useIcons";
import { IconButton } from "@mui/material";
import React from "react";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";

// ===========
// types
// ===========

// =============
// main
// =============
export function RTopbarSidebar() {
  const { Menu } = iconsStore;
  const fileID = "RTopbarSidebar";

  // ----------
  // handlers
  // ----------
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const isMDOpen = busContext.getDataFromBusOne(busNames.isMiniDrawerOpen);
    if (isMDOpen) {
      busContext.placeDataToBus(busNames.isMiniDrawerOpen, false, fileID);
    } else {
      busContext.placeDataToBus(busNames.isMiniDrawerOpen, true, fileID);
    }
  };

  // ----------
  // rendering
  // ----------
  return (
    <IconButton
      type="button"
      sx={{
        p: 1,
        color: "inherit",
      }}
      onClick={handleClick}
    >
      <Menu />
    </IconButton>
  );
}
