/**
 * Code example from here:
 * https://mui.com/material-ui/react-table/#sorting-amp-selecting
 * */
// =========
// import
// =========
import * as bm from "../barrelModule";

// =========
// main
// =========
export function getComparator<T extends bm.TOrder, K extends keyof any>(
  order: T,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// =========
// helpers
// =========
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}
