// ===========
// import
// ===========
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { type TSidebarRoute /*type TIcon*/ } from "./hooks/routesStack";
import { styled, type Theme } from "@mui/material/styles";
import React from "react";
import { busContext } from "../..";
import { busNames } from "../../bus_context/busContextStore";
import { ELangs } from "../../enums/ELangs";
import Tooltip, {
  type TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import reference from "../../reference.json";
import { Typography } from "@mui/material";
import { type TThemeSettings } from "../../theming/themeSettings";
import Box from "@mui/material/Box";

// ===========
// types
// ===========
type TListItemText = {
  primary: string;
  open: boolean;
  className: string;
  isHeader: boolean;
};
type TListItemButton = {
  children: React.ReactNode;
  open: boolean;
  className: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isActive?: boolean;
  disabled: boolean;
  isFirefox: boolean;
  isTablet: boolean;
};
type TComponentProps = {
  route: TSidebarRoute;
  open: boolean;
  parentHandleClick: Function;
  lang: ELangs;
  isFirefox: boolean;
  isTablet: boolean;
  kefWidth: number;
  correction: undefined | number;
};
type TTheme = Theme & TThemeSettings;

// ===========
// pre-component
// ===========
const PreStyledListItemIcon = React.forwardRef<any, any>(
  function PreStyledListItemIcon(props, ref) {
    return (
      <ListItemIcon {...props} ref={ref}>
        {props.children}
      </ListItemIcon>
    );
  }
);
// ||
// from here: https://github.com/mui/material-ui/issues/33079#issuecomment-1151216761

// ===========
// styled
// ===========
const TooltipCustom = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: {
        modifiers: [{ name: "offset", options: { offset: [0, 12] } }],
      },
    }}
    classes={{ popper: className }}
  />
))((allProps) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: allProps.theme.palette.primary.dark,
      color: allProps.theme.palette.common.white,
      lineHeight: 2.5,
      letterSpacing: "0.2em",
      borderRadius: 0,
      fontSize: "0.8em",
    },
  };
});

const StyledListItemIcon = styled(PreStyledListItemIcon, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isTablet",
})((props) => {
  // ---
  // CSSObject
  // ---
  return {
    width: "100%",
    minWidth: 0,
    // paddingLeft: PL,
    mr: props.open ? 3 : "auto",
    justifyContent: "center",
    color: "inherit",
    "& .MuiSvgIcon-root": {
      height: props.isTablet ? "1em" : "1.5rem",
      width: props.isTablet ? "1em" : "1.5rem",
    },
    opacity: props.theme.palette.mode === "dark" ? 0.7 : 1,
  };
});

/// ---
const StyledListItemText = styled(
  (props: TListItemText) => <ListItemText {...props} />,
  {
    shouldForwardProp: (prop) =>
      prop !== "open" && prop !== "fnIsAnimationStarted" && prop !== "isHeader",
  }
)((props) => {
  return {
    whiteSpace: "nowrap",
    color: "inherit",
    paddingTop: "0.2em",
    marginLeft: !props.isHeader ? "1em" : "0.3em",
    visibility: props.open ? "visible" : "hidden",
    transition: "visibility 250ms",
  };
});

/// ---
const StyledListItemButton = styled(
  (props: TListItemButton) => (
    <ListItemButton {...props}>{props.children}</ListItemButton>
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== "isActive" && prop !== "isFirefox" && prop !== "isTablet",
  }
)((styleProps) => {
  const themeWithType = styleProps.theme as TTheme;
  const isDark = themeWithType.palette.mode === "dark" ? true : false;

  // ---
  // CSSObject
  // ---
  return {
    height: 48,
    justifyContent: "flex-start",
    padding: styleProps.isTablet ? "0 16px" : "0 20.5px",
    paddingRight: styleProps.open ? 0 : styleProps.isTablet ? "16px" : "20.5px",

    "&::before": {
      position: "absolute",
      content: `""`,
      top: 0,
      right: 0,
      bottom: 0,
      width: "4px",
      backgroundColor: themeWithType.palette.secondary.main,
      visibility: styleProps.isActive ? "visible" : "hidden",
    },
    "&:hover::before": {
      visibility: "visible",
    },
    "&.Mui-disabled": {
      color: isDark ? themeWithType.palette.primary.main : "inherit",
      opacity: styleProps.open ? (isDark ? 1 : 0.8) : 0,
      backgroundColor: `rgba(255, 255, 255, ${themeWithType.overlay.kefs.k7})`,
      padding: 0,
      transition: "opacity 1s 400ms",
    },

    /* Descendants */
    "& .RSidebarMuiItem_IconWrapper": {
      flexBasis: "fit-content",
    },
  };
});

// ===========
// main
// ===========
export function RSidebarMuiItem({
  route,
  open,
  parentHandleClick,
  lang,
  isFirefox,
  isTablet,
  kefWidth,
  correction,
}: TComponentProps) {
  const routeId =
    route.to.indexOf("_") === -1
      ? route.to.slice(1)
      : route.to.slice(1, route.to.indexOf("_"));

  const isClickable = Boolean(route.to) ? true : false;

  const refElm = React.useRef<HTMLLIElement>(null);
  const isClicked = React.useRef(false);

  const routeCategory = route.block as keyof typeof reference.directory.blocks;
  const categoryString = reference.directory.blocks[routeCategory].name[lang];
  const tooltipString = React.useMemo(
    () => (
      <>
        <Typography sx={{ fontSize: "1em" }}>
          {categoryString.toUpperCase()}
        </Typography>
        <Typography variant="h4">{route.title.toUpperCase()}</Typography>
      </>
    ),
    [categoryString, route.title]
  );

  // -----------
  // bus
  // -----------
  const [, setTrigg] = React.useState({});
  const fileID = `RSidebarMuiItem${routeId}`;
  const { activeIndicator } = isClickable
    ? busContext.getDataFromBus([busNames.activeIndicator], fileID, setTrigg)
    : { activeIndicator: "" };

  // **

  const isActive = activeIndicator === route.name ? true : false;

  // -----------
  // handlers
  // -----------
  const handleIsActive = React.useCallback(() => {
    setTrigg({});
  }, []);

  const icn = route.icon !== undefined ? <route.icon /> : undefined;

  // -----------
  // effects
  // -----------
  React.useEffect(() => {
    if (isActive && refElm.current && !isClicked.current) {
      refElm.current.scrollIntoView({ block: "center" });
      isClicked.current = false;
    }
  }, [isActive]);

  // -----------
  // memo
  // -----------
  const ListItemMemo = React.useMemo(
    () => {
      // --------
      // rendering
      // --------
      return (
        <ListItem
          key={route.id}
          disablePadding
          sx={_stylesListItem()}
          ref={refElm}
        >
          <StyledListItemButton
            className={`Crawer_ListItemButton_Link key${route.id}`}
            onClick={(event) => {
              if (isClickable) {
                parentHandleClick(
                  event,
                  route,
                  routeId,
                  handleIsActive,
                  fileID
                );
                isClicked.current = true;
              }
            }}
            open={open}
            isActive={isActive}
            disabled={!isClickable}
            isFirefox={isFirefox}
            isTablet={isTablet}
          >
            {Boolean(icn) && (
              <TooltipCustom title={tooltipString} placement="right">
                <Box
                  className="RSidebarMuiItem_IconWrapper"
                  sx={_styledIconWrapper(isFirefox, correction)}
                >
                  <StyledListItemIcon
                    className="Drawer_ListItemIcon"
                    open={open}
                    isTablet={isTablet}
                  >
                    {icn}
                  </StyledListItemIcon>
                </Box>
              </TooltipCustom>
            )}
            {
              <StyledListItemText
                className="Drawer_ListItemText"
                primary={route.title.toUpperCase()}
                open={open}
                isHeader={!isClickable}
              />
            }
          </StyledListItemButton>
        </ListItem>
      );
    },
    // eslint-disable-next-line
    [open, activeIndicator, lang, isTablet, kefWidth, correction]
  );

  // -----------
  // render
  // -----------
  return ListItemMemo;
}
// );

// ===========
// styles
// ===========
function _stylesListItem() {
  return {
    display: "block",
    position: "relative",
    direction: "ltr",
  };
} //<

//>
function _styledIconWrapper(
  isFirefox: boolean,
  correction: undefined | number
) {
  const corr = correction ? correction : 0;

  // ---
  // CSSObject
  // ---
  return {
    flex: "0 0 auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "8px",
    paddingBottom: "8px",
    marginLeft: isFirefox ? "unset" : `-${corr}px`,
  };
}
