// =============
// import
// =============
import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { RMiniDrawer } from "./components/sidebar_mui/RMiniDrawer";
import { RTopbarMain } from "./components/topbar/RTopbarMain";
import { useTheme } from "@mui/material";
import { RTargetsDrawer } from "./components/topbar/targetsDrawer/RTargetsDrawer";
import { busContext } from ".";
import { busNames } from "./bus_context/busContextStore";
import { prepareTheme } from "./func/prepare/prepareTheme";
import { type TThemeSettings } from "./theming/themeSettings";
import changeUrl from "./func/url/changeUrl";
import prepareBGImageColors from "./func/prepare/prepareBGImageColors";

// =============
// main
// =============
function App() {
  // ---------
  // bus
  // ---------
  const [, setTrigg] = React.useState({});
  const fileID = "App";
  const { colorMode, isTablet, isMobile } = busContext.getDataFromBus(
    [busNames.colorMode, busNames.isTablet, busNames.isMobile],
    fileID,
    setTrigg
  );
  ///

  /// ---
  const muiTheme = useTheme();
  const theme = prepareTheme(muiTheme, colorMode);
  busContext.placeDataToBus(busNames.theme, theme, fileID);
  changeUrl({ clmode: colorMode });
  ///

  console.log("%cApp::theme:", "color: orange", theme);

  // ---------
  // effects
  // ---------
  React.useEffect(() => {
    busContext.placeDataToBus(busNames.isInitial, false, fileID);
  }, []);

  // ---------
  // rendering
  // ---------
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app" style={{ display: "flex" }}>
        <RMiniDrawer />
        {(isMobile || isTablet) && <RTargetsDrawer />}
        <main
          className="App_main content"
          style={_mainStyles(theme as unknown as TThemeSettings, isTablet)}
        >
          <RTopbarMain />
          <Outlet />
        </main>
      </div>
    </ThemeProvider>
  );
} ///

// ===
// export
// ===
export default App;

// =============
// styles
// =============
function _mainStyles(theme: TThemeSettings, isTablet: boolean) {
  const backgroundImageColor = prepareBGImageColors(theme);

  // ---
  // CSSObject
  // ---
  return {
    padding: "16px", // 1rem
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    rowGap: isTablet ? "1.5em" : "2em",
    overflow: "hidden",
    backgroundImage: `linear-gradient(${backgroundImageColor}, ${backgroundImageColor})`,
  };
}

// =============
// debug / profiling
// =============
