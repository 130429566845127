// ============
// import
// ============
import { store, type TMinState } from "../mainStore";
import { type TECountriesVal } from "../../../enums/ECountries";
import { upsertOne } from "../handlers/upsertOne";

// ============
// types
// ============
type TUpdateOneAction = {
  id: TECountriesVal;
  active: string;
};
type TNavSlice = {
  actions: Record<string, Function>;
};

// ============
// slice
// ============
const navSlice: TNavSlice = store.createSlice({
  name: "navSlice",
  initialState: { ids: [], entities: {}, indicatorID: "" } as TMinState,
  reducers(self) {
    return {
      upsertOneNavActiveNRedux(state, dispatch) {
        if (state[self.name] === undefined) {
          state[self.name] = self.initialState;
        }
        return (action: TUpdateOneAction | null) => {
          if (action) {
            dispatch(
              self.name,
              upsertOne(state[self.name] as TMinState, action),
              true
            );
          }
        };
      },
    };
  },
});

// ============
// actions
// ============
if (!navSlice.actions) {
  throw new Error("noredux/navState/navSlice:\nnavSlice.actions === null");
}

export const { upsertOneNavActiveNRedux /*, navInitFn*/ } = navSlice.actions;
