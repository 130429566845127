/**
 * from here: https://stackoverflow.com/questions/76095729/how-can-i-add-my-custom-component-to-mui-theme-options-so-that-i-can-override-it
 */

// ===========
// import
// ===========
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { ELangs } from "../enums/ELangs";
import reference from "../reference.json";

// ===========
// types
// ===========
type RStyledDescriptionProps = {
  lang: ELangs;
  isTabletOrMobile: boolean;
  resultData: {
    baseHeight: string;
    EN:
      | {
          id: string;
          name: string;
          description: string;
          sourceOrganization: string;
          topics: string;
        }
      | undefined;
    RU:
      | {
          id: string;
          name: string;
          description: string;
          sourceOrganization: string;
          topics: string;
        }
      | undefined;
  };
};

const StyledDescriptionWrapper = styled("div", {
  name: "StyledDescriptionWrapper",
  slot: "Root",
  shouldForwardProp: (prop) => prop !== "baseHeight",
})<{ baseHeight: string }>((/*{ theme, ...props }*/) => {
  return {
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    maxWidth: "500px",
    justifyContent: "center",
  };
});

const StyledDescriptionTitle = styled(Typography, {
  name: "StyledDescriptionWrapper",
  slot: "Title",
})(({ theme }) => {
  return Object.assign({}, theme.typography.h4, {
    color: theme.palette.primary.light,
    fontWeight: "bold",
  });
});

const StyledDescriptionDesc = styled(Typography, {
  name: "StyledDescriptionWrapper",
  slot: "Description",
})(({ theme }) => {
  return Object.assign({}, theme.typography.h5, {
    marginTop: "0.5em",
    color: theme.palette.text.secondary,
    // opacity: 0.9,
    lineHeight: 1.5,
  });
});

const StyledDescriptionOrganBlock = styled("div", {
  name: "StyledDescriptionWrapper",
  slot: "Organization",
})(({ theme }) => ({
  marginTop: "2em",
  color: theme.palette.text.secondary,
  "& > p:first-of-type": {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  "& > p": {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const StyledDescriptionOrganText = styled(Typography, {
  name: "StyledDescriptionWrapper",
  slot: "Organization",
})(({ theme }) => {
  return Object.assign({}, theme.typography.body1);
});

// ============
// main
// ============
export const RStyledDescription = React.forwardRef(function RStyledDescription(
  inProps: RStyledDescriptionProps,
  _ref
) {
  return (
    <StyledDescriptionWrapper baseHeight={inProps.resultData.baseHeight}>
      {!inProps.isTabletOrMobile && (
        <StyledDescriptionTitle variant="h4">
          {inProps.resultData[inProps.lang] &&
            inProps.resultData[inProps.lang]?.name}
        </StyledDescriptionTitle>
      )}
      <StyledDescriptionDesc variant="body1">
        {inProps.resultData[inProps.lang] &&
          inProps.resultData[inProps.lang]?.description}
      </StyledDescriptionDesc>
      <StyledDescriptionOrganBlock>
        <StyledDescriptionOrganText>
          {/* Source Organization: */}
          {reference.ui.pages.description.sourceOrganization.name[inProps.lang]}
        </StyledDescriptionOrganText>
        <StyledDescriptionOrganText variant="body1">
          {inProps.resultData[inProps.lang] &&
            inProps.resultData[inProps.lang]?.sourceOrganization}
        </StyledDescriptionOrganText>
      </StyledDescriptionOrganBlock>
    </StyledDescriptionWrapper>
  );
});

// ===========
// declare the component
// (module augmentation)
// ===========
// declare module "@mui/material/styles" {
//   interface Components {
//     MyComponent?: {
//       defaultProps?: MyComponentProps;
//       styleOverrides?: Partial<
//         OverridesStyleRules<MyComponentClassKey, "MyComponent", Theme>
//       >;
//     };
//   }
// }
