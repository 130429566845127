// ========
// import
// ========
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ELangs } from "../../enums/ELangs";
import { styled } from "@mui/material/styles";

// ========
// types
// ========
type TComponentProps = {
  lang: ELangs;
  isFirefox: boolean;
  isTablet: boolean;
};
type TPreAbout = {
  dataObj: {
    b1h: Record<"__html", string>;
    block1: { [p: string]: Record<"__html", string> };
    b2h: Record<"__html", string>;
    block2: { [p: string]: Record<"__html", string> };
    b3h: Record<"__html", string>;
    block3: { [p: string]: Record<"__html", string> };
  };
  className: string;
  isFirefox: boolean;
  isTablet: boolean;
};

// ========
// const
// ========
const URL_ABOUT = "/data/meta/meta_about/about.json";

// ========
// pre-styled
// ========
const PreAbout = ({ dataObj, className }: TPreAbout) => (
  <Box className={className}>
    {dataObj && (
      <Box className={`container_${className}`}>
        {/* About app */}
        <Box className="block">
          <Typography
            className="header"
            variant="h3"
            dangerouslySetInnerHTML={dataObj.b1h}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block1.p1}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block1.p2}
          />
        </Box>
        {/* How to */}
        <Box className="block">
          <Typography
            className="header"
            variant="h3"
            dangerouslySetInnerHTML={dataObj.b2h}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block2.p1}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block2.p2}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block2.p3}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block2.p4}
          />
        </Box>
        {/* Technology */}
        <Box className="block">
          <Typography
            className="header"
            variant="h3"
            dangerouslySetInnerHTML={dataObj.b3h}
          />
          <Typography
            className="paragraph"
            dangerouslySetInnerHTML={dataObj.block3.p1}
          />
          <Typography
            // className="paragraph"
            dangerouslySetInnerHTML={dataObj.block3.p2}
          />
          <Typography
            // className="paragraph"
            dangerouslySetInnerHTML={dataObj.block3.p3}
          />
        </Box>
      </Box>
    )}
  </Box>
);

// ========
// styled
// ========
const StyledAbout = styled(PreAbout)((allProps) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  justifyContent: "center",
  alignItems: "flex-start",
  color:
    allProps.theme.palette.mode === "dark"
      ? allProps.theme.palette.text.secondary
      : allProps.theme.palette.text.primary,
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
  fontSize: allProps.isTablet ? "0.9em" : "1em",

  "& p": {
    fontSize: !allProps.isFirefox ? "1.3em" : "1.2em",
    display: "block",
  },

  [`& .container_${allProps.className}`]: {
    maxWidth: "700px",
    alignItems: "flex-start",
    paddingBottom: "2em",
  },

  [`& .container_${allProps.className} .block`]: {
    paddingTop: "2em",
  },

  [`& .container_${allProps.className} .header`]: {
    color: allProps.theme.palette.primary.main,
  },

  [`& .container_${allProps.className} .paragraph`]: {
    paddingTop: "1em",
  },

  [`& .container_${allProps.className} .paragraph:first-of-type`]: {
    paddingTop: "0.5em",
  },

  [`& .container_${allProps.className} a`]: {
    color:
      allProps.theme.palette.mode === "dark"
        ? allProps.theme.palette.secondary.main
        : allProps.theme.palette.secondary.dark,
    textDecoration: "none",
  },

  [`& .container_${allProps.className} em`]: {
    // color: allProps.theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

// ========
// main
// ========
export const RAbout = (props: TComponentProps) => {
  const [aboutData, setAboutData] = React.useState<Record<string, any> | null>(
    null
  );

  // -------
  // effect
  // -------
  React.useEffect(() => {
    window
      .fetch(URL_ABOUT)
      .then((response) => {
        if (response.ok) {
        }
        return response.json();
      })
      .then((jsonData) => setAboutData(jsonData));
  }, []);

  const dataObj = aboutData ? aboutData[props.lang] : null;

  // -------
  // rendering
  // -------
  return (
    <StyledAbout
      className="RAbout"
      dataObj={dataObj}
      isFirefox={props.isFirefox}
      isTablet={props.isTablet}
    />
  );
};
