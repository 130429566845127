// =============
// import
// =============
import { type TECountriesVal } from "../../enums/ECountries";

// =============
// const
// =============
const FILE_NAME = "wb";

// =============
// main
// =============
export async function fetchData(target: string, country: TECountriesVal) {
  const dataAddress = _httpAddress(FILE_NAME, target, country);

  return await _fetchToServer(dataAddress);
}

// -------------
// _httpAddress
// -------------
function _httpAddress(
  fileName: string,
  target: string,
  country: TECountriesVal
) {
  return `/data/${country}/${target}/${fileName}.json`;
}

// -------------
// _fetchToServer
// -------------
async function _fetchToServer(httpAddress: string) {
  try {
    return await fetch(httpAddress).then((response) => response.json());
  } catch (err) {
    console.error("%cERROR:", "color: red");
    console.error(err);
    console.error("file: 'fetchData.ts'");
    console.error("func: '_fetchToServer'");
    console.error("httpAddress: ", httpAddress);
    console.error("---------------------");
  }
}
