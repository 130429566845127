// ===========
// import
// ===========
import * as bm from "./barrelModule";
import * as React from "react";
import type { TECountriesVal } from "../../../../enums/ECountries";
import { ELangs } from "../../../../enums/ELangs";
import { RStyledTooltipComponent } from "../../../../styled/RStyledTooltipComponent";
import { type TEWhatVal } from "./barrelModule";
import { prepareFormatY as formatY } from "../../../../func/prepare/prepareFormatY";
import { type Theme } from "@mui/material/styles";
import { busContext } from "../../../..";
import { busNames } from "../../../../bus_context/busContextStore";
import { BusContext } from "../../../../bus_context/busContext";
import changeUrl from "../../../../func/url/changeUrl";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { type TThemeSettings } from "../../../../theming/themeSettings";
import { iconsStore } from "../../../../hooks_g/useIcons";
import { Typography } from "@mui/material";

// =============
// types
// =============
type TComponentProps = {
  data: Array<TData>;
  xArray: Array<string> | null;
  xRange: Array<string> | null;
  handleChangedXRange: (range: [number, number]) => void;
  target: TEWhatVal;
  lang: ELangs;
  rangeTupple: [] | [number, number];
  dataID: string;
  baseHeight: string;
  targetFloatPrecision: number;
  area: Array<number | null>;
  fnOpenInFullscreen(): void;
};
type TData = {
  id: TECountriesVal;
  data: Array<{ x: string; y: number }>;
  minX: string;
  maxX: string;
  stepX: number;
  minY: number;
  maxY: number;
  stepY: number;
};
type TBusNames = typeof busNames;

// =============
// subcomponent
// =============
function FullscreenToggle({
  flag,
  theme,
  fnOpenInFullscreen,
}: {
  flag: boolean;
  theme: TThemeSettings;
  fnOpenInFullscreen(): void;
}) {
  const { FullscreenIcon, FullscreenExitIcon } = iconsStore;

  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_styleZeroToggle(theme, flag)}
      className="RMainResponsiveBar_FullscreenToggle"
    >
      <IconButton
        aria-label="toggle to zero"
        sx={{ color: "inherit" }}
        onClick={fnOpenInFullscreen}
      >
        {flag ? <FullscreenIcon /> : <FullscreenExitIcon />}
      </IconButton>
    </Box>
  );
} //<

//>>
function PostUssr({
  theme,
  isFullscreenMode,
}: {
  theme: TThemeSettings;
  isFullscreenMode: boolean;
}) {
  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_stylePostUssr(theme, isFullscreenMode)}
      className="RMainResponsiveBar_PostUssr"
    >
      <Typography>https://postussr.info</Typography>
    </Box>
  );
} //<<

//>>
function CurrentYear({
  year,
  theme,
  isFullscreenMode,
}: {
  year: number;
  theme: TThemeSettings;
  isFullscreenMode: boolean;
}) {
  // ---
  // JSX
  // ---
  return (
    <Box
      sx={_styleCurrentYear(theme, isFullscreenMode)}
      className="RMainResponsiveBar_CurrentYear"
    >
      <Typography sx={{ fontSize: "4em" }}>{year}</Typography>
    </Box>
  );
}
//<<

// ===========
// main
// ===========
export function RMainResponsiveBar(props: TComponentProps) {
  const { xRange, data, dataID, targetFloatPrecision } = props;
  const target = props.target as bm.TEWhatVal;
  const lang = props.lang as bm.ELangs;

  // -----------
  // bus
  // -----------
  const [, setTrigg] = React.useState({});
  const fileID = "RMainResponsiveBar";
  const { isAnimation, isDesktop, isMobile, isFullscreenMode } =
    busContext.getDataFromBus(
      [
        busNames.isAnimation,
        busNames.isDesktop,
        busNames.isMobile,
        busNames.colorMode,
        busNames.isFullscreenMode,
      ],
      fileID,
      setTrigg
    );
  const theme = busContext.getDataFromBusOne(busNames.theme);
  //<<

  const isPostFullscreen =
    busContext.getDataFromBusOne(busNames.currentXYear) !== 0;

  // -----------
  // local store
  // -----------
  const localStore = bm.useRef<{
    isAction: boolean;
    oldXYear: number;
    isInit: boolean;
    oldRangeTupple: [] | [number, number];
    isManualChangedSlider: boolean;
    oldDataID: string;
    justFixed: boolean;
    isTargetChanged: boolean;
    oldXRangeID: string;
    isXRangeChanged: boolean;
  }>({
    isAction: false,
    oldXYear: 0,
    isInit: !isFullscreenMode && !isPostFullscreen ? true : false,
    oldRangeTupple: [],
    isManualChangedSlider: false,
    oldDataID: "",
    justFixed: false,
    isTargetChanged: true,
    oldXRangeID: "",
    isXRangeChanged: false,
  });

  const isDataIDChanged = ((oldDataID: string, newDataID: string) => {
    return oldDataID !== newDataID;
  })(localStore.current.oldDataID, dataID);

  // -----------
  // local state
  // -----------
  const [oldTarget, setOldTarget] = bm.useState(target);
  const [readyData, setReadyData] = bm.useState<any | null>(null);
  const [xYear, setXYear] = bm.useState<number | Array<number>>(
    (() => {
      return !localStore.current.isInit
        ? busContext.getDataFromBusOne(busNames.currentXYear)
        : busContext.getDataFromBusOne(busNames.yearInit);
    })()
  );
  const [fixedXYear, setFixedXYear] = bm.useState<number>();

  // -----------
  // memo
  // -----------
  React.useMemo(
    () => (localStore.current.isTargetChanged = oldTarget !== target),
    // eslint-disable-next-line
    [target]
  );

  (() => {
    const newXRangeID =
      xRange === null
        ? ""
        : String(xRange[0]) +
          String(xRange[xRange?.length - 1]) +
          String(xRange?.length);

    if (newXRangeID !== localStore.current.oldXRangeID) {
      localStore.current.isXRangeChanged = localStore.current.isInit
        ? false
        : true;
      localStore.current.oldXRangeID = newXRangeID;
    }
  })();

  const [, setIsActionChanged] = bm.useState(false);

  if (oldTarget !== target) {
    setOldTarget(target);
  }

  // ---------
  // local state handlers
  // ---------
  const fnHandleIsAction = (isActionDraft: boolean) =>
    (localStore.current.isAction = isActionDraft);
  const isAction = () => localStore.current.isAction;

  const themeColors = theme;

  const isAreaTuppleMin = props.area[0] ? true : false;
  const isAreaTuppleMax = props.area[1] ? true : false;
  const minX =
    xRange === null
      ? 0
      : isAreaTuppleMin && !localStore.current.isTargetChanged
      ? Number(props.area[0])
      : Number(xRange[0]);
  const maxX =
    xRange === null
      ? 0
      : isAreaTuppleMax && !localStore.current.isTargetChanged
      ? Number(props.area[1])
      : Number(xRange[xRange.length - 1]);

  let marks;
  try {
    // const marks =
    marks =
      xRange === null
        ? undefined
        : xRange.map((xPoint) => ({ value: Number(xPoint), label: "" }));
  } catch (error) {
    console.info("%cERROR: RMainResponsiveBar::marks: ", "color: red", error);
    throw new Error("STOP! RMainResponsiveBar::marks");
  }

  // ----------
  // slider
  // ----------
  const startSlideYear =
    xRange === null || xYear === 0
      ? ""
      : xRange.lastIndexOf(String(xYear)) === xRange.length - 1
      ? xRange[0]
      : String(xYear);

  const yearGenerator =
    xRange === null
      ? { value: "", done: true }
      : _yearGenerator(xRange)(startSlideYear);

  // -----------
  // handlers
  // -----------
  const pseudoHandler = () => {
    const isRun = readyData === null ? false : true;

    if (isRun) {
      _runThumb(
        yearGenerator,
        setXYear,
        isAction,
        fnHandleIsAction,
        setIsActionChanged,
        true,
        localStore,
        busContext,
        busNames,
        fileID
      );
      fnHandleIsAction(true);
      setIsActionChanged((old) => !old);
    }
    return true;
  };

  const handlerSlideChange = (
    _event: Event,
    newValue: number | Array<number>
  ) => {
    setXYear((old: number | Array<number>) => {
      localStore.current.isTargetChanged = false;
      localStore.current.oldXYear = old as number;
      return newValue;
    });
    busContext.placeDataToBus(busNames.currentXYear, newValue, fileID);
  };

  const stopSlider = () => {
    fnHandleIsAction(false);
    setIsActionChanged((old) => !old);
  };

  const handleOnClickXThumb = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    const point = event.currentTarget.innerText;

    // @ts-ignore: prop 'tagName' is valid
    const { tagName, classList, innerHTML } = event.target;
    const isToFix =
      // tagName === "SPAN" && classList.contains("MuiSlider-valueLabelLabel");
      tagName === "SPAN" &&
      (classList.contains("MuiSlider-valueLabelOpen") ||
        classList.contains("MuiSlider-valueLabelLabel"));
    if (isToFix) {
      if (fixedXYear === innerHTML || innerHTML.includes(fixedXYear)) {
        setFixedXYear(undefined);
      } else if (innerHTML.includes(point)) {
        setFixedXYear(Number(point));
      } else {
        setFixedXYear(innerHTML);
      }
    } else {
    }
  };

  // -----------
  // data for rendering
  // -----------
  let dataForRender = null;
  try {
    if (readyData === null || data.length === 0) {
      dataForRender = null;
    } else {
      dataForRender = readyData[String(xYear)];
      if (dataForRender === undefined) {
        throw new Error(`xYear ${xYear} is out of xRange!`, { cause: "E_002" });
      }
    }
  } catch (error: any) {
    switch (error.cause) {
      case "E_002": {
        localStore.current.isAction = false;
        try {
          dataForRender = readyData[String(localStore.current.oldXYear)];
          if (dataForRender === undefined) {
            throw new Error(`xYear ${xYear} is out of xRange`, {
              cause: "E_002_02",
            });
          }
          if (localStore.current.justFixed) {
            // nothing
          } else {
            setXYear(localStore.current.oldXYear);
            busContext.placeDataToBus(
              busNames.currentXYear,
              localStore.current.oldXYear,
              fileID
            );
          }
        } catch (error2) {
          const readyDataKeysArr = Object.keys(readyData);
          const lastReadyDataYear = Number(
            readyDataKeysArr[readyDataKeysArr.length - 1]
          );
          dataForRender = readyData[String(lastReadyDataYear)];
          if (dataForRender === undefined) {
            throw new Error(
              "RMainResponsiveBar::data_for_rendering::catch_error2: dataForRender === undefined"
            );
          }
          setXYear(() => {
            localStore.current.oldXYear = lastReadyDataYear as number;
            return lastReadyDataYear;
          });
          busContext.placeDataToBus(
            busNames.currentXYear,
            lastReadyDataYear,
            fileID
          );
        }
        break;
      }

      default:
        dataForRender = readyData[String(localStore.current.oldXYear)];
        if (dataForRender === undefined) {
          throw new Error(
            `RMainResponsiveBar::data_for_rendering::default: dataForRender === undefined || oldXYear: ${localStore.current.oldXYear} || error.cause: ${error.cause}`
          );
        }
        break;
    }
  }

  const axisYLegend = bm.reference.targets[target].y[lang];
  const axisXLegend = bm.reference.targets[target].xBars[lang];

  // -----------
  // options for rendering
  // -----------
  const marginRight = isDesktop
    ? Number(bm.config.layout.charts.line.margin.right.md)
    : Number(bm.config.layout.charts.line.margin.right.xs);

  // -----------
  // memo 2
  // -----------
  const FullscreenToggleMemo = React.useMemo(
    () => (
      <FullscreenToggle
        flag={!isFullscreenMode}
        theme={theme}
        fnOpenInFullscreen={props.fnOpenInFullscreen}
      />
    ),
    // eslint-disable-next-line
    [isDesktop, isFullscreenMode, theme.palette.mode]
  ); //<<

  //>>
  const PostUssrMemo = React.useMemo(
    () => <PostUssr theme={theme} isFullscreenMode={isFullscreenMode} />,
    [isFullscreenMode, theme]
  ); //<<

  // -----------
  // effects
  // -----------
  bm.useEffect(() => {
    if (
      localStore.current.isTargetChanged ||
      localStore.current.isXRangeChanged ||
      isDataIDChanged
    ) {
      // ---------
      // data
      // ---------
      let dataArray: Array<any> | null;
      try {
        dataArray =
          xRange === null
            ? null
            : xRange.map((year: string) => {
                return {
                  year: year,
                  chartData: data
                    .map((obj: any) => {
                      return {
                        id: obj.id,
                        y: obj.data.find((element: any) => element.x === year)
                          .y,
                      };
                    })
                    .sort((a: { y: number }, b: { y: number }) => {
                      return b.y > a.y ? 1 : -1;
                    }),
                };
              });
      } catch (error) {
        console.info(
          "%cERROR: RMainResponsive::Effects::dataArray: ",
          "color: red",
          error
        );
        throw new Error("STOP! RMainResponsiveBar::dataArray");
      }

      // ---------
      // colors
      // ---------
      const dataWithColors =
        dataArray === null
          ? null
          : (_addColors(dataArray, theme.palette.mode) as Array<{
              year: string;
              chartData: Array<{ id: string; color: string; y: number }>;
            }>);

      // ---------
      // shortnames
      // ---------
      const dataWithShortNames =
        dataArray === null
          ? null
          : _addShortNames(
              dataWithColors as Array<{
                year: string;
                chartData: Array<{ id: string; color: string; y: number }>;
              }>,
              lang
            );

      // ---------
      // result data
      // ---------
      if (dataWithShortNames !== null) {
        const resultData = dataWithShortNames.reduce((accum, obj) => {
          const newObj = { [obj.year]: obj };
          return { ...accum, ...newObj };
        }, {} as Record<string, any>);

        // ------
        // set data
        // ------
        setReadyData(resultData);

        // ------
        // set the end year
        // ------
        // if (rangeTupple.length > 0) {
        if (props.area.length > 0) {
          if (
            (localStore.current.isAction && localStore.current.isInit) ||
            (localStore.current.isInit && isDataIDChanged)
          ) {
            // nothing
          } else {
            // init xYear
            if (
              Boolean(fixedXYear) &&
              (localStore.current.isTargetChanged ||
                localStore.current.isXRangeChanged)
            ) {
              setXYear(fixedXYear as number);
              localStore.current.isTargetChanged = false;
              localStore.current.justFixed = true;
              busContext.placeDataToBus(
                busNames.currentXYear,
                fixedXYear,
                fileID
              );
            } else if (localStore.current.isTargetChanged) {
              let transferPreResult = 0;
              setXYear((old: number | Array<number>) => {
                const preResult = xRange
                  ? Number(xRange[xRange.length - 1])
                  : (props.area[1] as number);
                localStore.current.oldXYear = old as number;
                transferPreResult = preResult;
                return preResult;
              });
              busContext.placeDataToBus(
                busNames.currentXYear,
                transferPreResult,
                fileID
              );
            } else if (localStore.current.isXRangeChanged) {
              let transferPreResult = 0;
              if (!isFullscreenMode && !isPostFullscreen) {
                setXYear((old: number | Array<number>) => {
                  const preResult = xRange
                    ? Number(xRange[xRange.length - 1])
                    : (props.area[1] as number);
                  localStore.current.oldXYear = old as number;
                  transferPreResult = preResult;

                  return preResult;
                });
                busContext.placeDataToBus(
                  busNames.currentXYear,
                  transferPreResult,
                  fileID
                );
              }
            } else if (localStore.current.justFixed) {
              localStore.current.justFixed = false;
            } else {
            }
          }
        } else if (xRange !== null) {
          if (isDataIDChanged) {
            localStore.current.oldDataID = dataID;
          } else {
            let transferPreResult = 0;
            setXYear((old: number | Array<number>) => {
              localStore.current.oldXYear = old as number;
              transferPreResult = parseInt(xRange[xRange.length - 1]);
              return transferPreResult;
            });
            busContext.placeDataToBus(
              busNames.currentXYear,
              transferPreResult,
              fileID
            );
          }
        }

        // ---------
        // turn off 'init' status
        // ---------
        if (localStore.current.isInit) {
          localStore.current.isInit = false;
          localStore.current.oldRangeTupple = props.area as [number, number];
        }

        // ---------
        // to default
        // ---------
        localStore.current.isManualChangedSlider = false;
        localStore.current.isXRangeChanged = false;
      }
    }
  }, [target, xRange, lang]);

  bm.useEffect(() => {
    busContext.placeDataToBus(busNames.targetInContext, target, fileID);
  }, [target]);

  // unmount
  bm.useEffect(() => () => {
    localStore.current.isInit = false;
  });

  // ------
  // Slider value
  // ------
  const thumbYear = xYear;

  // -------------
  // changes the 'address bar'
  // -------------
  changeUrl({
    year: xYear.toString(),
  });

  // -----------
  // rendering
  // -----------
  if ((!isAnimation || isMobile) && dataForRender !== null) {
    if (!dataForRender) {
      const message =
        "ERROR:\nRMainResponsiveBar::rendering::dataForRender === undefined";
      console.info(`%c${message}`, "color: red");
      throw new Error(message);
    }

    // ---
    // JSX
    // ---
    return (
      <bm.Box
        className="RMainResponsiveBarWrapper"
        sx={_rResponsiveBarWrapperStyle(themeColors)}
      >
        <bm.Box className="RMainResponsiveBar" sx={_mainResponsiveBarStyle()}>
          <Bar
            className="Bar"
            //eslint-disable-next-line
            //@ts-ignore
            data={dataForRender ? dataForRender.chartData : null}
            themeColors={themeColors}
            marginRight={marginRight}
            axisXLegend={axisXLegend}
            axisYLegend={axisYLegend}
            target={target}
            isDesktop={isDesktop}
            targetFloatPrecision={targetFloatPrecision}
          />
        </bm.Box>
        {!isFullscreenMode && (
          <bm.Box
            className="BarSliderBox"
            sx={_barSliderBoxStyle()}
            onClick={handleOnClickXThumb}
          >
            {isAction() === false ? (
              <bm.iconsStore.Play onClick={pseudoHandler} />
            ) : (
              <bm.iconsStore.Pause onClick={stopSlider} />
            )}
            <bm.Slider
              aria-labelledby="input-slider"
              className="Slider"
              min={minX}
              max={maxX}
              step={null}
              marks={marks}
              value={Number(thumbYear)}
              onChange={handlerSlideChange}
              valueLabelDisplay="on"
              sx={_inputSliderStyle(
                themeColors,
                isDesktop,
                fixedXYear,
                isMobile
              )}
            />
          </bm.Box>
        )}
        {!isMobile && FullscreenToggleMemo}
        {!isMobile && PostUssrMemo}
        {
          <CurrentYear
            theme={theme}
            isFullscreenMode={isFullscreenMode}
            year={xYear as number}
          />
        }
      </bm.Box>
    );
  } else {
    return (
      <bm.Box
        className="RMainResponsiveBarWrapper"
        sx={_rResponsiveBarWrapperStyle(themeColors)}
        style={{ visibility: "hidden" }}
      >
        <bm.Box className="RMainResponsiveBar" sx={_mainResponsiveBarStyle()}>
          {/* Empty "HTMLDivElement" */}
          <div></div>
        </bm.Box>
      </bm.Box>
    );
  }
}

// ===========
// subcomponents
// ===========
function Bar(props: any) {
  const {
    data,
    themeColors,
    marginRight,
    axisXLegend,
    axisYLegend,
    targetFloatPrecision,
  } = props;

  let countries;
  try {
    countries = data.map(
      (dataObj: Record<"shortName", string>) => dataObj.shortName
    );
  } catch (error) {
    console.info("%cERROR: RMainResponsiveBar::Bar #1: ", "color: red", error);
    throw new Error(`STOP! => RMainResponsiveBar::Bar #1\ndata: ${data}`);
  }

  const isDark = themeColors.palette.mode === "dark" ? true : false;

  let longestDataLength: number;
  try {
    longestDataLength =
      data.length > 0
        ? String(data[0].y.toFixed(Number(targetFloatPrecision))).length
        : 0;
  } catch (error) {
    longestDataLength = 0;
  }

  const PX_FOR_ONE_FIGURE = 7;

  // -----------
  // rendering
  // -----------
  return (
    <bm.ResponsiveBar
      data={data}
      keys={countries}
      theme={_nivoTheme(themeColors, props.isDesktop, isDark)}
      indexBy="shortName"
      margin={{ top: 50, right: marginRight, bottom: 60, left: 70 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      valueFormat={`>-.${Number(targetFloatPrecision)}f`}
      colors={({ id, data }) => String(data[`${id}Color`])}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: props.isDesktop ? 0 : -48,
        legend: axisXLegend,
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: axisYLegend,
        legendPosition: "middle",
        legendOffset: -60,
        format: (value) => formatY(props.target)(value),
      }}
      labelSkipWidth={longestDataLength * PX_FOR_ONE_FIGURE}
      labelSkipHeight={20}
      labelTextColor={themeColors.palette.grey["900"]}
      role="application"
      ariaLabel="Nivo bar chart"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
      enableLabel={true}
      tooltip={(point) => (
        <RStyledTooltipComponent
          tooltipObj={{ point }}
          targetPrecision={targetFloatPrecision}
        />
      )}
      markers={[
        {
          axis: "y",
          value: 0,
          // from here: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Fills_and_Strokes#fill_and_stroke_attributes
          lineStyle: {
            stroke: isDark
              ? themeColors.palette.secondary.main
              : themeColors.palette.secondary.dark,
            strokeWidth: 1,
            strokeDasharray: "10",
          },
        },
      ]}
    />
  );
}

// ============
// utils
// ============
function _addColors(
  dataArray: Array<{
    year: string;
    chartData: Array<{ id: string; y: number }>;
  }>,
  mode: "light" | "dark"
) {
  const countries = Object.values(bm.ECountries);
  const countriesObj = bm.reference.countries;

  let colorsArray: Array<any>;
  try {
    colorsArray = countries.map((countryId: bm.TECountriesVal) => ({
      id: countryId,
      color: countriesObj[countryId].colors[mode],
    }));
  } catch (error) {
    console.info(
      "%cERROR: RMainResponsiveBar::_addColors #1: ",
      "color: red",
      error
    );
    throw new Error("STOP! => RMainResponsiveBar::_addColors");
  }

  let newData: Array<any>;
  try {
    // const newData = dataArray.map((dataTopObj) => ({
    newData = dataArray.map((dataTopObj) => ({
      ...dataTopObj,
      chartData: dataTopObj.chartData.map((dataObj: any) => {
        const foundedColorObject = colorsArray.find(
          (colorObj) => colorObj.id === dataObj.id
        );

        if (foundedColorObject === undefined) {
          throw new Error(
            "RMainResponsiveBar::_addColors::[ERROR]: 'searchedObj' is not founded."
          );
        }

        return {
          ...dataObj,
          color: foundedColorObject.color,
        };
      }),
    }));
  } catch (error) {
    console.info(
      "%cERROR: RMainResponsiveBar::_addColors #2: ",
      "color: red",
      error
    );
    throw new Error("STOP! => RMainResponsiveBar::_addColors");
  }

  return newData;
}

function _nivoTheme(themeColors: Theme, isDesktop: boolean, isDark: boolean) {
  const colors = themeColors.palette;

  //---------
  // returning
  //---------
  return {
    textColor: colors.text.primary,
    axis: {
      ticks: {
        text: {
          fontSize: isDesktop ? 11 : 10,
          fill: colors.text.secondary,
        },
      },
      legend: {
        text: {
          fill: colors.text.secondary,
        },
      },
    },
    grid: {
      line: {
        stroke: isDark ? colors.text.disabled : colors.grey[300],
        strokeWidth: 1,
      },
    },
  };
}

function _addShortNames(
  data: Array<{
    year: string;
    chartData: Array<{ id: string; color: string; y: number }>;
  }>,
  lang: bm.ELangs
) {
  const countriesObj = bm.reference.countries;

  try {
    return data.map((obj) => {
      const chartData = obj.chartData.map((chart) => {
        const name =
          countriesObj[chart.id as keyof typeof countriesObj].shortName[lang];
        return {
          ...chart,
          shortName: name,
          [name]: chart.y,
          [`${name}Color`]: chart.color,
        };
      });

      return { ...obj, chartData };
    });
  } catch (error) {
    console.info(
      "%cERROR: RMainResponsiveBar::_addShortNames: ",
      "color: red",
      error
    );
    throw new Error("STOP! RMainResponsiveBar::_assShortNames");
  }
}

function _yearGenerator(xRange: Array<string>) {
  return function* (year: string) {
    const index = xRange.indexOf(String(year));
    const start = index === 0 ? 0 : index + 1;
    for (const year of xRange.slice(start)) {
      yield year;
    }
  };
}

function _runThumb(
  yearGenerator: any,
  setXYear: any,
  isAction: Function,
  fnHandleIsAction: Function,
  setIsActionChanged: Function,
  start: boolean = true,
  localStore: bm.React.MutableRefObject<{
    isAction: boolean;
    oldXYear: number;
  }>,
  busContext: BusContext,
  busNames: TBusNames,
  fileID: string
) {
  const showTime = start ? 0 : 2000;
  const nextYear = yearGenerator.next();

  if (nextYear.done) {
    fnHandleIsAction(false);
    setIsActionChanged((old: boolean) => !old);
  } else {
    const timer = window.setTimeout(() => {
      if (isAction()) {
        setXYear((old: number) => {
          localStore.current.oldXYear = old;
          return nextYear.value;
        });
        _runThumb(
          yearGenerator,
          setXYear,
          isAction,
          fnHandleIsAction,
          setIsActionChanged,
          false,
          localStore,
          busContext,
          busNames,
          fileID
        );
        busContext.placeDataToBus(
          busNames.currentXYear,
          nextYear.value,
          fileID
        );
      } else {
        window.clearTimeout(timer);
      }
    }, showTime);
  }
}

// ============
// styles
// ============
function _barSliderBoxStyle() {
  return {
    width: "95%",
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gap: "1rem",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "0.4em",
  };
} //<<

//>>
function _inputSliderStyle(
  props: Theme,
  isDesktop: boolean,
  fixedXYear: number | undefined,
  isMobile: boolean
) {
  const TRACK_COLOR = props.palette.primary.light;
  const DONE_COLOR = props.palette.secondary.dark;
  const LABEL_COLOR = props.palette.secondary.dark;
  const THUMB_DIMENSIONS = "20px";

  return {
    width: isDesktop ? "100%" : "91%",
    justifySelf: isMobile ? "start" : "center",
    "& .MuiSlider-track": {
      backgroundColor: DONE_COLOR,
      border: 0,
    },
    "& .MuiSlider-thumb": {
      width: THUMB_DIMENSIONS,
      height: THUMB_DIMENSIONS,
      backgroundColor: DONE_COLOR,
      borderRadius: "50%",
    },
    "& .MuiSlider-rail": {
      backgroundColor: TRACK_COLOR,
    },
    "& .MuiSlider-valueLabelOpen": {
      backgroundColor: LABEL_COLOR,
    },
    "& .MuiSlider-mark": {
      width: "3px",
      height: "100%",
      backgroundColor:
        props.palette.mode === "dark"
          ? props.palette.background.default
          : props.palette.background.default,
    },
    [`& .MuiSlider-thumb input[value='${fixedXYear}'] + span`]: {
      backgroundColor:
        props.palette.mode === "dark"
          ? props.palette.secondary.main
          : props.palette.primary.light,
      color:
        props.palette.mode === "dark"
          ? props.palette.secondary.contrastText
          : props.palette.primary.contrastText,
    },
  };
} //<<

//>>
function _mainResponsiveBarStyle() {
  return {
    display: "flex",
    flexDirection: "column" as const,
    minHeight: `200px`,
    width: "100%",
    justifyContent: "space-between",
    "& > div > div": {
      height: "100%",
    },
    flex: "1",
  };
} //<

//>>
function _rResponsiveBarWrapperStyle(themeColors: Theme) {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    backgroundImage: `linear-gradient(${
      themeColors?.palette.background.paper + "30"
    }, ${themeColors?.palette.background.paper + "30"})`,
    overflow: "hidden",
    paddingBottom: "32px",
    position: "relative",
  };
} //<<

//>>
function _styleZeroToggle(theme: TThemeSettings, isNotFullscreenFlag: boolean) {
  // ---
  // CSSObj
  // ---
  return {
    position: "absolute",
    right: isNotFullscreenFlag ? "0.5em" : "2em",
    bottom: isNotFullscreenFlag ? "-0.4em" : "1.3em",
    color: theme !== undefined ? theme.palette.text.secondary : "transparent",
  };
} //<<

//>>
function _stylePostUssr(theme: TThemeSettings, isFullscreenMode: boolean) {
  // ---
  // CSSObj
  // ---
  return {
    position: "absolute",
    right: "2em",
    bottom: "2em",
    color: theme !== undefined ? theme.palette.text.secondary : "transparent",
    visibility: !isFullscreenMode ? "hidden" : "visible",
  };
} //<

//>>
function _styleCurrentYear(theme: TThemeSettings, isFullscreenMode: boolean) {
  // ---
  // CSSObj
  // ---
  return {
    position: "absolute",
    right: "3em",
    // bottom: "2em",
    top: "0",
    color: theme !== undefined ? theme.palette.text.secondary : "transparent",
    visibility: !isFullscreenMode ? "hidden" : "visible",
    padding: "1em",
    backgroundColor: theme.palette.background.default,
  };
} //<
